import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ClaimsService } from 'src/app/services/claims.service';
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  NgxFileDropEntry,
} from 'ngx-file-drop';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ModuleConstants as MC } from '../../module-constants';

@Component({
  selector: 'app-register-claim',
  templateUrl: './register-claim.component.html',
  styleUrls: ['./register-claim.component.scss'],
})
export class RegisterClaimComponent implements OnInit {
  //@ViewChild("placesRef") placesRef : GooglePlaceDirective;
  @ViewChild('places') places: GooglePlaceDirective;
  apiURL: string = '';
  selectedCity: any;
  cities: any;
  value: Date;
  activeState: boolean = false;
  activeState1: boolean = false;
  activeHistory: boolean = false;
  activeDataTab: boolean[] = [];
  activeSubForm: boolean[] = [];

  items: any[];
  claimDetails: any;
  claimsTitle: string = '';
  claimsSubTitle: string = '';
  nextJobStatus: any[];
  nextJobStatusList: any = [];
  jobComments: any[];
  createdByFullName: any = '';
  modifiedOn: any = '';
  createdSubJobs: any[];
  jobId: number = 0;
  userId: number = 0;
  emptyClaim = {};
  subForm: any = [];
  subFormFlag: boolean = false;
  jobTypeName: any = '';
  claimId: any = '';
  loginEmail: any = '';
  subFormName: any = '';
  formId: any = '';
  fieldsList: any = [];
  selectedClaim: any = '';
  patternEmail: RegExp = /[a-z0-9._-]+@[a-z]+\.[a-z]{3}/;
  blockSpace: RegExp = /[^\s]/;
  matchTextBetweenBrackets = new RegExp(/(\(.*?\))$/, "ig");
  email: any = '';
  tomorrow: any;
  uploadedFile: any;
  files: NgxFileDropEntry[] = [];
  jobStatus: any;
  itemsList: any[];
  itemCode: any = '';
  fileUploadForm: FormGroup;
  currentJobStatus: any = '';
  emailTemplateInfo: any = {};

  address: Object;
  establishmentAddress: Object;

  formattedAddress: string;
  formattedEstablishmentAddress: string;
  formSubmit = false;
  groupNamesList: any = [];
  fieldsList1: any = [];
  phone: string;
  userAddress: string = '';
  userLatitude: string = '';
  userLongitude: string = '';
  addMoreData: any = [];

  subfieldsList1: any[];
  subFormGroupNames: any = [];
  subFormFieldsInMainForm: any = [];
  fileAttachments: any = [];
  uploadFiles: any = [];
  subFormNamesInMainForm: any = [];
  fieldCount: any;
  addMoreGroupMap = {};
  mode: any = '';
  groupFieldsCountMap = {};
  isViewOnly = false;
  isEditMode = false;
  emailsFieldsList: any = [];
  emailsList: any[];
  jobFilesList: any[] = [];
  isSinglejobView = false;
  disableSubmitButton = false;
  subJobTabs: any = [];
  jobActionOptions: any[] = [];
  jobActionServices: any = [];
  jobActionService: any;
  jobActionServiceSubjobs: any[] = [];
  selectedJobAction;
  currentTabIndex: number = 0;
  currentChildTabIndex: number = 0;
  serviceTabForm = false;

  allAttachments: any = [];
  rolesAccessData: any;
  claimNo: number = 0;
  constructor(
    private claimsService: ClaimsService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private httpClient: HttpClient,
    private router: Router,
    public datepipe: DatePipe
  ) {
    var today = new Date();
    this.tomorrow = new Date(today);
    this.tomorrow.setDate(today.getDate() + 1);

    this.initializeFileUploadForm();
  }

  ngOnInit(): void {
    this.claimId = this.activatedRoute.snapshot.paramMap.get('claimId');
    this.selectedClaim =
      this.activatedRoute.snapshot.paramMap.get('selectedClaim');
    console.log('this.selectedClaim::', this.selectedClaim);
    this.mode = this.activatedRoute.snapshot.paramMap.get('mode');

    this.isSinglejobView = this.router.url.indexOf('singlejobView') > -1;

    this.getUserAccessDetails();

    this.newGuid();

    this.itemsList = [{ label: 'Hyderabad', value: 'Hyderabad' }];

    this.subfieldsList1 = [];
    this.subFormGroupNames = [];
    this.uploadFiles = [];
    this.nextJobStatus = [];
  }

  initializeFileUploadForm() {
    this.fileUploadForm = this.fb.group({
      fileUpload: this.fb.array([]),
    });
  }

  newFileUploadForm(): FormGroup {
    return this.fb.group({
      documentType: [null],
      documentName: [null],
      documentDescription: [null],
      files: [null],
    });
  }

  fileUpload(): FormArray {
    return this.fileUploadForm.get('fileUpload') as FormArray;
  }

  addFileUpload() {
    this.fileUpload().push(this.newFileUploadForm());
  }

  removeFileUpload(i: number) {
    this.fileUpload().removeAt(i);
  }

  getUserAccessDetails() {
    let userId = localStorage.getItem('loginUserId');

    this.claimsService.getUserAccessDetails(userId).subscribe((res) => {
      this.rolesAccessData = res;

      console.log('this.rolesAccessData::', this.rolesAccessData);
      this.getSelectedClaimsData();
    });
  }
  checkGroupAccess(groupName) {
    let showGroupName = false;
    let jobtypes = this.rolesAccessData['JobTypes'];
    let isExist = false;
    const selectedJobType = jobtypes.filter(
      (jobtype) => jobtype.name === this.selectedClaim
    );

    if (
      selectedJobType.length > 0 &&
      selectedJobType[0]['fullAccess'] == true
    ) {
      showGroupName = true;
    } else {
      let groupAccessList = selectedJobType[0]['groupAccess'];
      //     console.log('groupAccessList::', groupAccessList);
      groupAccessList.forEach((element) => {
        if (element['name'] == groupName) {
          if (element['value'] == true) {
            showGroupName = true;
          } else {
            showGroupName = false;
          }
        }
      });
    }
    return showGroupName;
  }

  getSelectedClaimsData() {
    this.items = [];
    this.claimDetails = [];
    this.apiURL = this.claimsService.apiURL;
    this.loginEmail = localStorage.getItem('loginUserName');
    if (this.claimId && this.selectedClaim) {
      this.getSingleMasterJob(1);
      this.isViewOnly = this.mode === 'view';
      this.isEditMode = this.mode === 'edit';
    } else if (this.selectedClaim) {
      this.claimsTitle = this.selectedClaim;
      this.claimsSubTitle = 'Register a new Loss.';
      this.getAllMasterTypeJobsList(2);
    }
  }

  assignUniqueId(fields) {
    const uniqueIdMap = {};
    return fields.map((field) => {
      if (uniqueIdMap[field.groupName]) {
        field['uniqueId'] = uniqueIdMap[field.groupName];
      } else {
        let unq = Math.floor(Math.random() * 100000000000).toString();
        uniqueIdMap[field.groupName] = unq;
        field['uniqueId'] = unq;
      }
      return field;
    });
  }

  sortByOrderId(fields) {
    return fields.sort((a,b) => {
      return a.order && b.order ? a.order - b.order : 1;
    });
  }

  getAllMasterTypeJobsList(type) {
    var input = {
      label: this.selectedClaim,
    };

    this.claimsService.getAllMasterTypeJobsList(input).subscribe(
      (res) => {
        if (res['status'] == 1) {
          var result = res['orgs'];
          this.fieldsList = this.sortByOrderId(result[0].Fields);
          this.fieldsList = this.assignUniqueId(this.fieldsList);
          this.fieldsList.map((row) => {
            row.fieldName = row.name.replace(/_/g, ' ');
          });

          const dependentMap = {};
          if (this.fieldsList) {
            for (var i = 0; i < this.fieldsList.length; i++) {
              let dependentOptions = {};
              var ifContainsSlash =
                this.fieldsList[i]?.allowedValues?.includes('|');
              var ifContainsQColon =
                this.fieldsList[i]?.allowedValues?.includes('q:');
              var allowedValuesData: any = [];
              if (ifContainsSlash) {
                allowedValuesData = this.fieldsList[i]?.allowedValues
                  ?.split('|')
                  .map((o) => {
                    return {
                      value: o.replace(this.matchTextBetweenBrackets, ''),
                      code: o,
                    };
                  });
              }
              if (ifContainsQColon) {
                if (
                  Object.keys(dependentMap).includes(this.fieldsList[i].name)
                ) {
                  allowedValuesData = [];
                  dependentOptions = Object.keys(
                    this.fieldsList[i].allowedValuesResults
                  ).reduce((acc, o) => {
                    const [parentCode, childCode] =
                      this.fieldsList[i].allowedValuesResults[o].split('~');
                    const childValue = o.split('~')[1];
                    if (acc[parentCode]?.length > 0) {
                      acc[parentCode].push({
                        value: childValue.replace(this.matchTextBetweenBrackets, ''),
                        code: childCode,
                      });
                    } else {
                      acc[parentCode] = [
                        {
                          value: childValue.replace(this.matchTextBetweenBrackets, ''),
                          code: childCode,
                        },
                      ];
                    }
                    return acc;
                  }, {});
                } else {
                  allowedValuesData = Object.keys(
                    this.fieldsList[i].allowedValuesResults
                  ).map((k) => {
                    return {
                      value: k.replace(this.matchTextBetweenBrackets, ''),
                      code: this.fieldsList[i].allowedValuesResults[k],
                    };
                  });
                }
              }
              // If this field values is required by another field, saving in Map
              if (
                this.fieldsList[i].dependentFields &&
                this.fieldsList[i].dependentFields != ''
              ) {
                this.fieldsList[i].dependentFields.split(',').forEach(f => {
                  dependentMap[f] = {
                    parentName: this.fieldsList[i].name,
                    value: this.fieldsList[i].allowedValuesResults,
                  };
                });
              }

              var obj = {
                name: this.fieldsList[i].name,
                lang_label: this.fieldsList[i].lang_label,
                fieldName: this.fieldsList[i].name.replace(/_/g, ' '),
                type: this.fieldsList[i].type,
                mandatory: this.fieldsList[i].mandatory,
                groupName: this.fieldsList[i].groupName,
                propgateValueToSubFormFields:
                  this.fieldsList[i].propgateValueToSubFormFields,
                reportAllowedValues: this.fieldsList[i].reportAllowedValues,
                multiselect: this.fieldsList[i].multiselect,
                addMore: this.fieldsList[i].addMore ? true : false,
                mask: this.fieldsList[i].mask,
                options: allowedValuesData,
                dependentFields: this.fieldsList[i].dependentFields,
                value: '',
                id: i,
                parent: 1,
                uniqueId: this.fieldsList[i]['uniqueId'],
              };

              if (this.fieldsList[i].name === 'Initiators_email') {
                obj.value = this.loginEmail;
              }

              // if (this.fieldsList[i].name === 'Initiators_email' || this.fieldsList[i].name === 'Initiators_BU') {
              //   obj.addMore = true;
              // }

              if (Object.keys(dependentOptions).length !== 0) {
                obj['dependentOptions'] = dependentOptions;
              }

              if (this.fieldsList[i].type !== 'Report') {
                this.fieldsList1.push(obj);
              }
            }
          }
          this.updateAddMoreMap(this.fieldsList1);

          const differentGroups = this.fieldsList
            .filter((field) => {
              return !['Report', 'SubJob', 'ServiceType'].includes(field.type);
            })
            .map((s) => {
              console.log('s.groupName::', s.groupName);
              return s.groupName;
            });

          //this.groupNamesList = [...new Set(differentGroups)];

          let listdata = [...new Set(differentGroups)];

          let finalList = [];

          listdata.forEach((element) => {
            if (this.checkGroupAccess(element)) {
              finalList.push(element);
            }
          });
          this.groupNamesList = finalList;
          console.log('finalList::', finalList);

          this.subJobTabs = this.fieldsList
            .filter((field) => {
              return (
                field.type === 'SubJob' &&
                (!this.mode || (this.mode && field.addMore))
              );
            })
            .map((field) => {
              if (this.mode) {
                field.jobId = this.claimDetails?.id;
              }
              return field;
            });

          const jobActions = this.fieldsList.filter((field) => {
            return field.type === 'ServiceType';
          })[0]?.allowedValuesResults;

          this.jobActionOptions = Object.keys(jobActions ? jobActions : {}).map(
            (option) => {
              return {
                val: option.replace(this.matchTextBetweenBrackets, ''),
                code: option.replace(this.matchTextBetweenBrackets, ''),
              };
            }
          );

          if (type == 1) {
            this.fieldsList1 = this.formatFieldValues(
              this.fieldsList1,
              this.claimDetails
            );

            if (this.jobTypeName.toLowerCase().indexOf('email') == -1) {
              const emailsFieldsList = this.getEmailsFromFields(
                this.fieldsList1
              );
              this.emailsFieldsList = [
                ...this.emailsFieldsList,
                ...emailsFieldsList,
              ];
            }
            this.fieldsList1 = this.repeatFieldListForGroupIfAddMore(
              this.fieldsList1
            );
          } else {
            this.fieldsList1 = this.formatFieldValues(
              this.fieldsList1,
              this.claimDetails
            );
          }
        }
      },
      (error: any) => console.log(error)
    );
  }

  formatFieldValues(fieldList, claimDetails) {
    const parentDropdownValue = {};
    var claimKeys = [];
    var key = '';
    const date_regex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    claimKeys = Object.keys(claimDetails);

    for (var a = 0; a < fieldList.length; a++) {
      key = fieldList[a]['name'];

      if (
        claimKeys.indexOf(key) !== -1 ||
        fieldList[a]['type'] === 'Attachment'
      ) {
        if (fieldList[a]['type'] === 'Attachment') {
          const keyName = key + '_Name';
          const keyUrl = key + '_URL';
          fieldList[a][keyName] = claimDetails[keyName];
          fieldList[a][keyUrl] = claimDetails[keyUrl];
          fieldList[a]['value'] = claimDetails[keyName];
        } else if (
          fieldList[a]['type'] === 'Date' ||
          fieldList[a]['type'] === 'Date Time' ||
          fieldList[a]['type'] === 'Auto Date' ||
          fieldList[a]['type'] === 'Auto Date Time' ||
          fieldList[a]['type'] === 'Time'
        ) {
          if (Array.isArray(claimDetails[key])) {
            fieldList[a].value = claimDetails[key].map((date) => {
              if (date_regex.test(date)) {
                let dateParts = date.split('/');
                return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
              } else {
                return date ? new Date(date) : '';
              }
            });
          } else {
            if (date_regex.test(claimDetails[key])) {
              let dateParts = claimDetails[key].split('/');
              fieldList[a].value = new Date(
                +dateParts[2],
                dateParts[1] - 1,
                +dateParts[0]
              );
            } else {
              fieldList[a].value = claimDetails[key]
                ? new Date(claimDetails[key])
                : '';
            }
          }
        } else if (fieldList[a]['type'] === 'Selection') {
          const fieldName = fieldList[a].name;
          if (Object.keys(parentDropdownValue).includes(fieldName)) {
            const options = fieldList[a].dependentOptions ?
              fieldList[a].dependentOptions[parentDropdownValue[fieldName]] : [];
            fieldList[a].options = options ? options : [];
          }
          if (Array.isArray(claimDetails[key])) {
            const selectedValues = [];
            claimDetails[key].forEach((value) => {
              const val = value.replace(this.matchTextBetweenBrackets, '');
              const selectedValue = fieldList[a].options.filter(
                (o) => o.code === val || o.value === val
              );
              selectedValues.push(
                selectedValue.length > 0 ? selectedValue[0].code : ''
              );
            });
            fieldList[a].value = selectedValues;
          } else {
            const selectedValue = fieldList[a].options.filter((o) => {
              const val = claimDetails[key]
                ? claimDetails[key].replace(this.matchTextBetweenBrackets, '')
                : '';
              return o.code === val || o.value === val;
            });
            fieldList[a].value =
              selectedValue.length > 0 ? selectedValue[0].code : '';
          }
          if (
            fieldList[a]['dependentFields'] &&
            fieldList[a]['dependentFields'] != ''
          ) {
            fieldList[a].dependentFields.split(',').forEach(f => {
              parentDropdownValue[f] =
              Array.isArray(fieldList[a].value)
                ? fieldList[a].value[0]
                : fieldList[a].value;
            });

          }

        } else {
          fieldList[a].value = claimDetails[key];
        }
      }
    }
    fieldList = this.trimJobIdFromSelectorValues(fieldList);
    return fieldList;
  }

  trimJobIdFromSelectorValues(lists) {
    return lists.map((list) => {
      if (list.type === 'Selection') {
        list.value =
          list?.value && Array.isArray(list.value)
            ? list.value.map((l) => l.replace(this.matchTextBetweenBrackets, ''))
            : list.value? list.value.replace(this.matchTextBetweenBrackets, '') : '';
        list.options = list?.options?.map((o) => {
          o.value = o.value?.replace(this.matchTextBetweenBrackets, '');
          return o;
        });
      }
      return list;
    });
  }

  repeatFieldListForGroupIfAddMore(fieldList, groupName = 'groupName') {
    let newItems = [];
    let newSortedItems = {};
    const fieldCountForEachGroup = fieldList.reduce((acc, list) => {
      const grpName = list.groupName ? list.groupName : list[groupName];
      if (acc[grpName]) {
        acc[grpName] = {
          count: acc[grpName].count + 1,
          lastItem: list.name,
        };
      } else {
        acc[grpName] = {
          count: 1,
          lastItem: list.name,
        };
      }
      return acc;
    }, {});

    fieldList.forEach((eachField) => {
      const grpName = groupName ? eachField[groupName] : eachField.subFormName;
      if (this.addMoreGroupMap[grpName] && Array.isArray(eachField.value)) {
        let urlArr,
          arr = eachField.value;
        if (eachField.type === 'Attachment') {
          arr = eachField[eachField.name + '_Name'];
          urlArr = eachField[eachField.name + '_URL'];
        }
        arr.forEach((element, index) => {
          if (index === 0) {
            if (eachField.type === 'Attachment') {
              eachField[eachField.name + '_Name'] = arr[index];
              eachField[eachField.name + '_URL'] = urlArr[index];
              eachField.value = arr[index];
              this.fileAttachments.push({
                Image_Name: arr[index],
                URL: urlArr[index],
              });
              this.allAttachments.push({
                name: arr[index],
                url: urlArr[index],
                groupName: eachField.groupName
                  ? eachField.groupName
                  : eachField[groupName],
              });
            } else {
              eachField.value = arr[index];
            }
            eachField['uniqueId'] = eachField['uniqueId']
              ? eachField['uniqueId'].replace(/\d{1}$/, index)
              : '';
            const grpName = eachField.groupName
              ? eachField.groupName
              : eachField[groupName];
            eachField.showRemove =
              fieldCountForEachGroup[grpName].lastItem == eachField.name;
          } else {
            const newEle = { ...eachField };
            if (eachField.type === 'Attachment') {
              newEle[eachField.name + '_Name'] = arr[index];
              newEle[eachField.name + '_URL'] = urlArr[index];
              newEle.value = arr[index];
              this.fileAttachments.push({
                Image_Name: arr[index],
                URL: urlArr[index],
              });
              this.allAttachments.push({
                name: arr[index],
                url: urlArr[index],
                groupName: eachField.groupName
                  ? eachField.groupName
                  : eachField[groupName],
              });
            } else {
              newEle.value = element;
            }
            newEle.extra = true;
            eachField['uniqueId'] = eachField['uniqueId']
              ? eachField['uniqueId'].replace(/\d{1}$/, index)
              : '';
            const grpName = eachField.groupName
              ? eachField.groupName
              : eachField[groupName];
            newEle.showRemove =
              fieldCountForEachGroup[grpName].lastItem == eachField.name;
            // newItems.push(newEle);
            if (newSortedItems[grpName]) {
              if (newSortedItems[grpName][index - 1]) {
                newSortedItems[grpName][index - 1].push(newEle);
              } else {
                newSortedItems[grpName][index - 1] = [newEle];
              }
            } else {
              newSortedItems[grpName] = {};
              newSortedItems[grpName][index - 1] = [newEle];
            }
          }
        });
      } else if (Array.isArray(eachField.value)) {
        eachField.value = eachField.value[0];
      } else {
        if (eachField.type === 'Attachment') {
          this.updateFileAttachments(
            eachField[eachField.name + '_Name'],
            eachField[eachField.name + '_URL']
          );

          this.allAttachments.push({
            name: eachField[eachField.name + '_Name'],
            url: eachField[eachField.name + '_URL'],
            groupName: eachField.groupName
              ? eachField.groupName
              : eachField[groupName],
          });
        }
      }
    });

    Object.keys(newSortedItems).forEach((k) => {
      Object.keys(newSortedItems[k]).forEach((o) => {
        newItems = [...newItems, ...newSortedItems[k][o]];
      });
    });
    fieldList = [...fieldList, ...newItems];
    return fieldList;
  }

  updateFileAttachments(name, url) {
    if (name && url) {
      this.fileAttachments.push({
        Image_Name: name,
        URL: url,
      });
    }
  }

  expand(type) {
    if (type == 'all') {
      for (let i = 0; i < this.groupNamesList.length; i++) {
        this.activeDataTab[i] = true;
      }
      for (let i = 0; i < this.subFormNamesInMainForm.length; i++) {
        this.activeSubForm[i] = true;
      }
    } else {
      for (let i = 0; i < this.groupNamesList.length; i++) {
        this.activeDataTab[i] = false;
      }
      for (let i = 0; i < this.subFormNamesInMainForm.length; i++) {
        this.activeSubForm[i] = false;
      }
    }
  }

  getSingleMasterJob(type) {
    var inputData = {
      claimId: this.claimId,
    };

    this.nextJobStatusList = [];
    this.emailTemplateInfo = {};

    this.claimsService.getSingleMasterJob(inputData).subscribe(
      async (res) => {
        if (res['status'] == 1) {
          this.items = [];
          var claimData = res['jobs'];
          this.claimDetails = claimData[0];
          this.claimsTitle = 'CLAIM DETAILS';
          this.claimsSubTitle = '';
          this.claimNo = this.claimDetails["Loan Number"];
          this.nextJobStatus = this.claimDetails.Next_Job_Statuses;
          this.createdSubJobs = this.claimDetails.CreatedSubJobs;
          this.jobComments = this.claimDetails.jobComments;

          this.createdByFullName = this.claimDetails.createdByFullName;
          let lastModifiedDate = this.claimDetails.lastModifiedDate
            ? this.claimDetails.lastModifiedDate
            : '';

          if (lastModifiedDate != '') {
            let datesplitarray = lastModifiedDate.split(' ');

            let datepart = datesplitarray[0];
            if (datepart) {
              let datepartarray = datepart.split('-');
              let fdate =
                datepartarray[2] +
                '-' +
                datepartarray[1] +
                '-' +
                datepartarray[0];
              let timedata = datesplitarray[1];
              let timearray = timedata.split(':');
              let fDate1 = new Date(
                fdate +
                  ' ' +
                  timearray[0] +
                  ':' +
                  timearray[1] +
                  ':' +
                  timearray[2]
              );

              fDate1.setHours(fDate1.getHours() + 5);
              fDate1.setMinutes(fDate1.getMinutes() + 30);
              this.modifiedOn = fDate1;
            }
          } else {
            this.modifiedOn = '';
          }
          var myDate = new Date(this.modifiedOn);

          this.jobId = this.claimDetails.id;
          this.userId = this.claimDetails.createdById;
          this.jobTypeName = this.claimDetails.jobTypeName;
          this.itemCode = this.claimDetails.ItemCode;
          this.fileAttachments = this.claimDetails.Attachments;
          this.currentJobStatus = this.claimDetails.Current_Job_Status
            ? this.claimDetails.Current_Job_Status
            : '';
          this.emailTemplateInfo = this.claimDetails.EmailTemplateInfo;
          this.allAttachments = this.fileAttachments;

          this.jobComments.sort(function (a, b) {
            return a.commentId - b.commentId;
          });

          this.jobComments.forEach((element) => {
            element.comment = element.comment.split("'")[1];
            var previousJobObj = {
              label: element.comment,
              status: 0,
            };
            this.items.push(previousJobObj);
          });

          var index = this.items.length - 1;
          this.items.splice(index, 1);
          var currentJob = {
            label: this.claimDetails.Current_Job_Status,
            status: 1,
          };
          this.items.push(currentJob);

          this.nextJobStatus.forEach((element) => {
            var jobObj = {
              label: element.Status_Name,
              status: 2,
            };
            this.items.push(jobObj);
            this.nextJobStatusList.push(element.Status_Name);
          });
          this.subFormFieldsInMainForm = [];

          if (this.createdSubJobs.length != 0) {
            for (var cs = 0; cs < this.createdSubJobs.length; cs++) {
              var apiUrl =
                this.apiURL +
                'Services/getMasters?type=allJobTypes|' +
                this.createdSubJobs[cs].jobTypeName +
                '&mallId='+ MC.MALL_ID +'&dt=CAMPAIGNS';
              var result1 = await this.httpClient.get(apiUrl).toPromise();

              var orgs = result1['orgs'];
              var subFormFields = this.sortByOrderId(orgs[0].Fields);
              subFormFields = this.assignUniqueId(subFormFields);

              var subFormKeys = Object.keys(this.createdSubJobs[cs]);

              for (var sf = 0; sf < subFormFields.length; sf++) {
                if (['SubJob'].includes(subFormFields[sf].type)) {
                  continue;
                }
                var ifContainsSlash = subFormFields[sf]?.allowedValues
                  ? subFormFields[sf].allowedValues.includes('|')
                  : null;
                var ifContainsQColon = subFormFields[sf].allowedValues
                  ? subFormFields[sf].allowedValues.includes('q:')
                  : null;
                var allowedValuesData: any = [];
                if (ifContainsSlash) {
                  var allowedValues1 =
                    subFormFields[sf]?.allowedValues?.split('|');

                  for (var j = 0; j < allowedValues1.length; j++) {
                    var aObj = {
                      value: allowedValues1[j],
                      code: allowedValues1[j],
                    };
                    allowedValuesData.push(aObj);
                  }
                }
                if (ifContainsQColon) {
                  var allowedValues2 = subFormFields[sf].allowedValuesResults;
                  var result = Object.keys(allowedValues2).map((key) => [
                    key,
                    allowedValues2[key],
                  ]);

                  for (var k = 0; k < result.length; k++) {
                    var kObj = {
                      value: result[k][0],
                      code: result[k][1],
                    };
                    allowedValuesData.push(kObj);
                  }
                }

                var subFormObj = {
                  name: subFormFields[sf]['name'],
                  fieldName: subFormFields[sf]['name'].replace(/_/g, ' '),
                  lang_label: subFormFields[sf].lang_label,
                  type: subFormFields[sf].type,
                  mandatory: subFormFields[sf].mandatory,
                  groupName: subFormFields[sf].groupName,
                  multiselect: subFormFields[sf].multiselect,
                  propgateValueToSubFormFields:
                    subFormFields[sf].propgateValueToSubFormFields,
                  reportAllowedValues: subFormFields[sf].reportAllowedValues,
                  addMore: subFormFields[sf].addMore ? true : false,
                  mask: subFormFields[sf].mask,
                  options: allowedValuesData,
                  value: null,
                  subFormName:
                    this.createdSubJobs[cs].jobTypeName +
                    ':-' +
                    this.createdSubJobs[cs].id,
                  itemCode: this.createdSubJobs[cs].ItemCode,
                  parent: 1,
                  uniqueId: subFormFields[sf]['uniqueId'],
                };
                var key = subFormFields[sf]['name'];

                const date_regex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
                const parentDropdownValue = {};

                if (
                  subFormKeys.indexOf(key) !== -1 ||
                  subFormFields[sf]['type'] === 'Attachment'
                ) {
                  if (subFormFields[sf]['type'] === 'Attachment') {
                    const keyName = key + '_Name';
                    const keyUrl = key + '_URL';
                    subFormObj[keyName] = this.createdSubJobs[cs][keyName];
                    subFormObj[keyUrl] = this.createdSubJobs[cs][keyUrl];
                    subFormObj['value'] = this.createdSubJobs[cs][keyName];
                  } else if (
                    subFormFields[sf]['type'] === 'Date' ||
                    subFormFields[sf]['type'] === 'Date Time' ||
                    subFormFields[sf]['type'] === 'Auto Date' ||
                    subFormFields[sf]['type'] === 'Auto Date Time' ||
                    subFormFields[sf]['type'] === 'Time'
                  ) {
                    if (Array.isArray(this.createdSubJobs[cs][key])) {
                      subFormObj.value = this.createdSubJobs[cs][key].map(
                        (date) => {
                          if (date_regex.test(date)) {
                            let dateParts = date.split('/');
                            return new Date(
                              +dateParts[2],
                              dateParts[1] - 1,
                              +dateParts[0]
                            );
                          } else {
                            return date ? new Date(date) : '';
                          }
                        }
                      );
                    } else {
                      if (date_regex.test(this.createdSubJobs[cs][key])) {
                        let dateParts = this.createdSubJobs[cs][key].split('/');
                        subFormObj.value = new Date(
                          +dateParts[2],
                          dateParts[1] - 1,
                          +dateParts[0]
                        );
                      } else {
                        subFormObj.value = this.createdSubJobs[cs][key]
                          ? new Date(this.createdSubJobs[cs][key])
                          : '';
                      }
                    }
                  } else if (subFormFields[sf]['type'] === 'Selection') {
                    const fieldName = subFormFields[sf].name;
                    if (Object.keys(parentDropdownValue).includes(fieldName)) {
                      const options =
                        subFormFields[sf].dependentOptions[
                          parentDropdownValue[fieldName]
                        ];
                      subFormFields[sf].options = options ? options : [];
                    }
                    if (Array.isArray(this.createdSubJobs[cs][key])) {
                      const selectedValues = [];
                      this.createdSubJobs[cs][key].forEach((val) => {
                        const selectedValue = subFormObj?.options?.filter(
                          (o) => o.code === val || o.value === val
                        );
                        selectedValues.push(
                          selectedValue.length > 0 ? selectedValue[0].code : ''
                        );
                      });
                      subFormObj.value = selectedValues;
                    } else {
                      const selectedValue = subFormObj?.options?.filter(
                        (o) =>
                          o.code === this.createdSubJobs[cs][key] ||
                          o.value === this.createdSubJobs[cs][key]
                      );
                      subFormObj.value =
                        selectedValue?.length > 0 ? selectedValue[0].code : '';
                    }
                    if (
                      subFormFields[sf]['dependentFields'] &&
                      subFormFields[sf]['dependentFields'] != ''
                    ) {
                      parentDropdownValue[
                        subFormFields[sf]['dependentFields']
                      ] = Array.isArray(subFormFields[sf].value)
                        ? subFormFields[sf].value[0]
                        : subFormFields[sf].value;
                    }
                  } else {
                    subFormObj.value = this.createdSubJobs[cs][key];
                  }
                }

                this.subFormFieldsInMainForm.push(subFormObj);
              }

              const apiUrlSubjob =
                this.apiURL +
                'Services/getMasters?jobId=' +
                this.createdSubJobs[cs].id;
              const responseSubjob = await this.httpClient
                .get(apiUrlSubjob)
                .toPromise();
              const createdSubJobs = responseSubjob['jobs'][0]?.CreatedSubJobs;

              if (createdSubJobs && createdSubJobs.length > 0) {
                createdSubJobs.forEach(async (job) => {
                  const apiUrl =
                    this.apiURL +
                    'Services/getMasters?type=allJobTypes|' +
                    job.jobTypeName +
                    '&mallId='+ MC.MALL_ID +'&dt=CAMPAIGNS';
                  const parentJobTypeName = this.createdSubJobs[cs].jobTypeName;
                  const response = await this.httpClient
                    .get(apiUrl)
                    .toPromise();
                  let subFormFields = this.sortByOrderId(response['orgs'][0].Fields);
                  subFormFields = this.assignUniqueId(
                    subFormFields
                  );
                  subFormFields = this.getFormattedSubjobOnTabsFields(
                    subFormFields,
                    job.jobTypeName
                  );
                  subFormFields.forEach((element) => {
                    element.subFormName =
                      parentJobTypeName + '-' + job.jobTypeName;
                  });
                  subFormFields = this.formatFieldValues(subFormFields, job);
                  this.subFormFieldsInMainForm = [
                    ...this.subFormFieldsInMainForm,
                    ...subFormFields,
                  ];
                  this.updateSubFormFieldsInMainForm();
                });
              }
            }
            this.updateSubFormFieldsInMainForm();
          }

          if (type == 1) {
            this.getAllMasterTypeJobsList(1);
          }
        }
      },
      (error: any) => console.log(error)
    );
  }

  updateAddMoreMap(fieldList, grpName = '') {
    fieldList.forEach((ele) => {
      const groupName = grpName ? ele[grpName] : ele.groupName;
      this.addMoreGroupMap[groupName] =
        this.addMoreGroupMap[groupName] || ele.addMore;
    });
  }
  updateSubFormFieldsInMainForm() {
    this.subFormFieldsInMainForm = this.trimJobIdFromSelectorValues(
      this.subFormFieldsInMainForm
    );
    this.updateAddMoreMap(this.subFormFieldsInMainForm, 'subFormName');
    const emailsFieldsList = this.getEmailsFromFields(
      this.subFormFieldsInMainForm
    );
    this.emailsFieldsList = [...this.emailsFieldsList, ...emailsFieldsList];

    this.subFormFieldsInMainForm = this.repeatFieldListForGroupIfAddMore(
      this.subFormFieldsInMainForm,
      'subFormName'
    );

    const differentGroups = this.subFormFieldsInMainForm.map((s) => {
      return s.subFormName;
    });
    this.subFormNamesInMainForm = [...new Set(differentGroups)];
  }

  updateJobStatus(jobStatus, type) {
    this.jobStatus = jobStatus;
    this.subfieldsList1 = [];
    this.subFormGroupNames = [];

    if (type == 2) {
      var inputData = {
        jobSeqNo: this.jobStatus.Status_Id,
        jobId: this.jobId,
        userId: this.userId,
      };

      this.claimsService.updateConsumerJobMetaData(inputData).subscribe(
        (res) => {
          if (res['status'] == 1) {
            alert(res['msg']);
            this.getSelectedClaimsData();
            this.getSingleMasterJob(2);
            this.subfieldsList1 = [];
            this.nextJobStatus = [];
            this.jobComments = [];
            this.createdSubJobs = [];
            this.subForm = [];
            this.fieldsList = [];
            this.groupNamesList = [];
            this.fieldsList1 = [];
            this.addMoreData = [];
            this.subfieldsList1 = [];
            this.subFormGroupNames = [];
            this.subFormFieldsInMainForm = [];
            this.subFormNamesInMainForm = [];
            this.uploadFiles = [];
            this.fileAttachments = [];
          } else {
            alert(res['msg']);
          }
        },
        (error: any) => console.log(error)
      );
    }
    if (this.jobStatus.Sub_Jobtype_Forms.length != 0) {
      this.subFormName = jobStatus.Sub_Jobtype_Forms[0].Form_Name;
      this.formId = jobStatus.Sub_Jobtype_Forms[0].Form_Id;
      var input = {
        label: this.subFormName,
      };

      this.claimsService.getAllMasterTypeJobsList(input).subscribe(
        (res) => {
          if (res['status'] == 1) {
            var orgs = res['orgs'];
            this.subForm = this.sortByOrderId(this.subForm);
            this.subForm = this.assignUniqueId(orgs[0].Fields);
            this.subfieldsList1 = this.getFormattedSubjobOnTabsFields(
              this.subForm,
              orgs[0].type
            );
            this.subfieldsList1 = this.subfieldsList1.filter((field) => {
              return !['SubJob'].includes(field.type);
            });

            this.subfieldsList1.forEach((ele) => {
              const grpName = ele.groupName ? ele.groupName : ele.subGroup;
              this.addMoreGroupMap[grpName] =
                this.addMoreGroupMap[grpName] || ele.addMore;
            });

            const differentGroups = this.subfieldsList1.map((s) => {
              return s.groupName ? s.groupName : s.subGroup;
            });
            this.subFormGroupNames = [...new Set(differentGroups)];
          }
        },
        (error: any) => console.log(error)
      );
    } else {
      var inputData = {
        jobSeqNo: jobStatus.Status_Id,
        jobId: this.jobId,
        userId: this.userId,
      };

      this.claimsService.updateConsumerJobMetaData(inputData).subscribe(
        (res) => {
          if (res['status'] == 1) {
            alert(res['msg']);
            this.getSelectedClaimsData();
            this.subfieldsList1 = [];
            this.nextJobStatus = [];
            this.jobComments = [];
            this.createdSubJobs = [];
            this.subForm = [];
            this.fieldsList = [];
            this.groupNamesList = [];
            this.fieldsList1 = [];
            this.addMoreData = [];
            this.subfieldsList1 = [];
            this.subFormGroupNames = [];
            this.subFormFieldsInMainForm = [];
            this.subFormNamesInMainForm = [];
            this.uploadFiles = [];
            this.fileAttachments = [];
            this.getSingleMasterJob(2);
          } else {
            alert(res['msg']);
          }
        },
        (error: any) => console.log(error)
      );
    }
  }

  saveSubMainForm(subFormFieldsInMainForm, grpName) {
    const filteredGroup = subFormFieldsInMainForm.filter((form) => {
      return form.subFormName === grpName;
    });
    this.subFormSave(filteredGroup, grpName);
  }

  subFormSave(value, subFormGroupName = '', jobId = '') {
    let list = [];
    let jobFilesList = [];
    let itemCode = value[0]?.itemCode;
    let trimGroupName = subFormGroupName?.split(':-')[0];
    let listObj = {};

    this.formSubmit = true;
    if(!this.isFromValid(value)) {
      return false;
    }
    this.formSubmit = false;
    const formatted = this.formatPostValues(value, subFormGroupName);
    listObj = formatted.listObj;
    jobFilesList = formatted.jobFilesList;

    Object.keys(listObj).forEach((key) => {
      listObj[key] = Array.isArray(listObj[key])
        ? listObj[key].join('|')
        : listObj[key];
    });
    if (itemCode) {
      listObj['ItemCode'] = itemCode;
    }

    list.push(listObj);
    var inputData = {
      userId: this.userId,
      clientEmail: this.loginEmail,
      jobId: jobId ? jobId : this.jobId,
      subFormList: JSON.stringify(list),
      subFormName: trimGroupName ? trimGroupName : this.subFormName,
      files: jobFilesList,
    };

    this.claimsService.loader.next(true);
    this.claimsService.saveSubJobPost(inputData).subscribe(
      (res) => {
        this.claimsService.loader.next(false);
        var result = res['myHashMap'];
        if (result['status'] == 1) {
          alert(result['message']);
          if (this.serviceTabForm && this.jobActionServiceSubjobs.length > 0) {
            this.jobActionServiceSubjobs.forEach((job) => {
              job.jobId = result.jobId;
            });
          }
          if (
            !this.mode &&
            this.subJobTabs.length > 0 &&
            this.currentTabIndex < this.subJobTabs.length
          ) {
            this.currentTabIndex++;
            this.handleTabChange({ index: this.currentTabIndex });
          } else if (
            !this.mode &&
            this.subJobTabs.length > 0 &&
            this.currentTabIndex === this.subJobTabs.length
          ) {
            this.router.navigate(['/ClaimModule/jobsList'], {
              fragment: this.selectedClaim,
            });
          } else if (
            this.serviceTabForm &&
            this.jobActionServiceSubjobs.length > 0 &&
            this.currentChildTabIndex < this.jobActionServiceSubjobs.length
          ) {
            this.currentChildTabIndex++;
            this.serviceTabForm = false;
            this.handleServiceTabChange({ index: this.currentChildTabIndex });
          } else if (
            this.serviceTabForm &&
            this.jobActionServiceSubjobs.length > 0 &&
            this.currentChildTabIndex === this.jobActionServiceSubjobs.length
          ) {
            this.router.navigate(['/ClaimModule/jobsList'], {
              fragment: this.selectedClaim,
            });
          } else {
            this.subfieldsList1 = [];
            if (this.jobStatus) {
              this.updateJobStatus(this.jobStatus, 2);
            }
            window.location.reload();
          }
        } else {
          alert(res['message']);
        }
      },
      (error: any) => console.log(error)
    );
  }

  public dropped(files: NgxFileDropEntry[], registerForm) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          registerForm.value = file;
          registerForm.fileName = file.name;
          if (registerForm[registerForm.name + '_URL']) {
            delete registerForm[registerForm.name + '_URL'];
            delete registerForm[registerForm.name + '_Name'];
          }
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  public addUploadFiles(event, i) {
    this.uploadFiles[i].files = event.target.files[0];
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }

  finalUploadFiles() {
    if (this.uploadFiles.length == 0) {
      alert('upload atlease one file');
      return;
    }

    const attachmentFields = this.uploadFiles.map((file) => {
      return {
        documentName: file.files.name,
        documentType: file.documentType,
        description: file.documentDescription,
        remarks: '',
      };
    });

    const formData = new FormData();
    formData.append('jobId', this.jobId.toString());
    formData.append('orgId', '3');
    this.uploadFiles.forEach((file) => {
      formData.append('srcFile', file.files, file.files.name);
    });
    //formData.append('attachmentFields', JSON.stringify(attachmentFields));

    this.claimsService.addFileAttachments(formData).subscribe(
      (res) => {
        this.getSelectedClaimsData();
        this.subfieldsList1 = [];
        this.nextJobStatus = [];
        this.jobComments = [];
        this.createdSubJobs = [];
        this.subForm = [];
        this.fieldsList = [];
        this.groupNamesList = [];
        this.fieldsList1 = [];
        this.addMoreData = [];
        this.subfieldsList1 = [];
        this.subFormGroupNames = [];
        this.subFormFieldsInMainForm = [];
        this.subFormNamesInMainForm = [];
        this.uploadFiles = [];
        this.fileAttachments = [];
      },
      (error: any) => console.log(error)
    );
  }

  handleAddressChange(address: any, registerForm) {
    this.userAddress = address.formatted_address;
    this.userLatitude = address.geometry.location.lat();
    this.userLongitude = address.geometry.location.lng();

    registerForm.value = address.formatted_address;
  }

  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  AddingDetails(data, formType) {
    if (formType === 'mainForm') {
      if (this.fieldsList1) {
        const uniqueId = Math.floor(Math.random() * 100000000000).toString();
        var id = this.newGuid();
        var sameGroupNameData: any = [];
        for (var i = 0; i < this.fieldsList1.length; i++) {
          var ifgroupName = this.fieldsList1[i].groupName == data;
          if (ifgroupName) {
            var obj = {
              name: this.fieldsList1[i].name,
              fieldName: this.fieldsList1[i].fieldName,
              lang_label: this.fieldsList1[i].lang_label,
              type: this.fieldsList1[i].type,
              mandatory: this.fieldsList1[i].mandatory,
              groupName: this.fieldsList1[i].groupName,
              propgateValueToSubFormFields:
                this.fieldsList1[i].propgateValueToSubFormFields,
              reportAllowedValues: this.fieldsList1[i].reportAllowedValues,
              multiselect: this.fieldsList1[i].multiselect,
              addMore: this.fieldsList1[i].addMore,
              value: this.fieldsList1[i].value,
              mask: this.fieldsList1[i].mask,
              options: this.fieldsList1[i].options,
              dependentOptions: this.fieldsList1[i]?.dependentOptions,
              id: this.fieldsList1[i].id,
              extra: this.fieldsList1[i].extra,
            };
            sameGroupNameData.push(obj);
          }
        }
        if (sameGroupNameData) {
          let showRemove = false;

          sameGroupNameData = sameGroupNameData.filter((data) => {
            return !data['extra'];
          });

          let addFielCount = sameGroupNameData.length - 1;

          for (var j = 0; j < sameGroupNameData.length; j++) {
            if (j == addFielCount) {
              showRemove = true;
            }
            var aobj = {
              name: sameGroupNameData[j].name,
              fieldName: sameGroupNameData[j].fieldName,
              lang_label: sameGroupNameData[j].lang_label,
              type: sameGroupNameData[j].type,
              mandatory: sameGroupNameData[j].mandatory,
              groupName: sameGroupNameData[j].groupName,
              propgateValueToSubFormFields:
                sameGroupNameData[j].propgateValueToSubFormFields,
              reportAllowedValues: sameGroupNameData[j].reportAllowedValues,
              addMore: sameGroupNameData[j].addMore,
              multiselect: sameGroupNameData[j].multiselect,
              mask: sameGroupNameData[j].mask,
              options: sameGroupNameData[j].options,
              dependentOptions: sameGroupNameData[j]?.dependentOptions,
              id: id,
              parent: 0,
              value: '',
              showRemove: showRemove,
              extra: true,
              uniqueId: uniqueId,
            };
            aobj.value = sameGroupNameData[j].addMore
              ? ''
              : sameGroupNameData[j].value;

            this.fieldsList1.push(aobj);

            this.fieldCount = addFielCount + 1;
          }
        }
      }
    } else if (formType === 'subForm') {
      if (this.subfieldsList1) {
        var id = this.newGuid();
        const uniqueId = Math.floor(Math.random() * 100000000000).toString();
        var sameGroupNameData: any = [];
        for (var i = 0; i < this.subfieldsList1.length; i++) {
          var ifgroupName = this.subfieldsList1[i].groupName == data;
          if (ifgroupName) {
            var obj = {
              name: this.subfieldsList1[i].name,
              fieldName: this.subfieldsList1[i].fieldName,
              lang_label: this.subfieldsList1[i].lang_label,
              type: this.subfieldsList1[i].type,
              mandatory: this.subfieldsList1[i].mandatory,
              groupName: this.subfieldsList1[i].groupName,
              propgateValueToSubFormFields:
                this.subfieldsList1[i].propgateValueToSubFormFields,
              reportAllowedValues: this.subfieldsList1[i].reportAllowedValues,
              multiselect: this.subfieldsList1[i].multiselect,
              addMore: this.subfieldsList1[i].addMore,
              value: this.fieldsList1[i].value,
              mask: this.subfieldsList1[i].mask,
              options: this.subfieldsList1[i].options,
              dependentOptions: this.subfieldsList1[i]?.dependentOptions,
              id: this.subfieldsList1[i].id,
              extra: this.subfieldsList1[i].extra,
            };
            sameGroupNameData.push(obj);
          }
        }
        if (sameGroupNameData) {
          let showRemove = false;

          sameGroupNameData = sameGroupNameData.filter((data) => {
            return !data['extra'];
          });

          let addFielCount = sameGroupNameData.length - 1;

          for (var j = 0; j < sameGroupNameData.length; j++) {
            if (j == addFielCount) {
              showRemove = true;
            }
            var aobj = {
              name: sameGroupNameData[j].name,
              fieldName: sameGroupNameData[j].fieldName,
              lang_label: sameGroupNameData[j].lang_label,
              type: sameGroupNameData[j].type,
              mandatory: sameGroupNameData[j].mandatory,
              groupName: sameGroupNameData[j].groupName,
              propgateValueToSubFormFields:
                sameGroupNameData[j].propgateValueToSubFormFields,
              reportAllowedValues: sameGroupNameData[j].reportAllowedValues,
              addMore: sameGroupNameData[j].addMore,
              multiselect: sameGroupNameData[j].multiselect,
              mask: sameGroupNameData[j].mask,
              options: sameGroupNameData[j].options,
              dependentOptions: sameGroupNameData[j]?.dependentOptions,
              id: id,
              parent: 0,
              value: '',
              showRemove: showRemove,
              extra: true,
              uniqueId: uniqueId,
            };
            aobj.value = sameGroupNameData[j].addMore
              ? ''
              : sameGroupNameData[j].value;
            this.subfieldsList1.push(aobj);

            this.fieldCount = addFielCount + 1;
          }
        }
      }
    }
  }

  removeDetails(key) {
    this.fieldsList1.forEach((value, index) => {
      if (value.id == key) {
        this.fieldsList1.splice(index, this.fieldCount);
      }
    });
  }

  removeDetailsSubList(key) {
    this.subfieldsList1.forEach((value, index) => {
      if (value.id == key) {
        this.subfieldsList1.splice(index, this.fieldCount);
      }
    });
  }

  singleSelectChange(e, registerForm) {
    if (registerForm.dependentFields && registerForm.dependentFields !== '') {
      const childDropdowns = this.fieldsList1.filter((list) => {
        return registerForm.dependentFields.split(',').includes(list.name);
      });
      childDropdowns.forEach((dd) => {
        dd.options = dd.dependentOptions ? dd.dependentOptions[e.code] : [];
        dd.value = '';
      });
    }
    registerForm.value = e?.code ? e.code : '';
  }

  formatPostValues(form, subFormGroupName = '') {
    let key = '';
    let vValue: any;
    let jobFilesList = [];
    let listObj = {};

    form.forEach((element) => {
      key = element['name'];
      let grpName = subFormGroupName ? subFormGroupName : element.groupName;

      vValue = element.value || '';

      if (
        vValue &&
        vValue.length != 0 &&
        element.type === 'Selection' &&
        element.multiselect === 'true'
      ) {
        vValue = vValue.join('%23');
      }

      if (element.type === 'Attachment') {
        if (element[element.name + '_URL']) {
          const val =
            element[element.name + '_Name'] +
            '~' +
            element[element.name + '_URL'] +
            '`' +
            element.uniqueId;
          if (this.addMoreGroupMap[grpName]) {
            listObj[key] = listObj[key] ? [...listObj[key], val] : [val];
          } else {
            listObj[key] = val;
          }
        } else if (vValue) {
          jobFilesList.push(vValue);
          const val = vValue.name + '`' + element.uniqueId;
          if (this.addMoreGroupMap[grpName]) {
            listObj[key] = listObj[key] ? [...listObj[key], val] : [val];
          } else {
            listObj[key] = val;
          }
        } else {
          if (this.addMoreGroupMap[grpName]) {
            listObj[key] = listObj[key] ? [...listObj[key], ''] : [''];
          } else {
            listObj[key] = '';
          }
        }
      } else if (
        element['type'] === 'Date' ||
        element['type'] === 'Date Time' ||
        element['type'] === 'Auto Date' ||
        element['type'] === 'Auto Date Time'
      ) {
        if (vValue) {
          const val = this.dateFormat(vValue) + '`' + element.uniqueId;
          if (this.addMoreGroupMap[grpName]) {
            listObj[key] = listObj[key] ? [...listObj[key], val] : [val];
          } else {
            listObj[key] = val;
          }
        } else {
          if (this.addMoreGroupMap[grpName]) {
            listObj[key] = listObj[key] ? [...listObj[key], ''] : [''];
          } else {
            listObj[key] = '';
          }
        }
      } else {
        if (vValue) {
          const val = vValue + '`' + element.uniqueId;
          if (this.addMoreGroupMap[grpName]) {
            listObj[key] = listObj[key] ? [...listObj[key], val] : [val];
          } else {
            listObj[key] = val;
          }
        } else {
          if (this.addMoreGroupMap[grpName]) {
            listObj[key] = listObj[key] ? [...listObj[key], ''] : [''];
          } else {
            listObj[key] = '';
          }
        }
      }
    });
    return {
      jobFilesList: jobFilesList,
      listObj: listObj,
    };
  }

  isFromValid(form) {
    let isValid = true;
    form.forEach(element => {
      if(element.mandatory == 'true' && !element.value){
        element.isvalid == false;
        isValid = false
      }
    });
    return isValid;
  }

  saveOrUpdateMainForm(mainForm) {
    let list = [];
    let jobFilesList = [];
    let listObj = {};
    this.formSubmit = true;
    if(!this.isFromValid(mainForm)) {
      return false;
    }
    this.formSubmit = false;
    const formatted = this.formatPostValues(mainForm);
    listObj = formatted.listObj;
    jobFilesList = formatted.jobFilesList;

    Object.keys(listObj).forEach((key) => {
      listObj[key] = Array.isArray(listObj[key])
        ? listObj[key].join('|')
        : listObj[key];
    });

    if (this.itemCode) {
      listObj['ItemCode'] = this.itemCode;
      list.push(listObj);

      var inputData = {
        userId: this.userId,
        clientEmail: this.loginEmail,
        jobId: this.jobId,
        subFormList: JSON.stringify(list),
        subFormName: this.selectedClaim,
        files: jobFilesList,
      };
      this.disableSubmitButton = true;
      this.claimsService.loader.next(true);
      this.claimsService.saveMainJobPost(inputData).subscribe(
        (res) => {
          this.disableSubmitButton = false;
          var result = res['myHashMap'];
          if (result['status'] == 1) {
            alert(result['message']);
            this.claimsService.loader.next(false);
            this.router.navigate(['/ClaimModule/jobsList'], {
              fragment: this.selectedClaim,
            });
          } else {
            alert(res['message']);
            this.claimsService.loader.next(false);
          }
        },
        (error: any) => console.log(error)
      );
    } else {
      list.push(listObj);
      var input = {
        ItemCode: this.itemCode,
        userId: this.userId,
        clientEmail: this.loginEmail,
        subFormList: JSON.stringify(list),
        subFormName: this.selectedClaim,
        files: jobFilesList,
      };

      this.disableSubmitButton = true;
      this.claimsService.loader.next(true);
      this.claimsService.saveMainJobPost(input).subscribe(
        (res) => {
          this.disableSubmitButton = false;
          var result = res['myHashMap'];
          if (result['status'] == 1) {
            this.claimsService.loader.next(false);
            if (this.subJobTabs.length > 0) {
              this.subJobTabs.forEach((job) => {
                job.jobId = result.jobId;
              });
              this.currentTabIndex = 1;
              this.handleTabChange({ index: this.currentTabIndex });
            } else {
              alert(result['message']);
              this.router.navigate(['/ClaimModule/jobsList'], {
                fragment: this.selectedClaim,
              });
            }
          } else {
            alert(res['message']);
            this.claimsService.loader.next(false);
          }
        },
        (error: any) => console.log(error)
      );
    }
  }

  dateFormat(date) {
    var d = new Date(date);

    var datestring =
      d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();

    return datestring;
  }

  download(file) {
    window.open(
      file.URL,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  removeFile(file) {
    var inputData = {
      attachmentId: Number(file.Id),
    };

    this.claimsService.removeFileAttachments(inputData).subscribe(
      (result) => {
        if (result['status'] == 1) {
          alert('Email sent successfully');
          this.emailsList = [];
        } else {
          alert('Could not delete document. Please try later...');
        }
      },
      (error: any) => console.log(error)
    );
  }

  addFiles() {
    this.uploadFiles.push({
      documentType: '',
      documentName: '',
      documentDescription: '',
      files: [],
    });
  }

  downloadAttachment(url) {
    window.open(url, '_blank');
  }

  getFileList(type, isUpload) {
    if (type === 'field' && isUpload === 'download') {
      return this.allAttachments?.filter((att) => {
        return att.groupName && att.name;
      });
    }
    if (type === 'field' && isUpload === 'browse') {
      return this.allAttachments?.filter((att) => {
        return att.groupName && !att.name;
      });
    }
    if (type === 'claim' && isUpload === 'download') {
      return this.allAttachments?.filter((att) => {
        return att?.Image_Name;
      });
    }
  }

  sendEmail() {
    var emailString = '';

    if (this.emailsList.length != 0) {
      for (var e = 0; e < this.emailsList.length; e++) {
        if (emailString) {
          emailString = emailString + ',' + this.emailsList[e].value;
        } else {
          emailString = this.emailsList[e].value;
        }
      }
    } else {
      alert('please select email');
      return;
    }

    var inputData = {
      jobId: this.jobId,
      emailTemplateId: this.emailTemplateInfo.emailTemplateId,
      userId: this.userId,
      recipientEmail: emailString,
    };

    this.claimsService.sendEmail(inputData).subscribe(
      (res) => {
        var result = res['myHashMap'];
        if (result['status'] == 1) {
          alert('Email sent successfully');
          this.emailsList = [];
        } else {
          alert('Email is not sent. Please try later...');
        }
      },
      (error: any) => console.log(error)
    );
  }

  getColorCodeClass(group) {
    const groupFields = this.fieldsList1.filter((field) => {
      return group === field.groupName;
    });
    let atleastOneFieldSelected = false;
    let allFieldsSelected = true;
    groupFields.forEach((field) => {
      atleastOneFieldSelected =
        atleastOneFieldSelected || (field.value ? true : false);
      allFieldsSelected = allFieldsSelected && (field.value ? true : false);
    });
    return allFieldsSelected
      ? ''
      : atleastOneFieldSelected
      ? 'partial-filled'
      : 'none-filled';
  }

  getHeaderForSubjob(form) {
    // return form.split(':-')[0] + ":    Added By - " + this.createdByFullName + ":     on - " + this.dateFormat(this.modifiedOn);
    return form.split(':-')[0].toUpperCase();
  }

  duplicateSubformGroup(subFormFieldsInMainForm, grpName) {
    this.subFormFieldsInMainForm = this.duplicateGroupFields(
      subFormFieldsInMainForm,
      grpName,
      'subFormName'
    );
  }
  duplicateTabbedSubJobFields(index) {
    this.subJobTabs[index].fields = this.duplicateGroupFields(
      this.subJobTabs[index].fields,
      this.subJobTabs[index].fieldName,
      'groupName'
    );
  }
  duplicateJobActionSubJobFields(index) {
    this.jobActionServiceSubjobs[index].Fields = this.duplicateGroupFields(
      this.jobActionServiceSubjobs[index].Fields,
      this.jobActionServiceSubjobs[index].name,
      'groupName'
    );
  }
  duplicateMainJobActionSubJobFields() {
    this.jobActionService.Fields = this.duplicateGroupFields(
      this.jobActionService.Fields,
      this.jobActionService.fieldName,
      'groupName'
    );
  }
  duplicateGroupFields(fields, grpName, grpNameKey) {
    const uniqueId = Math.floor(Math.random() * 100000000000).toString();
    const duplicateGroup = fields
      .filter((form) => {
        return form[grpNameKey] === grpName && !form['extra'];
      })
      .map((form) => {
        let mapform = { ...form };
        mapform['extra'] = true;
        mapform['uniqueId'] = uniqueId;
        if (mapform.type === 'Attachment' && mapform[mapform.name + '_URL']) {
          delete mapform[mapform.name + '_URL'];
          delete mapform[mapform.name + '_Name'];
          delete mapform['fileName'];
        }
        mapform.value = mapform.addMore ? '' : mapform.value;
        return mapform;
      });
    return [...fields, ...duplicateGroup];
  }
  getEmailsFromFields(fieldList) {
    const emails = [];
    fieldList.forEach((field) => {
      if (field.type === 'Email' && field.value) {
        let emailsList = [...this.emailsFieldsList, ...emails];
        if (!emailsList.map((email) => email.value).includes(field.value)) {
          emails.push({
            value: field.value,
            label: field.value,
          });
        }
      }
    });
    return emails;
  }

  removeUnderScore(str) {
    return str ? str.replace(/_/g, ' ') : '';
  }

  handleTabChange(job) {
    // exclude first tab it contans main job
    const tabData = job?.index > 0 ? this.subJobTabs[job.index - 1] : null;
    if (tabData) {
      var input = {
        label: tabData.name,
      };
      if (!this.subJobTabs[job.index - 1].fields) {
        this.claimsService.getAllMasterTypeJobsList(input).subscribe(
          (res) => {
            const allFields = res['orgs'][0].Fields;
            this.subJobTabs[job.index - 1].fields =
              this.getFormattedSubjobOnTabsFields(
                allFields,
                this.subJobTabs[job.index - 1].groupName
              );
            this.updateAddMoreMap(this.subJobTabs[job.index - 1].fields);
          },
          (error) => console.log(error)
        );
      }
    }
  }

  handleServiceTabChange(tab) {
    // exclude first tab it contans main job
    const tabData =
      tab?.index > 0 ? this.jobActionServiceSubjobs[tab.index - 1] : null;
    if (tabData) {
      var input = {
        label: tabData.name,
      };
      // if(!this.jobActionServices[tab.index - 1].fields) {
      this.claimsService.getAllMasterTypeJobsList(input).subscribe(
        (res) => {
          const allFields = res['orgs'][0].Fields;
          this.jobActionServiceSubjobs[tab.index - 1].Fields =
            this.getFormattedSubjobOnTabsFields(
              allFields,
              this.jobActionServiceSubjobs[tab.index - 1].name
            );
          this.updateAddMoreMap(
            this.jobActionServiceSubjobs[tab.index - 1].Fields
          );
        },
        (error) => console.log(error)
      );
      // }
    }
  }

  updateJobAction(selectedJobAction) {
    var input = {
      label: selectedJobAction.val,
    };
    this.jobActionServiceSubjobs = [];
    this.claimsService.getAllMasterTypeJobsList(input).subscribe(
      (res) => {
        const jobActionService = res['orgs'][0];
        this.jobActionService = jobActionService;
        jobActionService.Fields.forEach((field) => {
          if (!field.groupName) {
            field.groupName = jobActionService.type;
          }
          if (field.type === 'SubJob') {
            this.jobActionServiceSubjobs.push({
              name: field.name,
            });
          }
        });
        this.jobActionService.Fields = jobActionService.Fields.filter(
          (service) => {
            return service.type !== 'SubJob';
          }
        );
        this.jobActionService.Fields = this.getFormattedSubjobOnTabsFields(
          this.jobActionService.Fields,
          jobActionService.groupName
        );
        this.updateAddMoreMap(this.jobActionService.Fields);
      },
      (error) => console.log(error)
    );
  }

  getFormattedSubjobOnTabsFields(allFields, groupName) {
    let formatedFields = [];
    allFields = this.sortByOrderId(allFields);
    allFields = this.assignUniqueId(allFields);
    allFields.forEach((field, index) => {
      const ifContainsSlash = field?.allowedValues?.includes('|');
      const ifContainsQColon = field?.allowedValues?.includes('q:');
      let allowedValuesData;
      if (ifContainsSlash) {
        allowedValuesData = field?.allowedValues?.split('|').map((o) => {
          return {
            value: o.replace(this.matchTextBetweenBrackets, ''),
            code: o,
          };
        });
      } else if (ifContainsQColon) {
        allowedValuesData = Object.keys(field.allowedValuesResults).map((k) => {
          return {
            value: k.replace(this.matchTextBetweenBrackets, ''),
            code: field.allowedValuesResults[k],
          };
        });
      }

      let obj = {
        name: field.name,
        fieldName: field.name.replace(/_/g, ' '),
        lang_label: field.lang_label,
        type: field.type,
        mandatory: field.mandatory,
        groupName: field.groupName ? field.groupName : groupName,
        propgateValueToSubFormFields: field.propgateValueToSubFormFields,
        reportAllowedValues: field.reportAllowedValues,
        multiselect: field.multiselect,
        addMore: field.addMore ? true : false,
        mask: field.mask,
        options: allowedValuesData,
        dependentFields: field.dependentFields,
        value: '',
        id: index,
        parent: 1,
        uniqueId: field['uniqueId'],
      };
      formatedFields.push(obj);
    });
    return formatedFields;
  }

  saveTabbedSubjob(job) {
    this.subFormSave(job.fields, job.groupName, job.jobId);
  }

  saveServiceTabbedSubjob(fields, grp, jobId) {
    // console.log('tab12312312', tab);
    this.serviceTabForm = true;
    this.subFormSave(fields, grp, jobId);
  }

  getRegisterFormAttachemntName(registerForm) {
    return registerForm[registerForm.name + '_Name'];
  }

  getRegisterFormAttachemntURL(registerForm) {
    return registerForm[registerForm.name + '_URL'];
  }

  getJobSaveButtonText(tabs) {
    const isTabs = tabs ? tabs.length > 0 : this.subJobTabs.length > 0;
    return isTabs ? 'Save & Next' : 'Save';
  }

  getSubJobTabSaveButtonText(tabs) {
    const isTabs = tabs
      ? tabs.length > this.currentChildTabIndex
      : this.subJobTabs.length > this.currentTabIndex;
    return isTabs ? 'Save & Next' : 'Save';
  }

  getServiceTabSaveButtonText() {
    return this.jobActionServiceSubjobs.length > 0 ? 'Save & Next' : 'Save';
  }

  inputchangeEvent(inputField, fieldList) {
    this.inputchangeEventInGroup(inputField,this.fieldsList1);
    this.inputchangeEventInGroup(inputField,this.subfieldsList1);
    this.inputchangeEventInGroup(inputField,this.subFormFieldsInMainForm);
    if (this.jobActionService && this.jobActionService.Fields) {
      this.inputchangeEventInGroup(inputField,this.jobActionService.Fields);
    }
    this.jobActionServiceSubjobs.forEach(tab => {
      this.inputchangeEventInGroup(inputField, tab.Fields);
    });
    this.subJobTabs.forEach(tab => {
      this.inputchangeEventInGroup(inputField, tab.Fields);
    });
  }

  inputchangeEventInGroup(inputField, fieldList) {
    const propagateFieldList = inputField.propgateValueToSubFormFields?.split('|');
    const re = /\d+(\+|\-|\*|\/)\d+/;
    if(propagateFieldList) {
      fieldList.forEach(field => {
        if(propagateFieldList.includes(field.lang_label)) {
          switch(field?.reportAllowedValues?.queryType) {
            case 'CalcText':
              const expression = field?.reportAllowedValues?.query?.replace(/LBL_JOB_TYPE_[0-9]{1,4}_[A-Z_]+/ig, (match) => {
                return this.getFieldValueById(match, fieldList);
              });
              if(re.test(expression)) {
                field.value = eval(expression);
              } else {
                field.value = 0;
              }
              break;
            case 'CalcDate':
              const dateExpression = field?.reportAllowedValues?.query?.replace(/LBL_JOB_TYPE_[0-9]{1,4}_[A-Z_]+/ig, (match) => {
                let date = this.getFieldValueById(match, fieldList);
                let fullDaysSinceEpoch = Math.floor(new Date(date).getTime()/8.64e7);
                return date ? fullDaysSinceEpoch : null;
              });
              if(re.test(dateExpression)) {
                const val = eval(dateExpression);
                field.value = new Date(val*8.64e7);
              }
              break;
            default:
                break;

          }
        }
      });
    }
  }

  getminDateValue(inputField, fieldList) {
    let defaultDate = new Date();
    defaultDate.setFullYear(defaultDate.getFullYear() - 10);
    if(inputField?.reportAllowedValues?.queryType && inputField?.reportAllowedValues?.queryType === 'DateAfter') {
      const dateAfter = this.getFieldValueById(inputField?.reportAllowedValues?.query, fieldList);
      return dateAfter instanceof Date ? dateAfter : defaultDate;
    }
    return defaultDate;
  }
  getmaxDateValue(inputField, fieldList) {
    let defaultDate = new Date();
    defaultDate.setFullYear(defaultDate.getFullYear() + 10);
    if(inputField?.reportAllowedValues?.queryType && inputField?.reportAllowedValues?.queryType === 'DateBefore') {
      const dateAfter = this.getFieldValueById(inputField?.reportAllowedValues?.query, fieldList);
      return dateAfter instanceof Date ? dateAfter : defaultDate;
    }
    return defaultDate;
  }

  getFieldValueById(id, fieldList) {
    const field = fieldList.filter((f) => {
      return f.lang_label === id;
    });
    return field && field[0] ? field[0].value : '';
  }
}
