<p-card *ngIf="!isSinglejobView">
    <div class="p-grid">
        <p-header class="p-col-12 p-pt-0 p-pb-0 header-title">
            <!-- CLAIM - REGISTER CLAIM -->
            {{claimsTitle}}
        </p-header>
        <p-header class="p-col-12 p-pt-0 p-pb-0 subheading">
            <!-- Register a new claim. -->
            {{claimsSubTitle}}
        </p-header>
    </div>
    <div class="p-col-12" style="position:relative;">
        <button pButton type="button" label="Download Sample xls" class="btnCss downloadBtn downloadposs p-mt-4">
        </button>
        <p-accordion>
            <p-accordionTab class="dataFilter" header="{{activeState?'Bulk Upload':'Bulk Upload'}}"
                [(selected)]="activeState">
                <div class="p-col-12 p-p-0">
                    <div class="cardSection">
                        <p-card>
                            <div class="p-col-12 p-pl-0">
                                <div class="tableSubheader">
                                    Upload claim data
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="dynamicCtrlWrap">
                                    <div class=" p-col-12 p-md-3">
                                        <label for="firstname">Document type</label>

                                        <ng-select [items]="itemsList" bindLabel="label" bindValue="value"
                                            [multiple]="true" [closeOnSelect]="true" [searchable]="true"
                                            placeholder="Select Work Order Steps">
                                        </ng-select>
                                    </div>
                                    <div class="p-col-12 p-md-3">
                                        <label for="lastname">Document Name</label>
                                        <input id="fieldId" type="text" pInputText>
                                    </div>
                                    <div class="p-col-12 p-md-3 center">
                                        <label for="firstname">Browse file</label>
                                        <ngx-file-drop class="claimdragdrop" dropZoneLabel="Drop files here"
                                            multiple="true">
                                            <ng-template ngx-file-drop-content-tmp
                                                let-openFileSelector="openFileSelector" multiple="true">
                                                <div
                                                    style="width:100%;height:24px;flex:3;text-align: center;margin-top:1px;color: #646565;">

                                                </div>
                                                <div
                                                    style="width:100%;height:auto;flex:1;text-align: center;margin-top:0px;">
                                                    <button type="button">Browse Files</button>
                                                </div>
                                            </ng-template>
                                        </ngx-file-drop>

                                    </div>

                                    <div class="p-col-12  p-md-3">
                                        <label for="lastname">Description</label>
                                        <input id="fieldId" type="text" pInputText>
                                    </div>
                                </div>

                                <div class="removeWrapperDiv diagnosisRemove">
                                    <i class="pi pi-times closeIcon_marginT"></i>
                                </div>
                            </div>

                            <div class="p-col-12 p-pl-0">
                                <button pButton type="button" class="btnCss finishBtn" label="Submit"></button>
                            </div>
                        </p-card>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>

    </div>
</p-card>
<p-card>
    <div class="p-grid">
        <p-header class="p-col-12 p-pt-0 p-pb-0 header-title">
            {{selectedClaim}} - <b>{{claimNo}}</b> - FULL VIEW
        </p-header>
    </div>

    <p-tabView [(activeIndex)]="currentTabIndex" class="tabsytle" (onChange)="handleTabChange($event)">
        <p-tabPanel header="{{selectedClaim}} Data">


            <div class="p-col-12 p-p-0 p-pb-0">

                <!-- <div class="p-grid" *ngIf="isEditMode">
                    <div class="p-col-12">
                        <p-card class="claimWorkflow">
                            <div class="tableheader">
                                CLAIM WORKFLOW
                            </div>
                            <div class="p-grid">
                                <div class="p-col-12 p-pl-0">
                                    <ol class="c-progress-steps">
                                        <ng-container *ngFor="let itemState of items">
                                            <li class="c-progress-steps__step"
                                                [ngClass]="{'done': itemState.status === 0, 'current' : itemState.status === 1, 'next' : itemState.status === 2 }">
                                                {{itemState.label}}</li>
                                        </ng-container>
                                    </ol>
                                </div>
                                <div class="p-col-12 p-pl-0">
                                    <p-accordion>
                                        <p-accordionTab class="activityHistory" header="Activity History"
                                            [(selected)]="activeHistory">
                                            <div class="p-col-12" style="position:relative;">
                                                <div class="histort-timeline p-mt-4">
                                                    <ul class="activeTimeline">
                                                        <ng-container *ngFor="let job of jobComments">
                                                            <li>
                                                                <p class="timeline-date"> {{job.comment}}</p>
                                                                <div class="histort-content">
                                                                    <div class="track-info">
                                                                        <h5> {{job.comment}}</h5>
                                                                        <p class="pClass"> {{job.time}}<br />
                                                                            {{job.postedBy_Name}}<br />
                                                                        </p>
                                                                        <button pButton type="button" label="Send Email"
                                                                            class="btnCss downloadBtn rejectedFont p-mr-2">
                                                                        </button>
                                                                    </div>

                                                                </div>

                                                            </li>
                                                        </ng-container>
                                                    </ul>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                    </p-accordion>
                                </div>
                            </div>
                        </p-card>
                    </div>
                </div> -->

                <p-card class="claimWorkflow" *ngIf="isEditMode">

                    <div class="tableheader">
                        CLAIM WORKFLOW
                    </div>
                    <div class="p-d-flex p-flex-row">
                        <div class="wizard small row">
                            <ng-container *ngFor="let itemState of items">
                                <a *ngIf="itemState.status !== 0"
                                    [ngClass]="{'done': itemState.status === 0, 'current' : itemState.status === 1, 'next' : itemState.status === 2 }">
                                    {{itemState.label}}
                                </a>
                            </ng-container>
                        </div>
                    </div>
                    <!-- <div class="p-d-flex p-flex-row">
                            <div class="statusUpdateHeader p-col-12">
                                Current Status
                            </div>
                            <div class="claims_btns btn-misc p-col-12" style="align-self: center;">
                                {{currentJobStatus}}
                            </div>
                        </div> -->
                    <div class="historyRow">
                        <div class="p-col col-lg-6 col-md-12 col-sm-12 p-p-0">
                            <div class="statusRow" *ngIf="nextJobStatus && nextJobStatus.length!=0">
                                <div class="statusLbl">
                                    <label> Change Status</label>
                                </div>
                                <div class="statusUpdateDdl dropselection"
                                    *ngIf="nextJobStatus && nextJobStatus.length!=0">
                                    <p-dropdown [options]="nextJobStatus" placeholder="Select Next Status Update"
                                        [(ngModel)]="jobStatus" (onChange)="updateJobStatus(jobStatus, 1)"
                                        optionValue="Status_Id" optionLabel="Status_Name">
                                    </p-dropdown>
                                </div>
                            </div>
                        </div>
                        <!-- ACTIVITY HISTORY -->
                        <div class="p-col col-lg-6 col-md-12 col-sm-12 p-p-0" style="position: relative;">
                            <p-accordion>
                                <p-accordionTab class="activityHistory" header="Activity History"
                                    [(selected)]="activeHistory">
                                    <div class="p-col-12" style="position:relative;margin-top: 40px;">
                                        <div class="histort-timeline p-mt-4">
                                            <ul class="activeTimeline">
                                                <ng-container *ngFor="let job of jobComments">
                                                    <li>
                                                        <p class="timeline-date"> {{job.comment}}</p>
                                                        <div class="histort-content">
                                                            <div class="track-info">
                                                                <h5> {{job.comment}}</h5>
                                                                <p class="pClass"> {{job.time}}<br />
                                                                    {{job.postedBy_Name}}<br />
                                                                </p>
                                                                <!-- <button pButton type="button" label="Send Email"
                                                                    class="btnCss downloadBtn rejectedFont p-mr-2">
                                                                </button>-->
                                                            </div>

                                                        </div>

                                                    </li>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                        </div>
                    </div>

                    <div class="p-grid" *ngIf="isEditMode && subfieldsList1.length!=0">
                        <div class="p-col-12">
                            <p-card class="statusEmailCard">

                                <div class="p-grid">
                                    <div class="p-col-12">

                                        <div class="p-grid p-p-0 p-mt-0" *ngIf="subfieldsList1.length!=0">
                                            <div class="cardWrapper" style="margin-bottom:0px;"
                                                *ngFor="let groupForm of subFormGroupNames">
                                                <div class="p-col-12" style="width:100%;border:1px solid #ddd;">
                                                    <div class="tableheader borderB">
                                                        {{groupForm}}
                                                        <span *ngIf="groupForm === ''">Other Details</span>
                                                    </div>
                                                    <div class="p-col-12 r_fontsize ctrlSection">
                                                        <div class="p-grid p-ml-0 p-mr-0">
                                                            <ng-container *ngFor="let registerForm of subfieldsList1">
                                                                <div class="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12 p-d-flex p-flex-column p-flex-sm-row borderB p-p-2"
                                                                    *ngIf='registerForm.groupName === groupForm && registerForm.parent ===1'>

                                                                    <div class="p-col-12 p-md-4 p-p-0">
                                                                        <label>{{registerForm.fieldName}} <span *ngIf="registerForm.mandatory == 'true'" style="color: red;">*</span></label>
                                                                    </div>
                                                                    <div class="p-col-12 p-md-8 p-p-0">
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Small Text';else notText">
                                                                            <input type="text"
                                                                                [(ngModel)]="registerForm.value"
                                                                                (ngModelChange)="inputchangeEvent(registerForm, subfieldsList1)"
                                                                                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}"
                                                                                pInputText>
                                                                        </ng-container>
                                                                        <ng-template #notText>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'ReadOnly Small Text';else notReadonly">
                                                                                <input type="text"
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    [readonly]="true" pInputText>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notReadonly>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Large Text';else notLargetext">

                                                                                <p-editor
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    [style]="{'height':'320px'}">
                                                                                </p-editor>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notLargetext>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Date Time';else notDatetime">
                                                                                <p-calendar
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    showTime="true" hourFormat="24"
                                                                                    appendTo="body">
                                                                                </p-calendar>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notDatetime>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Date'; else notDate">
                                                                                <p-calendar [showIcon]=" true"
                                                                                    appendTo="body"
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    [monthNavigator]="true"
                                                                                    [yearNavigator]="true"
                                                                                    dateFormat="dd/mm/yy"
                                                                                    inputId="navigators"
                                                                                    yearRange="1990:2050"
                                                                                    [minDate]="getminDateValue(registerForm,subfieldsList1)" 
                                                                                    [maxDate]="getmaxDateValue(registerForm,subfieldsList1)"
                                                                                    (onSelect)="inputchangeEvent(registerForm, subfieldsList1)"
                                                                                    (onInput)="inputchangeEvent(registerForm, subfieldsList1)"
                                                                                    [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                                </p-calendar>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notDate>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Selection';else notSelect">
                                                                                <div
                                                                                    *ngIf="registerForm.multiselect === 'false'">
                                                                                    <ng-select
                                                                                        [items]="registerForm.options"
                                                                                        bindLabel="value"
                                                                                        [(ngModel)]="registerForm.value"
                                                                                        bindValue="code"
                                                                                        [multiple]="false"
                                                                                        [closeOnSelect]="true"
                                                                                        [searchable]="true"
                                                                                        (change)="singleSelectChange($event, registerForm)">
                                                                                    </ng-select>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="registerForm.multiselect === 'true'">
                                                                                    <ng-select
                                                                                        [items]="registerForm.options"
                                                                                        bindLabel="value"
                                                                                        [(ngModel)]="registerForm.value"
                                                                                        bindValue="code"
                                                                                        [multiple]="true"
                                                                                        [closeOnSelect]="false"
                                                                                        [searchable]="true">
                                                                                    </ng-select>
                                                                                </div>

                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notSelect>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Large Selection';else notLargeSelection">
                                                                                <ng-select [items]="itemsList"
                                                                                    bindLabel="value"
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    bindValue="code" [multiple]="true"
                                                                                    [closeOnSelect]="true"
                                                                                    [searchable]="true">
                                                                                </ng-select>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notLargeSelection>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Small Heading';else notSmallHeading">
                                                                                <h2>Test Small Heading</h2>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notSmallHeading>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Large Heading';else notLargeHeading">
                                                                                <h1>Test Large Heading</h1>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notLargeHeading>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Radio OR CheckBox';else notRadio">
                                                                                <p-checkbox name="groupname"
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    value="val1">
                                                                                </p-checkbox>

                                                                                <p-radioButton name="groupname"
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    value="val2">
                                                                                </p-radioButton>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notRadio>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Attachment';else notAttachment">
                                                                                <ngx-file-drop class="claimdragdrop"
                                                                                    dropZoneLabel="Drop files here"
                                                                                    (onFileDrop)="dropped($event,registerForm)"
                                                                                    (onFileOver)="fileOver($event)"
                                                                                    (onFileLeave)="fileLeave($event)"
                                                                                    multiple="false">
                                                                                    <ng-template
                                                                                        ngx-file-drop-content-tmp
                                                                                        let-openFileSelector="openFileSelector"
                                                                                        multiple="true">
                                                                                        <div
                                                                                            style="width:100%;height:24px;flex:1;text-align: center;margin-top:1px;color: #646565;">
                                                                                            Drag & Drop Here</div>
                                                                                        <div
                                                                                            style="width:100%;height:auto;flex:1;text-align: center;">
                                                                                            <button type="button"
                                                                                                (click)="openFileSelector()">Browse</button>
                                                                                        </div>
                                                                                    </ng-template>

                                                                                </ngx-file-drop>

                                                                                <tr *ngIf="registerForm[registerForm.name +'_Name']"
                                                                                    style="cursor: pointer;">
                                                                                    <td><a
                                                                                            (click)="downloadAttachment(registerForm[registerForm.name +'_URL'])">
                                                                                            {{registerForm[registerForm.name
                                                                                            +'_Name']}}
                                                                                        </a>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr *ngIf="registerForm.fileName">
                                                                                    <td><strong>{{registerForm.fileName}}</strong>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notAttachment>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Hidden Text Field';else notHiddentext">
                                                                                <input type="text"
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    type="hidden" pInputText>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notHiddentext>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Auto Date Time';else notAutoDatetime">

                                                                                <p-calendar
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    [showTime]="true" appendTo="body"
                                                                                    inputId="time">
                                                                                </p-calendar>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notAutoDatetime>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Auto Date';else notAutoTime">
                                                                                <p-calendar [showIcon]=" true"
                                                                                    [monthNavigator]="true"
                                                                                    appendTo="body"
                                                                                    [yearNavigator]="true"
                                                                                    dateFormat="dd/mm/yy"
                                                                                    inputId="navigators"
                                                                                    yearRange="1990:2050"
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    [minDate]="getminDateValue(registerForm,subfieldsList1)" 
                                                                                    [maxDate]="getmaxDateValue(registerForm,subfieldsList1)"
                                                                                    (onSelect)="inputchangeEvent(registerForm, subfieldsList1)"
                                                                                    (onInput)="inputchangeEvent(registerForm, subfieldsList1)"
                                                                                    [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                                </p-calendar>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notAutoTime>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Time';else notTime">
                                                                                <p-calendar
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    appendTo="body" [timeOnly]="true"
                                                                                    inputId="timeonly"></p-calendar>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notTime>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'MulltiMedia';else notMultimedia">
                                                                                <input type="text" pInputText
                                                                                    [(ngModel)]="registerForm.value">
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notMultimedia>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Geo Location';else notGeoLocation">
                                                                                <input ngx-google-places-autocomplete
                                                                                    #places="ngx-places"
                                                                                    (onAddressChange)="handleAddressChange($event, registerForm)"
                                                                                    [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}" />

                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notGeoLocation>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Numeric Text';else notNumericText">
                                                                                <input pInputText type="text"
                                                                                    pKeyFilter="pnum"
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    (ngModelChange)="inputchangeEvent(registerForm, subfieldsList1)"
                                                                                    [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}" />
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notNumericText>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Dates after today';else notDatesAfterToday">
                                                                                <p-calendar [showIcon]=" true"
                                                                                    [monthNavigator]="true"
                                                                                    appendTo="body"
                                                                                    [yearNavigator]="true"
                                                                                    dateFormat="dd/mm/yy"
                                                                                    [minDate]="tomorrow"
                                                                                    inputId="navigators"
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    yearRange="1990:2050">
                                                                                </p-calendar>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notDatesAfterToday>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'SobJob';else notSubJob">
                                                                                <input type="text" pInputText
                                                                                    [(ngModel)]="registerForm.value">
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notSubJob>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Email';else notEmail">
                                                                                <input pInputText type="email"
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    pattern="^[a-z0-9._-]+@[a-z]+\.[a-z]{2,4}$"
                                                                                    required [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}"/>
                                                                            </ng-container>
                                                                        </ng-template>

                                                                    </div>

                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                        <div>
                                                            <div class="p-grid">
                                                                <ng-container
                                                                    *ngFor="let registerForm of subfieldsList1; let i = index">
                                                                    <ng-container
                                                                        *ngIf='registerForm.groupName === groupForm && registerForm.parent ===0'>
                                                                        <div
                                                                            class="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12 p-d-flex p-flex-column p-flex-sm-row borderB p-p-2">
                                                                            <div class="p-col-12 p-md-4 p-p-0">
                                                                                <label>{{registerForm.fieldName}} <span *ngIf="registerForm.mandatory == 'true'" style="color: red;">*</span></label>
                                                                            </div>
                                                                            <div class="p-col-12 p-md-8 p-p-0">
                                                                                <ng-container
                                                                                    *ngIf="registerForm.type === 'Small Text';else notText">
                                                                                    <input type="text" pInputText
                                                                                        (ngModelChange)="inputchangeEvent(registerForm, subfieldsList1)"
                                                                                        [(ngModel)]="registerForm.value"
                                                                                        [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                                </ng-container>
                                                                                <ng-template #notText>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'ReadOnly Small Text';else notReadonly">
                                                                                        <input type="text"
                                                                                            [readonly]="true" pInputText
                                                                                            [(ngModel)]="registerForm.value">
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notReadonly>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'Large Text';else notLargetext">
                                                                                        <p-editor
                                                                                            [(ngModel)]="registerForm.value"
                                                                                            [style]="{'height':'320px'}">
                                                                                        </p-editor>
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notLargetext>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'Date Time';else notDatetime">
                                                                                        <p-calendar showTime="true"
                                                                                            hourFormat="24"
                                                                                            appendTo="body"
                                                                                            [(ngModel)]="registerForm.value">
                                                                                        </p-calendar>
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notDatetime>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'Date'; else notDate">
                                                                                        <p-calendar [showIcon]=" true"
                                                                                            [monthNavigator]="true"
                                                                                            appendTo="body"
                                                                                            [yearNavigator]="true"
                                                                                            dateFormat="dd/mm/yy"
                                                                                            inputId="navigators"
                                                                                            yearRange="1990:2050"
                                                                                            [(ngModel)]="registerForm.value"
                                                                                            [minDate]="getminDateValue(registerForm,subfieldsList1)" 
                                                                                            [maxDate]="getmaxDateValue(registerForm,subfieldsList1)"
                                                                                            (onSelect)="inputchangeEvent(registerForm, subfieldsList1)"
                                                                                            (onInput)="inputchangeEvent(registerForm, subfieldsList1)"
                                                                                            [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                                        </p-calendar>
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notDate>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'Selection';else notSelect">
                                                                                        <div
                                                                                            *ngIf="registerForm.multiselect === 'false'">
                                                                                            <ng-select
                                                                                                [items]="registerForm.options"
                                                                                                bindLabel="value"
                                                                                                bindValue="code"
                                                                                                [multiple]="false"
                                                                                                [closeOnSelect]="true"
                                                                                                [searchable]="true"
                                                                                                (change)="singleSelectChange($event, registerForm)"
                                                                                                [(ngModel)]="registerForm.value"
                                                                                                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                                            </ng-select>
                                                                                        </div>
                                                                                        <div
                                                                                            *ngIf="registerForm.multiselect === 'true'">
                                                                                            <ng-select
                                                                                                [items]="registerForm.options"
                                                                                                bindLabel="value"
                                                                                                bindValue="code"
                                                                                                [multiple]="true"
                                                                                                [closeOnSelect]="false"
                                                                                                [searchable]="true"
                                                                                                [(ngModel)]="registerForm.value"
                                                                                                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                                            </ng-select>
                                                                                        </div>

                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notSelect>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'Large Selection';else notLargeSelection">
                                                                                        <ng-select [items]="itemsList"
                                                                                            bindLabel="value"
                                                                                            bindValue="code"
                                                                                            [multiple]="false"
                                                                                            [closeOnSelect]="true"
                                                                                            [searchable]="true"
                                                                                            [(ngModel)]="registerForm.value">
                                                                                        </ng-select>
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notLargeSelection>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'Small Heading';else notSmallHeading">
                                                                                        <h2>Test Small Heading</h2>
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notSmallHeading>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'Large Heading';else notLargeHeading">
                                                                                        <h1>Test Large Heading</h1>
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notLargeHeading>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'Radio OR CheckBox';else notRadio">
                                                                                        <p-checkbox name="groupname"
                                                                                            value="val1">
                                                                                        </p-checkbox>

                                                                                        <p-radioButton name="groupname"
                                                                                            value="val2">
                                                                                        </p-radioButton>
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notRadio>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'Attachment';else notAttachment">
                                                                                        <ngx-file-drop
                                                                                            class="claimdragdrop"
                                                                                            dropZoneLabel="Drop files here"
                                                                                            (onFileDrop)="dropped($event,registerForm)"
                                                                                            (onFileOver)="fileOver($event)"
                                                                                            (onFileLeave)="fileLeave($event)"
                                                                                            multiple="false">
                                                                                            <ng-template
                                                                                                ngx-file-drop-content-tmp
                                                                                                let-openFileSelector="openFileSelector"
                                                                                                multiple="true">
                                                                                                <div
                                                                                                    style="width:100%;height:24px;flex:1;text-align: center;margin-top:1px;color: #646565;">
                                                                                                    Drag & Drop Here
                                                                                                </div>
                                                                                                <div
                                                                                                    style="width:100%;height:auto;flex:1;text-align: center;">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        (click)="openFileSelector()">Browse</button>
                                                                                                </div>
                                                                                            </ng-template>

                                                                                        </ngx-file-drop>
                                                                                        <tr
                                                                                            *ngIf="registerForm.fileName">
                                                                                            <td><strong>{{registerForm.fileName}}</strong>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notAttachment>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'Hidden Text Field';else notHiddentext">
                                                                                        <input type="text" type="hidden"
                                                                                            pInputText>
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notHiddentext>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'Auto Date Time';else notAutoDatetime">

                                                                                        <p-calendar
                                                                                            [(ngModel)]="registerForm.value"
                                                                                            [showTime]="true"
                                                                                            appendTo="body"
                                                                                            inputId="time">
                                                                                        </p-calendar>
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notAutoDatetime>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'Auto Date';else notAutoTime">
                                                                                        <p-calendar [showIcon]=" true"
                                                                                            [monthNavigator]="true"
                                                                                            appendTo="body"
                                                                                            [yearNavigator]="true"
                                                                                            dateFormat="dd/mm/yy"
                                                                                            inputId="navigators"
                                                                                            yearRange="1990:2050"
                                                                                            [(ngModel)]="registerForm.value"
                                                                                            [minDate]="getminDateValue(registerForm,subfieldsList1)" 
                                                                                            [maxDate]="getmaxDateValue(registerForm,subfieldsList1)"
                                                                                            (onSelect)="inputchangeEvent(registerForm, subfieldsList1)"
                                                                                            (onInput)="inputchangeEvent(registerForm, subfieldsList1)"
                                                                                            [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                                        </p-calendar>
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notAutoTime>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'Time';else notTime">
                                                                                        <p-calendar [timeOnly]="true"
                                                                                            inputId="timeonly"
                                                                                            appendTo="body">
                                                                                        </p-calendar>
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notTime>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'MulltiMedia';else notMultimedia">
                                                                                        <input type="text" pInputText>
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notMultimedia>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'Geo Location';else notGeoLocation">
                                                                                        <input
                                                                                            ngx-google-places-autocomplete
                                                                                            #places="ngx-places"
                                                                                            (onAddressChange)="handleAddressChange($event,registerForm)" 
                                                                                            [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}"/>

                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notGeoLocation>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'Numeric Text';else notNumericText">
                                                                                        <input pInputText type="text"
                                                                                            pKeyFilter="pnum"
                                                                                            (ngModelChange)="inputchangeEvent(registerForm, subfieldsList1)"
                                                                                            [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}" />
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notNumericText>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'Dates after today';else notDatesAfterToday">
                                                                                        <p-calendar [showIcon]=" true"
                                                                                            [monthNavigator]="true"
                                                                                            appendTo="body"
                                                                                            [yearNavigator]="true"
                                                                                            dateFormat="dd/mm/yy"
                                                                                            [minDate]="tomorrow"
                                                                                            inputId="navigators"
                                                                                            yearRange="1990:2050">
                                                                                        </p-calendar>
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notDatesAfterToday>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'SobJob';else notSubJob">
                                                                                        <input type="text" pInputText>
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                                <ng-template #notSubJob>
                                                                                    <ng-container
                                                                                        *ngIf="registerForm.type === 'Email';else notEmail">
                                                                                        <input pInputText type="email"
                                                                                            [(ngModel)]="registerForm.value"
                                                                                            pattern="^[a-z0-9._-]+@[a-z]+\.[a-z]{2,4}$"
                                                                                            required [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}"/>
                                                                                    </ng-container>
                                                                                </ng-template>
                                                                            </div>
                                                                            <div class="p-col-12 p-md-2 p-p-0"
                                                                                *ngIf='registerForm.showRemove == true'>
                                                                                <button pButton pRipple type="button"
                                                                                    (click)="removeDetailsSubList(registerForm.id)"
                                                                                    icon="pi pi-times"
                                                                                    class="p-button-rounded p-button-danger p-button-text"></button>

                                                                            </div>
                                                                        </div>
                                                                        <div class="line-divider"
                                                                            *ngIf='registerForm.showRemove == true'
                                                                            style="margin-top: 25px; width: 100%;">
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-container>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div *ngIf="addMoreGroupMap[groupForm]"
                                                        class="p-col-12 p-d-flex p-jc-end">
                                                        <button pButton type="button" label="Add" icon="pi pi-plus"
                                                            (click)="AddingDetails(groupForm, 'subForm')"
                                                            class="btnCss cancelBtn p-mr-2"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="p-grid p-jc-end">
                                            <div class="p-col-12 p-md-2 p-sm-12 p-mt-3 p-d-flex  p-jc-end">
                                                <button pButton pRipple type="button" label="Save"
                                                    (click)="subFormSave(subfieldsList1)"
                                                    class="btnCss finishBtn"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p-card>
                        </div>
                    </div>
                </p-card>

                <p-card *ngIf="isEditMode && jobActionOptions.length !== 0">
                    <div class="p-grid p-pt-1">
                        <div class="p-col-12">
                            <div class="statusRow">
                                <div class="statusLbl">
                                    <label>Actions</label>
                                </div>
                                <div class="statusUpdateDdl dropselection"
                                    *ngIf="jobActionOptions && jobActionOptions.length != 0">
                                    <p-dropdown [options]="jobActionOptions" placeholder="Select Service"
                                        [(ngModel)]="selectedJobAction" (onChange)="updateJobAction(selectedJobAction)"
                                        optionValue="code" optionLabel="val">
                                    </p-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p-tabView [(activeIndex)]="currentChildTabIndex" class="tabsytle"
                        (onChange)="handleServiceTabChange($event)" *ngIf="selectedJobAction && jobActionService">
                        <p-tabPanel header="{{removeUnderScore(jobActionService?.type)}}">
                            <div class="p-grid">
                                <div class="p-col-12">
                                    <p-card class="claimWorkflow">
                                        <div class="p-col-12 r_fontsize ctrlSection">
                                            <div class="p-grid p-ml-0 p-mr-0">
                                                <ng-container *ngFor="let registerForm of jobActionService.Fields">
                                                    <div
                                                        class="p-col-6 p-d-flex p-flex-row borderB p-p-2 tabbed-subjob">
                                                        <div class="p-col-12 p-md-4 p-p-0">
                                                            <label>{{registerForm.name}} <span *ngIf="registerForm.mandatory == 'true'" style="color: red;">*</span></label>
                                                        </div>
                                                        <div class="p-col-12 p-md-8 p-p-0">
                                                            <div
                                                                *ngTemplateOutlet="myFieldTempate; context:{ 'registerForm': registerForm, 'fieldList': [jobActionService] }">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="p-grid p-jc-end">
                                                <div class="p-col-12 p-md-2 p-sm-12 p-mt-3 p-d-flex  p-jc-end">
                                                    <button *ngIf="addMoreGroupMap[jobActionService.groupName]" pButton
                                                        type="button" label="Add" icon="pi pi-plus"
                                                        (click)="duplicateMainJobActionSubJobFields()"
                                                        [ngStyle]="{'margin-right': '20px'}" class="btnCss cancelBtn">
                                                    </button>
                                                    <button pButton pRipple type="button"
                                                        label="{{ getJobSaveButtonText(jobActionServiceSubjobs) }}"
                                                        (click)="saveServiceTabbedSubjob(jobActionService?.Fields, jobActionService?.type, jobId)"
                                                        class="btnCss finishBtn"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </p-card>
                                </div>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel *ngFor="let tab of jobActionServiceSubjobs; let i=index"
                            header="{{removeUnderScore(tab.name)}}" [disabled]="!tab.jobId">
                            <div class="p-grid">
                                <div class="p-col-12">
                                    <p-card class="claimWorkflow">
                                        <div class="p-col-12 r_fontsize ctrlSection">
                                            <div class="p-grid p-ml-0 p-mr-0">
                                                <ng-container *ngFor="let registerForm of tab.Fields">
                                                    <div
                                                        class="p-col-6 p-d-flex p-flex-row borderB p-p-2 tabbed-subjob">
                                                        <div class="p-col-12 p-md-4 p-p-0">
                                                            <label>{{registerForm.name}} <span *ngIf="registerForm.mandatory == 'true'" style="color: red;">*</span></label>
                                                        </div>
                                                        <div class="p-col-12 p-md-8 p-p-0">
                                                            <div
                                                                *ngTemplateOutlet="myFieldTempate; context:{ 'registerForm': registerForm, 'fieldList': jobActionServiceSubjobs }">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="p-grid p-jc-end">
                                                <div class="p-col-12 p-md-4 p-sm-12 p-mt-3 p-d-flex  p-jc-end">
                                                    <button *ngIf="addMoreGroupMap[tab.name]" pButton type="button"
                                                        label="Add" icon="pi pi-plus"
                                                        (click)="duplicateJobActionSubJobFields(i)"
                                                        [ngStyle]="{'margin-right': '20px'}" class="btnCss cancelBtn">
                                                    </button>
                                                    <button pButton pRipple type="button"
                                                        label="{{getSubJobTabSaveButtonText(tab)}}"
                                                        (click)="saveServiceTabbedSubjob(tab?.Fields, tab?.name, tab?.jobId)"
                                                        class="btnCss finishBtn"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </p-card>
                                </div>
                            </div>
                        </p-tabPanel>
                    </p-tabView>
                </p-card>
                <!-- REGISTER FORM ACCORDION -->
                <p-card class="statusEmailCard">
                    <div class="p-col p-p-0 p-d-flex p-jc-end p-flex-row">
                        <div class="p-col-12 p-d-flex p-jc-end p-mb-1">

                            <button pButton type="button" (click)="expand('all')" class="claims_btns btn-misc p-mr-2"
                                label="Expand All"></button>
                            <button pButton type="button" (click)="expand('none')" class="claims_btns btn-misc"
                                label="Collapse All"></button>
                        </div>
                    </div>
                    <div class="p-col p-flex-row p-pt-0 p-pb-0">
                        <div class="p-grid p-mt-0 dynamicAccordion" *ngIf="fieldsList1.length!=0">
                            <p-accordion [multiple]="true">
                                <ng-container *ngFor="let groupForm of groupNamesList; let i = index">
                                    <p-accordionTab header="{{groupForm === ''?'Other Details' : groupForm}}"
                                        [(selected)]="activeDataTab[i]" [ngClass]="getColorCodeClass(groupForm)">
                                        <div class="p-col-12 r_fontsize ctrlSection">
                                            <div class="p-grid p-ml-0 p-mr-0">
                                                <ng-container *ngFor="let registerForm of fieldsList1">
                                                    <div class="p-col-12 p-lg-6 p-md-12 p-sm-12 p-d-flex p-flex-column p-flex-sm-row borderB p-p-2"
                                                        *ngIf='registerForm.groupName === groupForm && registerForm.parent ===1'>

                                                        <div class="p-col-12 p-md-4 p-p-0">
                                                            <label>{{registerForm.fieldName}} <span *ngIf="registerForm.mandatory == 'true'" style="color: red;">*</span></label>
                                                        </div>
                                                        <div class="p-col-12 p-md-8 p-p-0">
                                                            <ng-container
                                                                *ngIf="registerForm.type === 'Small Text';else notText">
                                                                <input type="text" [(ngModel)]="registerForm.value"
                                                                    (ngModelChange)="inputchangeEvent(registerForm, fieldsList1)"
                                                                    [disabled]="isViewOnly" pInputText 
                                                                    [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                            </ng-container>
                                                            <ng-template #notText>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'ReadOnly Small Text';else notReadonly">
                                                                    <input type="text" [(ngModel)]="registerForm.value"
                                                                        [disabled]="isViewOnly" [readonly]="true"
                                                                        pInputText>
                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notReadonly>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'Large Text';else notLargetext">
                                                                    <p-editor [(ngModel)]="registerForm.value"
                                                                        [style]="{'height':'320px'}"></p-editor>
                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notLargetext>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'Date Time';else notDatetime">
                                                                    <p-calendar [(ngModel)]="registerForm.value"
                                                                        [disabled]="isViewOnly" showTime="true"
                                                                        hourFormat="24" appendTo="body">
                                                                    </p-calendar>
                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notDatetime>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'Date'; else notDate">
                                                                    <p-calendar [showIcon]=" true" appendTo="body"
                                                                        [(ngModel)]="registerForm.value"
                                                                        [disabled]="isViewOnly" [monthNavigator]="true"
                                                                        [yearNavigator]="true" dateFormat="dd/mm/yy"
                                                                        inputId="navigators" yearRange="1990:2050"
                                                                        [minDate]="getminDateValue(registerForm,fieldsList1)" 
                                                                        [maxDate]="getmaxDateValue(registerForm,fieldsList1)" 
                                                                        (onSelect)="inputchangeEvent(registerForm, fieldsList1)"
                                                                        (onInput)="inputchangeEvent(registerForm, fieldsList1)"
                                                                        [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                    </p-calendar>
                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notDate>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'Selection';else notSelect">
                                                                    <div *ngIf="registerForm.multiselect === 'false'">
                                                                        <ng-select [items]="registerForm.options"
                                                                            bindLabel="value" [disabled]="isViewOnly"
                                                                            [(ngModel)]="registerForm.value"
                                                                            bindValue="code" [multiple]="false"
                                                                            [closeOnSelect]="true" [searchable]="true"
                                                                            (change)="singleSelectChange($event, registerForm)"
                                                                            [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                        </ng-select>
                                                                    </div>
                                                                    <div *ngIf="registerForm.multiselect === 'true'">
                                                                        <ng-select [items]="registerForm.options"
                                                                            bindLabel="value" [disabled]="isViewOnly"
                                                                            [(ngModel)]="registerForm.value"
                                                                            bindValue="code" [multiple]="true"
                                                                            [closeOnSelect]="false" [searchable]="true"
                                                                            [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                        </ng-select>
                                                                    </div>

                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notSelect>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'Large Selection';else notLargeSelection">
                                                                    <ng-select [items]="itemsList" bindLabel="value"
                                                                        [(ngModel)]="registerForm.value"
                                                                        bindValue="code" [multiple]="true"
                                                                        [closeOnSelect]="true" [searchable]="true">
                                                                    </ng-select>
                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notLargeSelection>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'Small Heading';else notSmallHeading">
                                                                    <h2>Test Small Heading</h2>
                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notSmallHeading>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'Large Heading';else notLargeHeading">
                                                                    <h1>Test Large Heading</h1>
                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notLargeHeading>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'Radio OR CheckBox';else notRadio">
                                                                    <p-checkbox name="groupname"
                                                                        [(ngModel)]="registerForm.value" value="val1">
                                                                    </p-checkbox>

                                                                    <p-radioButton name="groupname"
                                                                        [(ngModel)]="registerForm.value" value="val2">
                                                                    </p-radioButton>
                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notRadio>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'Attachment';else notAttachment">
                                                                    <ngx-file-drop dropZoneLabel="Drop files here"
                                                                        class="claimdragdrop"
                                                                        (onFileDrop)="dropped($event,registerForm)"
                                                                        (onFileOver)="fileOver($event)"
                                                                        (onFileLeave)="fileLeave($event)"
                                                                        multiple="false">
                                                                        <ng-template ngx-file-drop-content-tmp
                                                                            let-openFileSelector="openFileSelector"
                                                                            multiple="true">
                                                                            <div
                                                                                style="width:100%;height:24px;flex:1;text-align: center;margin-top:1px;color: #646565;">
                                                                                Drag & Drop Here</div>
                                                                            <div
                                                                                style="width:100%;height:auto;flex:1;text-align: center;">
                                                                                <button type="button"
                                                                                    (click)="openFileSelector()">Browse</button>
                                                                            </div>
                                                                        </ng-template>

                                                                    </ngx-file-drop>
                                                                    <tr *ngIf="registerForm[registerForm.name +'_Name']"
                                                                        style="cursor: pointer;">
                                                                        <td><a
                                                                                (click)="downloadAttachment(registerForm[registerForm.name +'_URL'])">
                                                                                {{registerForm[registerForm.name
                                                                                +'_Name']}}
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="registerForm.fileName">
                                                                        <td><strong>{{ registerForm.fileName }}</strong>
                                                                        </td>
                                                                    </tr>
                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notAttachment>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'Hidden Text Field';else notHiddentext">
                                                                    <input type="text" [(ngModel)]="registerForm.value"
                                                                        [disabled]="isViewOnly" type="hidden"
                                                                        pInputText>
                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notHiddentext>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'Auto Date Time';else notAutoDatetime">

                                                                    <p-calendar [(ngModel)]="registerForm.value"
                                                                        [showTime]="true" [disabled]="isViewOnly"
                                                                        appendTo="body" inputId="time"></p-calendar>
                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notAutoDatetime>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'Auto Date';else notAutoTime">
                                                                    <p-calendar [showIcon]=" true"
                                                                        [monthNavigator]="true" appendTo="body"
                                                                        [yearNavigator]="true" dateFormat="dd/mm/yy"
                                                                        inputId="navigators" [disabled]="isViewOnly"
                                                                        yearRange="1990:2050"
                                                                        [(ngModel)]="registerForm.value"
                                                                        [minDate]="getminDateValue(registerForm,fieldsList1)" 
                                                                        [maxDate]="getmaxDateValue(registerForm,fieldsList1)" 
                                                                        (onSelect)="inputchangeEvent(registerForm, fieldsList1)"
                                                                        (onInput)="inputchangeEvent(registerForm, fieldsList1)"
                                                                        [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                    </p-calendar>
                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notAutoTime>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'Time';else notTime">
                                                                    <p-calendar [(ngModel)]="registerForm.value"
                                                                        appendTo="body" [timeOnly]="true"
                                                                        [disabled]="isViewOnly" inputId="timeonly">
                                                                    </p-calendar>
                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notTime>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'MulltiMedia';else notMultimedia">
                                                                    <input type="text" pInputText
                                                                        [disabled]="isViewOnly"
                                                                        [(ngModel)]="registerForm.value">
                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notMultimedia>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'Geo Location';else notGeoLocation">
                                                                    <input ngx-google-places-autocomplete
                                                                        #places="ngx-places"
                                                                        [(ngModel)]="registerForm.value"
                                                                        (onAddressChange)="handleAddressChange($event,registerForm)"
                                                                        [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}" />

                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notGeoLocation>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'Numeric Text';else notNumericText">
                                                                    <input pInputText type="text" pKeyFilter="pnum"
                                                                        [disabled]="isViewOnly"
                                                                        [(ngModel)]="registerForm.value"
                                                                        (ngModelChange)="inputchangeEvent(registerForm, fieldsList1)"
                                                                        [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}" />
                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notNumericText>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'Dates after today';else notDatesAfterToday">
                                                                    <p-calendar [showIcon]=" true"
                                                                        [monthNavigator]="true" appendTo="body"
                                                                        [yearNavigator]="true" dateFormat="dd/mm/yy"
                                                                        [minDate]="tomorrow" inputId="navigators"
                                                                        [disabled]="isViewOnly"
                                                                        [(ngModel)]="registerForm.value"
                                                                        yearRange="1990:2050">
                                                                    </p-calendar>
                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notDatesAfterToday>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'SobJob';else notSubJob">
                                                                    <input type="text" pInputText
                                                                        [(ngModel)]="registerForm.value">
                                                                </ng-container>
                                                            </ng-template>
                                                            <ng-template #notSubJob>
                                                                <ng-container
                                                                    *ngIf="registerForm.type === 'Email';else notEmail">
                                                                    <input pInputText type="email"
                                                                        [disabled]="isViewOnly"
                                                                        [(ngModel)]="registerForm.value"
                                                                        pattern="^[a-z0-9._-]+@[a-z]+\.[a-z]{2,4}$"
                                                                        required 
                                                                        [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}"/>
                                                                </ng-container>
                                                            </ng-template>
                                                        </div>

                                                        <!-- <div class="p-col-12 p-md-2 p-p-0"
                                                        *ngIf='registerForm.showRemove == true'>
                                                        <button pButton pRipple type="button"
                                                            (click)="removeDetails(registerForm.id)"
                                                            icon="pi pi-times"
                                                            class="p-button-rounded p-button-danger p-button-text"></button>
                                                    </div> -->
                                                    </div>
                                                    <div class="line-divider"
                                                        *ngIf='registerForm.groupName === groupForm && registerForm.showRemove == true'
                                                        style="margin-top: 25px; width: 100%;"></div>
                                                </ng-container>
                                            </div>
                                            <div>
                                                <div class="p-grid p-ml-0 p-mr-0">
                                                    <!-- <div class="p-col-12 p-d-flex p-flex-row" style="flex-wrap: wrap;"> -->
                                                    <ng-container
                                                        *ngFor="let registerForm of fieldsList1; let i = index">
                                                        <ng-container
                                                            *ngIf='registerForm.groupName === groupForm && registerForm.parent ===0'>
                                                            <div
                                                                class="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12 p-d-flex p-flex-column p-flex-sm-row borderB p-p-2">
                                                                <div class="p-col-12 p-md-4 p-p-0">
                                                                    <label>{{registerForm.fieldName}} <span *ngIf="registerForm.mandatory == 'true'" style="color: red;">*</span></label>
                                                                </div>
                                                                <div class="p-col-12 p-md-8 p-p-0">
                                                                    <ng-container
                                                                        *ngIf="registerForm.type === 'Small Text';else notText">
                                                                        <input type="text" pInputText
                                                                            [(ngModel)]="registerForm.value"
                                                                            (ngModelChange)="inputchangeEvent(registerForm, fieldsList1)"
                                                                            [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                    </ng-container>
                                                                    <ng-template #notText>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'ReadOnly Small Text';else notReadonly">
                                                                            <input type="text" [readonly]="true"
                                                                                [(ngModel)]="registerForm.value"
                                                                                pInputText>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notReadonly>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Large Text';else notLargetext">
                                                                            <p-editor [(ngModel)]="registerForm.value"
                                                                                [style]="{'height':'320px'}"></p-editor>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notLargetext>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Date Time';else notDatetime">
                                                                            <p-calendar showTime="true"
                                                                                [(ngModel)]="registerForm.value"
                                                                                hourFormat="24" appendTo="body">
                                                                            </p-calendar>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notDatetime>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Date'; else notDate">
                                                                            <p-calendar [showIcon]=" true"
                                                                                [monthNavigator]="true" appendTo="body"
                                                                                [yearNavigator]="true"
                                                                                dateFormat="dd/mm/yy"
                                                                                inputId="navigators"
                                                                                yearRange="1990:2050"
                                                                                [(ngModel)]="registerForm.value"
                                                                                [minDate]="getminDateValue(registerForm,fieldsList1)" 
                                                                                [maxDate]="getmaxDateValue(registerForm,fieldsList1)" 
                                                                                (onSelect)="inputchangeEvent(registerForm, fieldsList1)"
                                                                                (oninput)="inputchangeEvent(registerForm, fieldsList1)"
                                                                                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                            </p-calendar>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notDate>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Selection';else notSelect">
                                                                            <div
                                                                                *ngIf="registerForm.multiselect === 'false'">
                                                                                <ng-select
                                                                                    [items]="registerForm.options"
                                                                                    bindLabel="value" bindValue="code"
                                                                                    [multiple]="false"
                                                                                    [closeOnSelect]="true"
                                                                                    [searchable]="true"
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    (change)="singleSelectChange($event, registerForm)"
                                                                                    [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                                </ng-select>
                                                                            </div>
                                                                            <div
                                                                                *ngIf="registerForm.multiselect === 'true'">
                                                                                <ng-select
                                                                                    [items]="registerForm.options"
                                                                                    bindLabel="value" bindValue="code"
                                                                                    [multiple]="true"
                                                                                    [closeOnSelect]="false"
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    [searchable]="true"
                                                                                    [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                                </ng-select>
                                                                            </div>

                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notSelect>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Large Selection';else notLargeSelection">
                                                                            <ng-select [items]="itemsList"
                                                                                bindLabel="value" bindValue="code"
                                                                                [multiple]="false"
                                                                                [closeOnSelect]="true"
                                                                                [(ngModel)]="registerForm.value"
                                                                                [searchable]="true">
                                                                            </ng-select>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notLargeSelection>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Small Heading';else notSmallHeading">
                                                                            <h2>Test Small Heading</h2>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notSmallHeading>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Large Heading';else notLargeHeading">
                                                                            <h1>Test Large Heading</h1>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notLargeHeading>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Radio OR CheckBox';else notRadio">
                                                                            <p-checkbox name="groupname" value="val1">
                                                                            </p-checkbox>

                                                                            <p-radioButton name="groupname"
                                                                                value="val2">
                                                                            </p-radioButton>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notRadio>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Attachment';else notAttachment">
                                                                            <ngx-file-drop class="claimdragdrop"
                                                                                dropZoneLabel="Drop files here"
                                                                                (onFileDrop)="dropped($event,registerForm)"
                                                                                (onFileOver)="fileOver($event)"
                                                                                (onFileLeave)="fileLeave($event)"
                                                                                multiple="false">
                                                                                <ng-template ngx-file-drop-content-tmp
                                                                                    let-openFileSelector="openFileSelector"
                                                                                    multiple="true">
                                                                                    <div
                                                                                        style="width:100%;height:24px;flex:1;text-align: center;margin-top:1px;color: #646565;">
                                                                                        Drag & Drop Here
                                                                                    </div>
                                                                                    <div
                                                                                        style="width:100%;height:auto;flex:1;text-align: center;">
                                                                                        <button type="button"
                                                                                            (click)="openFileSelector()">Browse</button>
                                                                                    </div>
                                                                                </ng-template>

                                                                            </ngx-file-drop>

                                                                            <tr *ngIf="registerForm[registerForm.name +'_Name']"
                                                                                style="cursor: pointer;">
                                                                                <td><a
                                                                                        (click)="downloadAttachment(registerForm[registerForm.name +'_URL'])">
                                                                                        {{registerForm[registerForm.name
                                                                                        +'_Name']}}
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngIf="registerForm.fileName">
                                                                                <td><strong>{{registerForm.fileName}}</strong>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notAttachment>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Hidden Text Field';else notHiddentext">
                                                                            <input type="text" type="hidden" pInputText>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notHiddentext>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Auto Date Time';else notAutoDatetime">

                                                                            <p-calendar [(ngModel)]="registerForm.value"
                                                                                [showTime]="true" appendTo="body"
                                                                                inputId="time">
                                                                            </p-calendar>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notAutoDatetime>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Auto Date';else notAutoTime">
                                                                            <p-calendar [showIcon]=" true"
                                                                                [monthNavigator]="true" appendTo="body"
                                                                                [yearNavigator]="true"
                                                                                dateFormat="dd/mm/yy"
                                                                                inputId="navigators"
                                                                                yearRange="1990:2050"
                                                                                [(ngModel)]="registerForm.value"
                                                                                [minDate]="getminDateValue(registerForm,fieldsList1)" 
                                                                                [maxDate]="getmaxDateValue(registerForm,fieldsList1)" 
                                                                                (onSelect)="inputchangeEvent(registerForm, fieldsList1)"
                                                                                (onInput)="inputchangeEvent(registerForm, fieldsList1)"
                                                                                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                            </p-calendar>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notAutoTime>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Time';else notTime">
                                                                            <p-calendar [timeOnly]="true"
                                                                                inputId="timeonly"
                                                                                [disabled]="isViewOnly" appendTo="body">
                                                                            </p-calendar>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notTime>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'MulltiMedia';else notMultimedia">
                                                                            <input type="text" [disabled]="isViewOnly"
                                                                                pInputText>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notMultimedia>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Geo Location';else notGeoLocation">
                                                                            <input ngx-google-places-autocomplete
                                                                                #places="ngx-places"
                                                                                [disabled]="isViewOnly"
                                                                                (onAddressChange)="handleAddressChange($event,registerForm)" 
                                                                                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}"/>

                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notGeoLocation>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Numeric Text';else notNumericText">
                                                                            <input pInputText type="text"
                                                                                [disabled]="isViewOnly"
                                                                                pKeyFilter="pnum"
                                                                                (ngModelChange)="inputchangeEvent(registerForm, fieldsList1)"
                                                                                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}"/>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notNumericText>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Dates after today';else notDatesAfterToday">
                                                                            <p-calendar [showIcon]=" true"
                                                                                [monthNavigator]="true" appendTo="body"
                                                                                [yearNavigator]="true"
                                                                                dateFormat="dd/mm/yy"
                                                                                [minDate]="tomorrow"
                                                                                [disabled]="isViewOnly"
                                                                                inputId="navigators"
                                                                                yearRange="1990:2050">
                                                                            </p-calendar>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notDatesAfterToday>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'SobJob';else notSubJob">
                                                                            <input type="text" pInputText>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notSubJob>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Email';else notEmail">
                                                                            <input pInputText type="email"
                                                                                [(ngModel)]="registerForm.value"
                                                                                pattern="^[a-z0-9._-]+@[a-z]+\.[a-z]{2,4}$"
                                                                                required [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}"/>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                </div>
                                                                <div class="p-col-12 p-md-2 p-p-0"
                                                                    *ngIf='registerForm.showRemove == true'>
                                                                    <button pButton pRipple type="button"
                                                                        (click)="removeDetails(registerForm.id)"
                                                                        icon="pi pi-times"
                                                                        class="p-button-rounded p-button-danger p-button-text"></button>
                                                                </div>
                                                            </div>
                                                            <div class="line-divider"
                                                                *ngIf='registerForm.groupName === groupForm && registerForm.showRemove == true'
                                                                style="margin-top: 25px; width: 100%;"></div>
                                                        </ng-container>
                                                    </ng-container>
                                                    <!-- </div> -->
                                                </div>
                                            </div>


                                            <div class="p-col-12 p-p-0 p-mt-3 p-d-flex p-jc-end"
                                                *ngIf="addMoreGroupMap[groupForm]">
                                                <button pButton type="button" label="Add" icon="pi pi-plus"
                                                    (click)="AddingDetails(groupForm, 'mainForm')"
                                                    class="btnCss cancelBtn"></button>
                                            </div>
                                        </div>
                                    </p-accordionTab>

                                </ng-container>
                                <!-- </p-accordion>    <p-accordion [multiple]="true"> -->
                                <div *ngIf="subFormFieldsInMainForm.length!=0">

                                    <ng-container *ngFor="let submainForm of subFormNamesInMainForm; let j= index">
                                        <p-accordionTab [(selected)]="activeSubForm[j]">
                                            <!-- header="{{submainForm === ''?'Other Details' : getHeaderForSubjob(submainForm)}}" -->
                                            <ng-template pTemplate="header">

                                                <div class="accHeader">
                                                    <div class="accHeaderLeft">{{submainForm === ''?'Other Details' :
                                                        getHeaderForSubjob(submainForm)}}</div>
                                                    <div class="accHeaderRight">
                                                        Added by: {{createdByFullName}} - on: {{modifiedOn |
                                                        date:'dd/MM/yyyy h:mm:ss a'}} IST
                                                    </div>
                                                </div>

                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <div class="p-col-12 r_fontsize ctrlSection">
                                                    <div class="p-grid p-ml-0 p-mr-0">
                                                        <ng-container
                                                            *ngFor="let registerForm of subFormFieldsInMainForm">
                                                            <div class="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12 p-d-flex p-flex-column p-flex-sm-row borderB p-p-2"
                                                                *ngIf='registerForm.subFormName === submainForm && registerForm.parent ===1'>

                                                                <div class="p-col-12 p-md-4 p-p-0">
                                                                    <label>{{registerForm.fieldName}} <span *ngIf="registerForm.mandatory == 'true'" style="color: red;">*</span></label>
                                                                </div>
                                                                <div class="p-col-12 p-md-8 p-p-0">
                                                                    <ng-container
                                                                        *ngIf="registerForm.type === 'Small Text';else notText">
                                                                        <input type="text"
                                                                            [(ngModel)]="registerForm.value" pInputText
                                                                            (ngModelChange)="inputchangeEvent(registerForm, subFormFieldsInMainForm)"
                                                                            [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                    </ng-container>
                                                                    <ng-template #notText>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'ReadOnly Small Text';else notReadonly">
                                                                            <input type="text"
                                                                                [(ngModel)]="registerForm.value"
                                                                                [readonly]="true" pInputText>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notReadonly>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Large Text';else notLargetext">
                                                                            <p-editor [(ngModel)]="registerForm.value"
                                                                                [style]="{'height':'320px'}"></p-editor>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notLargetext>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Date Time';else notDatetime">
                                                                            <p-calendar [(ngModel)]="registerForm.value"
                                                                                showTime="true" hourFormat="24"
                                                                                appendTo="body">
                                                                            </p-calendar>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notDatetime>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Date'; else notDate">
                                                                            <p-calendar [showIcon]=" true"
                                                                                appendTo="body"
                                                                                [(ngModel)]="registerForm.value"
                                                                                [monthNavigator]="true"
                                                                                [yearNavigator]="true"
                                                                                dateFormat="dd/mm/yy"
                                                                                inputId="navigators"
                                                                                yearRange="1990:2050"
                                                                                [minDate]="getminDateValue(registerForm,subFormFieldsInMainForm)" 
                                                                                [maxDate]="getmaxDateValue(registerForm,subFormFieldsInMainForm)" 
                                                                                (onSelect)="inputchangeEvent(registerForm, subFormFieldsInMainForm)"
                                                                                (onInput)="inputchangeEvent(registerForm, subFormFieldsInMainForm)"
                                                                                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                            </p-calendar>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notDate>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Selection';else notSelect">
                                                                            <div
                                                                                *ngIf="registerForm.multiselect === 'false'">
                                                                                <ng-select
                                                                                    [items]="registerForm.options"
                                                                                    bindLabel="value"
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    bindValue="code" [multiple]="false"
                                                                                    [closeOnSelect]="true"
                                                                                    [searchable]="true"
                                                                                    (change)="singleSelectChange($event, registerForm)"
                                                                                    [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                                </ng-select>
                                                                            </div>
                                                                            <div
                                                                                *ngIf="registerForm.multiselect === 'true'">
                                                                                <ng-select
                                                                                    [items]="registerForm.options"
                                                                                    bindLabel="value"
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    bindValue="code" [multiple]="true"
                                                                                    [closeOnSelect]="false"
                                                                                    [searchable]="true"
                                                                                    [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                                </ng-select>
                                                                            </div>

                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notSelect>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Large Selection';else notLargeSelection">
                                                                            <ng-select [items]="itemsList"
                                                                                bindLabel="value"
                                                                                [(ngModel)]="registerForm.value"
                                                                                bindValue="code" [multiple]="true"
                                                                                [closeOnSelect]="true"
                                                                                [searchable]="true">
                                                                            </ng-select>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notLargeSelection>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Small Heading';else notSmallHeading">
                                                                            <h2>Test Small Heading</h2>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notSmallHeading>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Large Heading';else notLargeHeading">
                                                                            <h1>Test Large Heading</h1>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notLargeHeading>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Radio OR CheckBox';else notRadio">
                                                                            <p-checkbox name="groupname"
                                                                                [(ngModel)]="registerForm.value"
                                                                                value="val1">
                                                                            </p-checkbox>

                                                                            <p-radioButton name="groupname"
                                                                                [(ngModel)]="registerForm.value"
                                                                                value="val2">
                                                                            </p-radioButton>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notRadio>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Attachment';else notAttachment">
                                                                            <ngx-file-drop
                                                                                dropZoneLabel="Drop files here"
                                                                                class="claimdragdrop"
                                                                                (onFileDrop)="dropped($event,registerForm)"
                                                                                (onFileOver)="fileOver($event)"
                                                                                (onFileLeave)="fileLeave($event)"
                                                                                multiple="false">
                                                                                <ng-template ngx-file-drop-content-tmp
                                                                                    let-openFileSelector="openFileSelector"
                                                                                    multiple="true">
                                                                                    <div
                                                                                        style="width:100%;height:24px;flex:1;text-align: center;margin-top:1px;color: #646565;">
                                                                                        Drag & Drop Here</div>
                                                                                    <div
                                                                                        style="width:100%;height:auto;flex:1;text-align: center;">
                                                                                        <button type="button"
                                                                                            (click)="openFileSelector()">Browse</button>
                                                                                    </div>
                                                                                </ng-template>

                                                                            </ngx-file-drop>
                                                                            <tr *ngIf="registerForm[registerForm.name +'_Name']"
                                                                                style="cursor: pointer;">
                                                                                <td><a
                                                                                        (click)="downloadAttachment(registerForm[registerForm.name +'_URL'])">
                                                                                        {{registerForm[registerForm.name
                                                                                        +'_Name']}}
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngIf="registerForm.fileName">
                                                                                <td><strong>{{registerForm.fileName}}</strong>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notAttachment>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Hidden Text Field';else notHiddentext">
                                                                            <input type="text"
                                                                                [(ngModel)]="registerForm.value"
                                                                                type="hidden" pInputText>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notHiddentext>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Auto Date Time';else notAutoDatetime">

                                                                            <p-calendar [(ngModel)]="registerForm.value"
                                                                                [showTime]="true" appendTo="body"
                                                                                inputId="time"></p-calendar>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notAutoDatetime>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Auto Date';else notAutoTime">
                                                                            <p-calendar [showIcon]=" true"
                                                                                [monthNavigator]="true" appendTo="body"
                                                                                [yearNavigator]="true"
                                                                                dateFormat="dd/mm/yy"
                                                                                inputId="navigators"
                                                                                yearRange="1990:2050"
                                                                                [(ngModel)]="registerForm.value"
                                                                                [minDate]="getminDateValue(registerForm,subFormFieldsInMainForm)" 
                                                                                [maxDate]="getmaxDateValue(registerForm,subFormFieldsInMainForm)" 
                                                                                (onSelect)="inputchangeEvent(registerForm, subFormFieldsInMainForm)"
                                                                                (onInput)="inputchangeEvent(registerForm, subFormFieldsInMainForm)"
                                                                                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                            </p-calendar>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notAutoTime>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Time';else notTime">
                                                                            <p-calendar [(ngModel)]="registerForm.value"
                                                                                appendTo="body" [timeOnly]="true"
                                                                                inputId="timeonly"></p-calendar>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notTime>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'MulltiMedia';else notMultimedia">
                                                                            <input type="text" pInputText
                                                                                [(ngModel)]="registerForm.value">
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notMultimedia>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Geo Location';else notGeoLocation">
                                                                            <input ngx-google-places-autocomplete
                                                                                [(ngModel)]="registerForm.value"
                                                                                #places="ngx-places"
                                                                                (onAddressChange)="handleAddressChange($event, registerForm)"
                                                                                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}" />

                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notGeoLocation>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Numeric Text';else notNumericText">
                                                                            <input pInputText type="text"
                                                                                pKeyFilter="pnum"
                                                                                [(ngModel)]="registerForm.value"
                                                                                (ngModelChange)="inputchangeEvent(registerForm, subFormFieldsInMainForm)"
                                                                                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}"/>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notNumericText>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Dates after today';else notDatesAfterToday">
                                                                            <p-calendar [showIcon]=" true"
                                                                                [monthNavigator]="true" appendTo="body"
                                                                                [yearNavigator]="true"
                                                                                dateFormat="dd/mm/yy"
                                                                                [minDate]="tomorrow"
                                                                                inputId="navigators"
                                                                                [(ngModel)]="registerForm.value"
                                                                                yearRange="1990:2050">
                                                                            </p-calendar>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notDatesAfterToday>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'SobJob';else notSubJob">
                                                                            <input type="text" pInputText
                                                                                [(ngModel)]="registerForm.value">
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #notSubJob>
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Email';else notEmail">
                                                                            <input pInputText type="email"
                                                                                [(ngModel)]="registerForm.value"
                                                                                pattern="^[a-z0-9._-]+@[a-z]+\.[a-z]{2,4}$"
                                                                                required [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}"/>
                                                                        </ng-container>
                                                                    </ng-template>

                                                                </div>
                                                                <!-- <div class="p-col-12 p-md-2 p-p-0"
                                                            *ngIf='registerForm.showRemove == true'>
                                                            <button pButton pRipple type="button"
                                                                (click)="removeSubFromDetails(subFormFieldsInMainForm, registerForm.id)"
                                                                icon="pi pi-times"
                                                                class="p-button-rounded p-button-danger p-button-text"></button>

                                                        </div> -->

                                                            </div>
                                                            <div class="line-divider"
                                                                *ngIf='registerForm.groupName === groupForm && registerForm.showRemove == true'
                                                                style="margin-top: 25px; width: 100%;">
                                                            </div>
                                                        </ng-container>

                                                        <div class="line-divider"
                                                            style="margin-top: 20px; width: 100%;"></div>
                                                        <div [ngStyle]="{'margin-bottom': '20px'}"
                                                            class="p-col-12 p-d-flex p-jc-end">
                                                            <button *ngIf="addMoreGroupMap[submainForm]" pButton
                                                                type="button" label="Add" icon="pi pi-plus"
                                                                (click)="duplicateSubformGroup(subFormFieldsInMainForm, submainForm)"
                                                                [ngStyle]="{'margin-right': '20px'}"
                                                                class="btnCss cancelBtn">
                                                            </button>
                                                            <button pButton type="button" label="Save"
                                                                (click)="saveSubMainForm(subFormFieldsInMainForm, submainForm)"
                                                                class="btnCss finishBtn "
                                                                [disabled]="disableSubmitButton">
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div class="p-grid p-ml-0 p-mr-0">
                                                        <!-- <div class="p-col-12 p-d-flex p-flex-row" style="flex-wrap: wrap;"> -->
                                                        <ng-container
                                                            *ngFor="let registerForm of subFormFieldsInMainForm; let i = index">
                                                            <ng-container
                                                                *ngIf='registerForm.subFormName === submainForm && registerForm.parent ===0'>
                                                                <div
                                                                    class="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12 p-d-flex p-flex-column p-flex-sm-row borderB p-p-2">
                                                                    <div class="p-col-12 p-md-4 p-p-0">
                                                                        <label>{{registerForm.fieldName}} <span *ngIf="registerForm.mandatory == 'true'" style="color: red;">*</span></label>
                                                                    </div>
                                                                    <div class="p-col-12 p-md-8 p-p-0">
                                                                        <ng-container
                                                                            *ngIf="registerForm.type === 'Small Text';else notText">
                                                                            <input type="text" pInputText
                                                                                (ngModelChange)="inputchangeEvent(registerForm, subFormFieldsInMainForm)"
                                                                                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                        </ng-container>
                                                                        <ng-template #notText>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'ReadOnly Small Text';else notReadonly">
                                                                                <input type="text" [readonly]="true"
                                                                                    pInputText>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notReadonly>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Large Text';else notLargetext">
                                                                                <p-editor
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    [style]="{'height':'320px'}">
                                                                                </p-editor>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notLargetext>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Date Time';else notDatetime">
                                                                                <p-calendar showTime="true"
                                                                                    hourFormat="24" appendTo="body">
                                                                                </p-calendar>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notDatetime>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Date'; else notDate">
                                                                                <p-calendar [showIcon]=" true"
                                                                                    [monthNavigator]="true"
                                                                                    appendTo="body"
                                                                                    [yearNavigator]="true"
                                                                                    dateFormat="dd/mm/yy"
                                                                                    inputId="navigators"
                                                                                    yearRange="1990:2050"
                                                                                    [minDate]="getminDateValue(registerForm,subFormFieldsInMainForm)" 
                                                                                    [maxDate]="getmaxDateValue(registerForm,subFormFieldsInMainForm)" 
                                                                                    (onSelect)="inputchangeEvent(registerForm, subFormFieldsInMainForm)"
                                                                                    (onInput)="inputchangeEvent(registerForm, subFormFieldsInMainForm)"
                                                                                    [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                                </p-calendar>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notDate>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Selection';else notSelect">
                                                                                <div
                                                                                    *ngIf="registerForm.multiselect === 'false'">
                                                                                    <ng-select
                                                                                        [items]="registerForm.options"
                                                                                        bindLabel="value"
                                                                                        bindValue="code"
                                                                                        [multiple]="false"
                                                                                        [closeOnSelect]="true"
                                                                                        [searchable]="true"
                                                                                        (change)="singleSelectChange($event, registerForm)"
                                                                                        [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                                    </ng-select>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="registerForm.multiselect === 'true'">
                                                                                    <ng-select
                                                                                        [items]="registerForm.options"
                                                                                        bindLabel="value"
                                                                                        bindValue="code"
                                                                                        [multiple]="true"
                                                                                        [closeOnSelect]="false"
                                                                                        [searchable]="true"
                                                                                        [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                                    </ng-select>
                                                                                </div>

                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notSelect>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Large Selection';else notLargeSelection">
                                                                                <ng-select [items]="itemsList"
                                                                                    bindLabel="value" bindValue="code"
                                                                                    [multiple]="false"
                                                                                    [closeOnSelect]="true"
                                                                                    [searchable]="true">
                                                                                </ng-select>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notLargeSelection>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Small Heading';else notSmallHeading">
                                                                                <h2>Test Small Heading</h2>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notSmallHeading>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Large Heading';else notLargeHeading">
                                                                                <h1>Test Large Heading</h1>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notLargeHeading>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Radio OR CheckBox';else notRadio">
                                                                                <p-checkbox name="groupname"
                                                                                    value="val1">
                                                                                </p-checkbox>

                                                                                <p-radioButton name="groupname"
                                                                                    value="val2">
                                                                                </p-radioButton>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notRadio>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Attachment';else notAttachment">
                                                                                <ngx-file-drop class="claimdragdrop"
                                                                                    dropZoneLabel="Drop files here"
                                                                                    (onFileDrop)="dropped($event,registerForm)"
                                                                                    (onFileOver)="fileOver($event)"
                                                                                    (onFileLeave)="fileLeave($event)"
                                                                                    multiple="false">
                                                                                    <ng-template
                                                                                        ngx-file-drop-content-tmp
                                                                                        let-openFileSelector="openFileSelector"
                                                                                        multiple="true">
                                                                                        <div
                                                                                            style="width:100%;height:24px;flex:1;text-align: center;margin-top:1px;color: #646565;">
                                                                                            Drag & Drop Here
                                                                                        </div>
                                                                                        <div
                                                                                            style="width:100%;height:auto;flex:1;text-align: center;">
                                                                                            <button type="button"
                                                                                                (click)="openFileSelector()">Browse</button>
                                                                                        </div>
                                                                                    </ng-template>

                                                                                </ngx-file-drop>

                                                                                <tr *ngIf="registerForm[registerForm.name +'_Name']"
                                                                                    style="cursor: pointer;">
                                                                                    <td><a
                                                                                            (click)="downloadAttachment(registerForm[registerForm.name +'_URL'])">
                                                                                            {{registerForm[registerForm.name
                                                                                            +'_Name']}}
                                                                                        </a>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr *ngIf="registerForm.fileName">
                                                                                    <td><strong>{{registerForm.fileName}}</strong>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notAttachment>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Hidden Text Field';else notHiddentext">
                                                                                <input type="text" type="hidden"
                                                                                    pInputText>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notHiddentext>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Auto Date Time';else notAutoDatetime">

                                                                                <p-calendar
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    [showTime]="true" appendTo="body"
                                                                                    inputId="time">
                                                                                </p-calendar>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notAutoDatetime>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Auto Date';else notAutoTime">
                                                                                <p-calendar [showIcon]=" true"
                                                                                    [monthNavigator]="true"
                                                                                    appendTo="body"
                                                                                    [yearNavigator]="true"
                                                                                    dateFormat="dd/mm/yy"
                                                                                    inputId="navigators"
                                                                                    yearRange="1990:2050"
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    [minDate]="getminDateValue(registerForm,subFormFieldsInMainForm)" 
                                                                                    [maxDate]="getmaxDateValue(registerForm,subFormFieldsInMainForm)" 
                                                                                    (onSelect)="inputchangeEvent(registerForm, subFormFieldsInMainForm)"
                                                                                    (onInput)="inputchangeEvent(registerForm, subFormFieldsInMainForm)"
                                                                                    [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
                                                                                </p-calendar>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notAutoTime>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Time';else notTime">
                                                                                <p-calendar [timeOnly]="true"
                                                                                    inputId="timeonly" appendTo="body">
                                                                                </p-calendar>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notTime>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'MulltiMedia';else notMultimedia">
                                                                                <input type="text" pInputText>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notMultimedia>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Geo Location';else notGeoLocation">
                                                                                <input ngx-google-places-autocomplete
                                                                                    #places="ngx-places"
                                                                                    (onAddressChange)="handleAddressChange($event,registerForm)" 
                                                                                    [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}"/>

                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notGeoLocation>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Numeric Text';else notNumericText">
                                                                                <input pInputText type="text"
                                                                                    pKeyFilter="pnum"
                                                                                    (ngModelChange)="inputchangeEvent(registerForm, subFormFieldsInMainForm)"
                                                                                    [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}"/>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notNumericText>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Dates after today';else notDatesAfterToday">
                                                                                <p-calendar [showIcon]=" true"
                                                                                    [monthNavigator]="true"
                                                                                    appendTo="body"
                                                                                    [yearNavigator]="true"
                                                                                    dateFormat="dd/mm/yy"
                                                                                    [minDate]="tomorrow"
                                                                                    inputId="navigators"
                                                                                    yearRange="1990:2050">
                                                                                </p-calendar>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notDatesAfterToday>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'SobJob';else notSubJob">
                                                                                <input type="text" pInputText>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                        <ng-template #notSubJob>
                                                                            <ng-container
                                                                                *ngIf="registerForm.type === 'Email';else notEmail">
                                                                                <input pInputText type="email"
                                                                                    [(ngModel)]="registerForm.value"
                                                                                    pattern="^[a-z0-9._-]+@[a-z]+\.[a-z]{2,4}$"
                                                                                    required [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}"/>
                                                                            </ng-container>
                                                                        </ng-template>
                                                                    </div>
                                                                    <div class="p-col-12 p-md-2 p-p-0"
                                                                        *ngIf='registerForm.showRemove == true'>
                                                                        <button pButton pRipple type="button"
                                                                            (click)="removeDetails(registerForm.id)"
                                                                            icon="pi pi-times"
                                                                            class="p-button-rounded p-button-danger p-button-text"></button>

                                                                    </div>
                                                                </div>
                                                                <div class="line-divider"
                                                                    *ngIf='registerForm.groupName === groupForm && registerForm.showRemove == true'
                                                                    style="margin-top: 25px; width: 100%;"></div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <!-- </div> -->
                                                    </div>
                                                </div>
                                            </ng-template>

                                        </p-accordionTab>
                                    </ng-container>

                                </div>
                            </p-accordion>
                        </div>
                        <div class="p-grid p-jc-end">
                            <div class="p-col-12 p-md-2 p-sm-12 p-mt-5 p-d-flex  p-jc-end">
                                <span *ngIf="mode === 'edit' || jobId == 0">
                                    <button pButton type="button" label="{{getJobSaveButtonText()}}"
                                        (click)="saveOrUpdateMainForm(fieldsList1)" class="btnCss saveAllBtn"
                                        [disabled]="disableSubmitButton"></button>
                                </span>
                            </div>
                        </div>
                    </div>
                </p-card>

                <!-- EMAIL NOTIFICATION -->
                <div class="p-grid" *ngIf="isEditMode && emailsFieldsList?.length != 0">
                    <div class="p-col-12">
                        <p-card class="statusEmailCard">
                            <div class="tableheader">
                                Email Notification
                            </div>
                            <div class="p-grid">
                                <div class="p-col-12">
                                    <div class="p-d-flex p-flex-row">
                                        <div class="p-col-7 p-lg-5 p-md-6 p-sm-7 p-pl-0">
                                            <p-multiSelect [options]="emailsFieldsList" [(ngModel)]="emailsList"
                                                appendTo="body" optionLabel="label" optionValue="value"
                                                placeholder="Select Emails">
                                            </p-multiSelect>
                                        </div>
                                        <div class="p-col-5 p-lg-3 p-md-3 p-sm-3" style="align-self: left;">
                                            <button pButton pRipple type="button" (click)="sendEmail()"
                                                label="Send Email" class="btnCss sendBtn"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p-card>
                    </div>
                </div>

                <!-- <div class="p-grid" *ngIf="isEditMode">
                    <div class="p-col-12">
                        <p-card class="claimWorkflow">
                            <div class="tableheader">
                                CLAIM WORKFLOW
                            </div>
                            <div class="p-grid">
                                <div class="p-col-12 p-pl-0">
                                    <ol class="c-progress-steps">
                                        <ng-container *ngFor="let itemState of items">
                                            <li class="c-progress-steps__step"
                                                [ngClass]="{'done': itemState.status === 0, 'current' : itemState.status === 1, 'next' : itemState.status === 2 }">
                                                {{itemState.label}}</li>
                                        </ng-container>
                                    </ol>
                                </div>
                                <div class="p-col-12 p-pl-0">
                                    <p-accordion>
                                        <p-accordionTab class="activityHistory" header="Activity History"
                                            [(selected)]="activeHistory">
                                            <div class="p-col-12" style="position:relative;">
                                                <div class="histort-timeline p-mt-4">
                                                    <ul class="activeTimeline">
                                                        <ng-container *ngFor="let job of jobComments">
                                                            <li>
                                                                <p class="timeline-date"> {{job.comment}}</p>
                                                                <div class="histort-content">
                                                                    <div class="track-info">
                                                                        <h5> {{job.comment}}</h5>
                                                                        <p class="pClass"> {{job.time}}<br />
                                                                            {{job.postedBy_Name}}<br />
                                                                        </p>
                                                                        <button pButton type="button" label="Send Email"
                                                                            class="btnCss downloadBtn rejectedFont p-mr-2">
                                                                        </button>
                                                                    </div>

                                                                </div>

                                                            </li>
                                                        </ng-container>
                                                    </ul>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                    </p-accordion>
                                </div>
                            </div>
                        </p-card>
                    </div>
                </div> -->
            </div>

        </p-tabPanel>

        <p-tabPanel *ngFor="let tab of subJobTabs; let i=index" header="{{removeUnderScore(tab.name)}}"
            [disabled]="!tab.jobId">
            <div class="p-col-12 p-p-0 p-pb-0">
                <p-card class="claimWorkflow">
                    <div class="p-col-12 r_fontsize ctrlSection">
                        <div class="p-grid p-ml-0 p-mr-0">
                            <ng-container *ngFor="let registerForm of tab.fields">
                                <div class="p-col-6 p-d-flex p-flex-row borderB p-p-2 tabbed-subjob">
                                    <div class="p-col-12 p-md-4 p-p-0">
                                        <label>{{registerForm.name}} <span *ngIf="registerForm.mandatory == 'true'" style="color: red;">*</span></label>
                                    </div>
                                    <div class="p-col-12 p-md-8 p-p-0">
                                        <div
                                            *ngTemplateOutlet="myFieldTempate; context:{ 'registerForm': registerForm, 'fieldList': subJobTabs }">
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="p-grid p-jc-end">
                            <div class="p-col-12 p-md-4 p-sm-12 p-mt-3 p-d-flex  p-jc-end">
                                <button *ngIf="addMoreGroupMap[tab.groupName]" pButton type="button" label="Add"
                                    icon="pi pi-plus" (click)="duplicateTabbedSubJobFields(i)"
                                    [ngStyle]="{'margin-right': '20px'}" class="btnCss cancelBtn">
                                </button>
                                <button pButton pRipple type="button" label="{{getSubJobTabSaveButtonText()}}"
                                    (click)="saveTabbedSubjob(tab)" class="btnCss finishBtn" [disabled]="!tab.jobId">
                                </button>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>
        </p-tabPanel>

        <p-tabPanel header="Upload Files" *ngIf="mode === 'edit' || mode === 'view'">

            <div class="p-col-12 p-p-0 p-pb-0">
                <div class="cardSection">
                    <p-card class="claimWorkflow">
                        <!-- <div class="tableheader"> Document Repository</div>
                <div class="p-grid" *ngFor="let file of fileAttachments; let i=index">
                    <div class="dynamicCtrlWrap">
                        <div class=" p-col-12 p-md-4">
                            <label for="firstname">Document type</label>
                            <input id="fieldId" type="text" [(ngModel)]="file.Image_Name" pInputText>
                        </div>
                        <div class="p-col-12 p-md-1 center">
                            <i class="pi pi-download" style="margin-top: 30px;" (click)="download(file)"></i>
                        </div>
                    </div>
                </div> -->

                        <div class="tableheader">Expected Documents</div>
                        <div class="p-col-12 p-lg-9 p-p-0 p-d-flex p-flex-column p-flex-md-row fieldLevelTbl"
                            *ngFor="let file of getFileList('field', 'browse'); let i=index">

                            <div class=" p-col-12 p-lg-4 p-md-6" style="border-right:1px solid #ddd;">
                                <label for="firstname">{{file.groupName.split(':-')[0]}}</label>
                            </div>
                            <div class="p-col-12 p-lg-8 p-md-6 center">
                                <input type="file" id="uploadFileList{{i}}">
                            </div>

                        </div>



                    </p-card>
                </div>
            </div>

            <div class="p-col-12 p-p-0 p-pb-0">
                <div class="cardSection">
                    <p-card class="claimWorkflow">
                        <div class="tableheader">Form fields - Attachments</div>
                        <div class="p-col-12 p-lg-9 p-p-0 p-d-flex p-flex-column p-flex-md-row  fieldLevelTbl"
                            *ngFor="let file of getFileList('field', 'download'); let i=index">
                            <div class=" p-col-12 p-lg-4  p-md-6" style="border-right:1px solid #ddd;">
                                <label for="firstname">{{file.groupName.split(':-')[0]}}</label>
                            </div>
                            <div class=" p-col-12 p-lg-7  p-md-5" style="border-right:1px solid #ddd;">
                                <label for="firstname">{{file.name}}</label>
                            </div>
                            <div class="p-col-12 p-lg-1  p-md-1 p-d-flex p-jc-center">
                                <i class="pi pi-download" (click)="downloadAttachment(file.url)"></i>
                            </div>

                        </div>
                    </p-card>
                </div>
            </div>

            <div class="p-col-12 p-p-0 p-pb-0">
                <div class="cardSection">
                    <p-card class="claimWorkflow">
                        <div class="tableheader">Document Repository</div>
                        <!-- <div class="p-col-12 p-pl-0">
                            <div class="tableSubheader">
                                Upload Attachments
                            </div>
                        </div> -->


                        <div class="p-col-12 p-lg-9 p-p-0 p-d-flex p-flex-column p-flex-md-row fieldLevelTbl"
                            *ngFor="let file of getFileList('claim', 'download'); let i=index">

                            <div class=" p-col-12 p-lg-11  p-md-10" style="border-right:1px solid #ddd;">
                                <label for="firstname">{{file.Image_Name}}</label>
                            </div>
                            <div class="p-col-12 p-lg-1  p-md-2  p-d-flex p-jc-center">
                                <i class="pi pi-download" (click)="download(file)"></i>
                            </div>

                        </div>
                        <div class="p-col-12 p-p-0 p-mt-4">
                            <div class="tableheader">Upload Documents</div>
                            <div class="p-col-12 p-lg-9 p-p-0 p-d-flex p-flex-column p-flex-md-row fieldLevelTbl"
                                *ngFor="let upload of uploadFiles; let i=index">
                                <div class="p-grid p-col-12">
                                    <div class="dynamicCtrlWrap">
                                        <!-- <div class=" p-col-12 p-md-3">
                                        <label for="firstname">Document type</label>

                                        <input id="fieldId" type="text" [(ngModel)]="upload.documentType" pInputText>
                                    </div> -->
                                        <!-- <div class="p-col-12 p-md-3">
                                        <label for="lastname">Document Name</label>
                                        <input id="fieldId" type="text" [(ngModel)]="upload.documentName" pInputText>
                                    </div> -->
                                        <div class="p-col-12 p-md-12 center">
                                            <label for="firstname">Browse file</label>
                                            <input type="file" id="uploadFile{{i}}"
                                                (change)="addUploadFiles($event, i)">
                                        </div>
                                        <!-- <div class="p-col-12  p-md-3">
                                        <label for="lastname">Description</label>
                                        <input id="fieldId" type="text" [(ngModel)]="upload.documentDescription"
                                            pInputText>
                                    </div> -->
                                    </div>
                                    <div class="removeWrapperDiv diagnosisRemove">
                                        <i class="pi pi-times closeIcon_T5" (click)="removeFileUpload(i)"></i>
                                    </div>



                                </div>
                            </div>
                        </div>




                        <div class="p-col-12 p-md-12 p-sm-12 p-mt-3 p-p-0 p-mb-3  p-d-flex p-jc-start">
                            <button pButton type="button" icon="pi pi-plus" iconPos="left" label="Add" icon="pi pi-plus"
                                (click)="addFiles()" class="btnCss cancelBtn p-mr-2"> </button>
                            <button pButton type="button" class="btnCss finishBtn" (click)="finalUploadFiles()"
                                label="Submit"></button>
                        </div>
                    </p-card>

                    <!-- <div class="p-grid p-jc-end">
                        <div class="p-col-12 p-md-5 p-sm-12">
                            <span class="p-buttonset">
                                <button pButton type="button" label="Previous" class="btnCss previousBtn"></button>
                                <button pButton type="button" label="Next" class="btnCss nextBtn p-mr-2"></button>
                                <button pButton type="button" label="Finish" class="btnCss finishBtn"></button>
                                <button pButton type="button" label="Cancel" class="btnCss cancelBtn"></button>
                            </span>
                        </div>
                    </div> -->
                </div>
            </div>




        </p-tabPanel>


    </p-tabView>


    <!-- <p-accordion>
                                                    <p-accordionTab class="uploadDocumentsFilter" header="Upload Documents"
                                                        [(selected)]="activeState1">
                                                        <div class="p-grid m-t-155">
                                                            <div class="cardSection">
                                                                <p-card>
                                                                    <div class="p-col-12 p-pl-0">
                                                                        <div class="tableSubheader">
                                                                            Upload Mandatory Documents
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-grid">
                                                                        <div class="dynamicCtrlWrap">
                                                                            <div class=" p-col-12 p-md-4">
                                                                                <label for="firstname">Document type</label>
                                                                                <ng-select [items]="itemsList" bindLabel="label"
                                                                                    bindValue="value" [multiple]="true"
                                                                                    [closeOnSelect]="true" [searchable]="true">
                                                                                </ng-select>
                                                                            </div>
                    
                                                                            <div class="p-col-12 p-md-4 center">
                                                                                <label for="firstname">Browse file</label>
                                                                                <ngx-file-drop dropZoneLabel="Drop files here"
                                                                                    accept=".pdf,.docx,.xlsx,.pptx" multiple="true">
                                                                                    <ng-template ngx-file-drop-content-tmp
                                                                                        let-openFileSelector="openFileSelector"
                                                                                        multiple="true">
                                                                                        <div
                                                                                            style="width:100%;height:24px;flex:3;text-align: center;margin-top:1px;color: #646565;">
                    
                                                                                        </div>
                                                                                        <div
                                                                                            style="width:100%;height:auto;flex:1;text-align: center;margin-top:0px;">
                                                                                            <button type="button">Browse Files</button>
                                                                                        </div>
                                                                                    </ng-template>
                                                                                </ngx-file-drop>
                    
                                                                            </div>
                    
                                                                            <div class="p-col-12  p-md-4">
                                                                                <label for="lastname">Description</label>
                                                                                <input id="fieldId" type="text" pInputText>
                                                                            </div>
                                                                        </div>
                    
                                                                        <div class="removeWrapperDiv diagnosisRemove">
                                                                            <i class="pi pi-times closeIcon_marginT"></i>
                                                                        </div>
                                                                    </div>
                    
                                                                    <div class="p-col-12 p-pl-0">
                                                                        <button pButton type="button" class="btnCss submitBtn"
                                                                            label="Submit"></button>
                                                                    </div>
                                                                </p-card>
                                                            </div>
                                                        </div>
                                                    </p-accordionTab>
                                                </p-accordion> -->


</p-card>

<ng-template let-registerForm="registerForm" let-fieldList="fieldList" #myFieldTempate>
    <div class="p-col-12 p-md-12 p-p-0" [ngSwitch]="registerForm.type">
        <ng-container *ngSwitchCase="'Small Text'">
            <input type="text" [(ngModel)]="registerForm.value" [disabled]="isViewOnly" pInputText
                (ngModelChange)="inputchangeEvent(registerForm, fieldList)"
                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}">
        </ng-container>
        <ng-container *ngSwitchCase="'ReadOnly Small Text'">
            <input type="text" [(ngModel)]="registerForm.value" [disabled]="isViewOnly" [readonly]="true" pInputText>
        </ng-container>
        <ng-container *ngSwitchCase="'Large Text'">
            <p-editor [(ngModel)]="registerForm.value" [style]="{'height':'320px'}"></p-editor>
        </ng-container>
        <ng-container *ngSwitchCase="'Date Time'">
            <p-calendar [(ngModel)]="registerForm.value" [disabled]="isViewOnly" showTime="true" hourFormat="24"
                appendTo="body">
            </p-calendar>
        </ng-container>
        <ng-container *ngSwitchCase="'Date'">
            <p-calendar [showIcon]=" true" appendTo="body" [(ngModel)]="registerForm.value" [disabled]="isViewOnly"
                [monthNavigator]="true" [yearNavigator]="true" dateFormat="dd/mm/yy" inputId="navigators"
                yearRange="1990:2050" (onSelect)="inputchangeEvent(registerForm, fieldList)" 
                (onInput)="inputchangeEvent(registerForm, fieldList)"
                [minDate]="getminDateValue(registerForm,fieldList)" 
                [maxDate]="getmaxDateValue(registerForm,fieldList)"
                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}" >
            </p-calendar>
        </ng-container>
        <ng-container *ngSwitchCase="'Selection'">
            <div *ngIf="registerForm.multiselect === 'false'">
                <ng-select [items]="registerForm.options" bindLabel="value" [disabled]="isViewOnly"
                    [(ngModel)]="registerForm.value" bindValue="code" [multiple]="false" [closeOnSelect]="true"
                    [searchable]="true" (change)="singleSelectChange($event, registerForm)"
                    [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}" >
                </ng-select>
            </div>
            <div *ngIf="registerForm.multiselect === 'true'">
                <ng-select [items]="registerForm.options" bindLabel="value" [disabled]="isViewOnly"
                    [(ngModel)]="registerForm.value" bindValue="code" [multiple]="true" [closeOnSelect]="false"
                    [searchable]="true" 
                    [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}" >
                </ng-select>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'Large Selection'">
            <ng-select [items]="itemsList" bindLabel="value" [(ngModel)]="registerForm.value" bindValue="code"
                [multiple]="true" [closeOnSelect]="true" [searchable]="true">
            </ng-select>
        </ng-container>
        <ng-container *ngSwitchCase="'Small Heading'">
            <h2>Test Small Heading</h2>
        </ng-container>
        <ng-container *ngSwitchCase="'Large Heading'">
            <h1>Test Large Heading</h1>
        </ng-container>
        <ng-container *ngSwitchCase="'Radio OR CheckBox'">
            <p-checkbox name="groupname" [(ngModel)]="registerForm.value" value="val1">
            </p-checkbox>

            <p-radioButton name="groupname" [(ngModel)]="registerForm.value" value="val2">
            </p-radioButton>
        </ng-container>
        <ng-container *ngSwitchCase="'Attachment'">
            <ngx-file-drop dropZoneLabel="Drop files here" class="claimdragdrop"
                (onFileDrop)="dropped($event,registerForm)" (onFileOver)="fileOver($event)"
                (onFileLeave)="fileLeave($event)" multiple="false">
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector" multiple="true">
                    <div style="width:100%;height:24px;flex:1;text-align: center;margin-top:1px;color: #646565;">
                        Drag & Drop Here</div>
                    <div style="width:100%;height:auto;flex:1;text-align: center;">
                        <button type="button" (click)="openFileSelector()">Browse</button>
                    </div>
                </ng-template>

            </ngx-file-drop>
            <tr *ngIf="getRegisterFormAttachemntName(registerForm)" style="cursor: pointer;">
                <td>
                    <a (click)="downloadAttachment( getRegisterFormAttachemntURL(registerForm) )">
                        {{ getRegisterFormAttachemntName(registerForm) }}
                    </a>
                </td>
            </tr>
            <tr *ngIf="registerForm.fileName">
                <td><strong>{{ registerForm.fileName }}</strong>
                </td>
            </tr>
        </ng-container>
        <ng-container *ngSwitchCase="'Hidden Text Field'">
            <input type="text" [(ngModel)]="registerForm.value" [disabled]="isViewOnly" type="hidden" pInputText>
        </ng-container>
        <ng-container *ngSwitchCase="'Auto Date Time'">
            <p-calendar [(ngModel)]="registerForm.value" [showTime]="true" [disabled]="isViewOnly" appendTo="body"
                inputId="time"></p-calendar>
        </ng-container>
        <ng-container *ngSwitchCase="'Auto Date'">
            <p-calendar [showIcon]=" true" [monthNavigator]="true" appendTo="body" [yearNavigator]="true"
                dateFormat="dd/mm/yy" inputId="navigators" [disabled]="isViewOnly" yearRange="1990:2050"
                [(ngModel)]="registerForm.value" (onSelect)="inputchangeEvent(registerForm, fieldList)"
                (onInput)="inputchangeEvent(registerForm, fieldList)"
                [minDate]="getminDateValue(registerForm,fieldList)" 
                [maxDate]="getmaxDateValue(registerForm,fieldList)"
                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}" >
            </p-calendar>
        </ng-container>
        <ng-container *ngSwitchCase="'Time'">
            <p-calendar [(ngModel)]="registerForm.value" appendTo="body" [timeOnly]="true" [disabled]="isViewOnly"
                inputId="timeonly">
            </p-calendar>
        </ng-container>
        <ng-container *ngSwitchCase="'MulltiMedia'">
            <input type="text" pInputText [disabled]="isViewOnly" [(ngModel)]="registerForm.value">
        </ng-container>
        <ng-container *ngSwitchCase="'Geo Location'">
            <input ngx-google-places-autocomplete #places="ngx-places" [(ngModel)]="registerForm.value"
                (onAddressChange)="handleAddressChange($event,registerForm)"
                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}" />
        </ng-container>
        <ng-container *ngSwitchCase="'Numeric Text'">
            <input pInputText type="text" pKeyFilter="pnum" [disabled]="isViewOnly" [(ngModel)]="registerForm.value"
                (ngModelChange)="inputchangeEvent(registerForm, fieldList)" 
                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}"/>
        </ng-container>
        <ng-container *ngSwitchCase="'Dates after today'">
            <p-calendar [showIcon]=" true" [monthNavigator]="true" appendTo="body" [yearNavigator]="true"
                dateFormat="dd/mm/yy" [minDate]="tomorrow" inputId="navigators" [disabled]="isViewOnly"
                [(ngModel)]="registerForm.value" yearRange="1990:2050">
            </p-calendar>
        </ng-container>
        <ng-container *ngSwitchCase="'SubJob'">
            <input type="text" pInputText [(ngModel)]="registerForm.value">
        </ng-container>
        <ng-container *ngSwitchCase="'Email'">
            <input pInputText type="email" [disabled]="isViewOnly" [(ngModel)]="registerForm.value"
                pattern="^[a-z0-9._-]@[a-z]\.[a-z]{2,4}$" required 
                [ngClass]="{'p-error' : formSubmit && registerForm.mandatory === 'true' && !registerForm.value}"/>
        </ng-container>
    </div>
</ng-template>