<div class="p-fluid">
    <!-- Log In page -->
    <div class="p-d-flex p-flex-lg-row  p-flex-column loginWrap">
        <!-- Left Container -->
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-5 p-xl-4 p-order-2 p-order-lg-1">
            <div class="card p-mb-0 shadow-none">
                <div class="card-body">
                    <div class="p-p-1 p-p-md-3">
                        <div class="media">
                            <a href="#" class="logo logo-admin" *ngIf="imageLogo">
                                <!-- <img src="../../../assets/images/logo-sm.png" height="50" alt="logo" class="p-my-3"> -->
                                <img src="{{imageLogo}}" height="50" alt="logo" class="p-my-3">
                            </a>
                            <div class="media-body p-ml-3 p-as-center">
                                <h4 class="p-mt-0 p-mb-1">Reset Password</h4>
                                <!-- <p class="text-muted p-mb-0">Enter your Email and instructions will be sent to you!</p> -->
                            </div>
                        </div>
                        <form [formGroup]="resetPasswordForm">
                        <div class="p-col-12 p-px-0 p-pt-3 p-pb-0">
                            <label>Current Password:</label>
                        </div>
                        <div class="p-col-12 p-px-0">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon"><i class="pi pi-tablet"></i></span>
                                <input type="text" pInputText type="password" formControlName="currentPassword" placeholder="Current Password">
                            </div>
                        </div>
                        <div class="p-col-12 p-px-0 p-pt-3 p-pb-0">
                            <label>New Password:</label>
                        </div>
                        <div class="p-col-12 p-px-0">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
                                <input type="text" pInputText type="password" formControlName="newPassword" placeholder="New Password">
                            </div>
                        </div>
                        <div class="p-col-12 p-px-0 p-pt-3 p-pb-0">
                            <label>Confirm Password:</label>
                        </div>
                        <div class="p-col-12 p-px-0">
                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
                                <input type="text" pInputText type="password" formControlName="confirmPassword" placeholder="Confirm Password">
                            </div>
                        </div>
                        <div class="p-col-12 p-mt-3 p-p-0">
                            <div class="p-col-12 p-p-0">
                                <button pButton pRipple type="button" icon="pi pi-sign-in" iconPos="right" (click)="resetPassword(resetPasswordForm.value)"
                                    label="Change Password"></button>
                            </div>
                        </div>
                        </form>
                        <div class="p-col-12 p-p-0">
                            <!-- <p class="p-col-12 p-mt-3 f-18 text-center">Remember It ? <a href="/login"
                                    class="text-primary">Login</a>
                            </p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Right Container -->
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-7 p-xl-8 p-p-0 p-d-flex p-jc-center p-order-1 p-order-lg-2">
            <div class="accountbg p-d-flex p-ai-center">
                <div class="account-title">
                    <h4 class="headerTxt p-mt-3">Welcome To Advanced InsurTech Solution</h4>
                    <div class="border w-25 p-mx-auto border-primary"></div>
                    <h1 class="">Let's Get Started</h1>
                    <!-- <p class="p-mt-3 f-18">Remember It ? <a href="/login" class="text-primary">Login</a></p> -->

                </div>
            </div>
        </div>
    </div>
</div>