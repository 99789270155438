import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MegaMenuItem, MenuItem } from 'primeng/api';
import { ClaimsService } from 'src/app/services/claims.service';
import { ModuleConstants as MC } from './../../module-constants';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit {

  items: MegaMenuItem[];
  opened: boolean = true;
  mobileItems: MenuItem[];
  settingsMenu: MenuItem[];
  organizationDetails: any[];
  imageLogo: any = "";
  modulesList: any[];
  selectedModule: any = { label: 'CLAIM MODULE', value: MC.CLAIM_MODULE };
  userId: any = 0;
  isSinglejobView = false;
  showLoader = false;

  constructor(private claimsService: ClaimsService, private router: Router) {}

  ngOnInit(): void {
    this.modulesList = [
      { label: 'LOSS MITIGATION', value: MC.CLAIM_MODULE },
      { label: 'LOAN CLAIM MODULE', value: MC.CLAIM_MODULE },
      { label: 'BANKRUPTCY MODULE', value: MC.CLAIM_MODULE },
      { label: 'FORECLOSURE MODULE', value: MC.CLAIM_MODULE },
      { label: 'INVOICING MODULE', value: MC.CLAIM_MODULE },
      { label: 'LOAN DISBURSEMENT MODULE', value: MC.CLAIM_MODULE },

    ];

    this.isSinglejobView = this.router.url.indexOf('singlejobView') > -1;

    this.items = [
      {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-desktop',
        routerLink: '/'+MC.CLAIM_MODULE+'/dashboard',
      },
      {
        label: 'Loss Details',
        routerLink: '/'+MC.CLAIM_MODULE+'/jobsList',
        icon: 'pi pi-fw pi-th-large',
        routerLinkActiveOptions: 'true',
      },
      {
        label: "Masters", routerLink: '/'+MC.CLAIM_MODULE+'/masters', icon: 'pi pi-fw pi-th-large', routerLinkActiveOptions: 'true',
      },
      /*{
        label: "User", routerLink: '/'+MC.CLAIM_MODULE+'/mastersUser', icon: 'pi pi-fw pi-th-large', routerLinkActiveOptions: 'true',
      },
      {
        label: "Roles", routerLink: '/'+MC.CLAIM_MODULE+'/usersRoles', icon: 'pi pi-fw pi-th-large', routerLinkActiveOptions: 'true',
      },*/
      // {
      //   label: "Configuration and Settings", routerLink: '', icon: 'pi pi-fw pi-sitemap',
      //   items: [
      //     [{
      //       label: 'Organization',
      //       items: [
      //         { label: 'Company', routerLink: '/'+MC.CLAIM_MODULE+'/masterCompany' },
      //         { label: 'Division', routerLink: '/'+MC.CLAIM_MODULE+'/masterDivision' },
      //         { label: 'Business Unit', routerLink: '/'+MC.CLAIM_MODULE+'/mastersBusinessunit' },
      //       ]
      //     },
      //     {
      //       label: 'User Administration',
      //       items: [
      //         { label: 'Users', routerLink: '/'+MC.CLAIM_MODULE+'/mastersUser' },
      //         { label: 'Roles', routerLink: '/'+MC.CLAIM_MODULE+'/usersRoles' },
      //       ]
      //     },
      //     ],
      //     [
      //       {
      //         label: 'Policy',
      //         items: [
      //           { label: 'Policy Repository', routerLink: '/'+MC.CLAIM_MODULE+'/policymastersRepository' },
      //           { label: 'Policy Numbers', routerLink: '/'+MC.CLAIM_MODULE+'/policymastersNumbers' },
      //           { label: 'Policy Types', routerLink: '/'+MC.CLAIM_MODULE+'/policymastersTypes' },
      //           { label: 'Policy Codes', routerLink: '/'+MC.CLAIM_MODULE+'/policymastersCodes' },
      //           { label: 'Policy Years', routerLink: '/'+MC.CLAIM_MODULE+'/policymastersYears' },
      //         ]
      //       },],
      //     [
      //       {
      //         label: 'Insurer',
      //         items: [
      //           { label: 'Insurer', routerLink: '/'+MC.CLAIM_MODULE+'/mastersInsurer' },
      //           { label: 'Co-Insurance Pattern', routerLink: '/'+MC.CLAIM_MODULE+'/mastersCoinsurerpattern' },
      //         ]
      //       },],
      //     [
      //       {
      //         label: 'Broker',
      //         items: [
      //           { label: 'Broker', routerLink: '/'+MC.CLAIM_MODULE+'/mastersBrokers' },
      //         ]
      //       }]

      //   ],
      // },
       {
         label: "Reports", routerLink: '/'+MC.CLAIM_MODULE+'/reports', icon: 'pi pi-fw pi-chart-line',
       },
      {
        label: 'Happy to help',
        icon: 'pi pi-fw pi-map',
        styleClass: 'menuHeading',
        items: [
          // [{
          //   label: 'Support',
          //   items: [
          //       { label: 'Chat', routerLink: '' },
          //      { label: 'User Manual', routerLink: '' },
          //      { label: 'Raise Ticket', routerLink: '' },
          //     { label: 'FAQs', routerLink: '/'+MC.CLAIM_MODULE+'/supportFaq' },
          //   ]
          // },
          // ],
          [
            {
              label: 'Configuration',
              items: [
                {
                  label: 'Emails',
                  routerLink: '/'+MC.CLAIM_MODULE+'/configurationEmails',
                },
                // { label: 'Alerts', routerLink: '' },
                // { label: 'Claim Workflow', routerLink: '' },
                // { label: 'Claim Forms', routerLink: '' },
              ],
            },
          ],
        ],
      },

      /*{
        label: "User Administration", icon: 'pi pi-fw pi-user', styleClass: 'menuHeading',
        items: [

          [
            {

              items: [
                { label: 'Users', routerLink: '/'+MC.CLAIM_MODULE+'/mastersUser' },
               { label: 'Roles', routerLink: '/'+MC.CLAIM_MODULE+'/usersRoles' },
               {
                separator:true
              },
              { label: 'Responsibility Matrix', routerLink: '' },
              ]
            },

          ]
        ]
      },*/

     ];

    this.mobileItems = [
      {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-desktop',
        routerLink: '/'+MC.CLAIM_MODULE+'/dashboard',
        routerLinkActiveOptions: 'true',
      },
      {
        label: 'Claims',
        routerLink: '/'+MC.CLAIM_MODULE+'/jobsList',
        icon: 'pi pi-fw pi-th-large',
        routerLinkActiveOptions: 'true',
      },
      {
        label: 'Masters',
        routerLink: '/'+MC.CLAIM_MODULE+'/masters',
        icon: 'pi pi-fw pi-th-large',
        routerLinkActiveOptions: 'true',
      },
      // {
      //   label: "Configuration and Settings", routerLink: '', icon: 'pi pi-fw pi-sitemap',
      //   items: [
      //     {
      //       label: 'Organization',
      //       items: [
      //         { label: 'Company', routerLink: '/'+MC.CLAIM_MODULE+'/masterCompany' },
      //         { label: 'Division', routerLink: '/'+MC.CLAIM_MODULE+'/masterDivision' },
      //         { label: 'Business Unit', routerLink: '/'+MC.CLAIM_MODULE+'/mastersBusinessunit' },
      //       ]
      //     },
      //     {
      //       label: 'User Administration',
      //       items: [
      //         { label: 'Users', routerLink: '/'+MC.CLAIM_MODULE+'/mastersBrokers' },
      //         { label: 'Roles', routerLink: '/'+MC.CLAIM_MODULE+'/usersRoles' },
      //       ]
      //     },

      //     {
      //       label: 'Policy',
      //       items: [
      //         { label: 'Policy Repository', routerLink: '/'+MC.CLAIM_MODULE+'/policymastersRepository' },
      //         { label: 'Policy Numbers', routerLink: '/'+MC.CLAIM_MODULE+'/policymastersNumbers' },
      //         { label: 'Policy Types', routerLink: '/'+MC.CLAIM_MODULE+'/policymastersTypes' },
      //         { label: 'Policy Codes', routerLink: '/'+MC.CLAIM_MODULE+'/policymastersCodes' },
      //         { label: 'Policy Years', routerLink: '/'+MC.CLAIM_MODULE+'/policymastersYears' },
      //       ]
      //     },
      //     {
      //       label: 'Insurer',
      //       items: [
      //         { label: 'Insurer', routerLink: '/'+MC.CLAIM_MODULE+'/mastersInsurer' },
      //         { label: 'Co-Insurance Pattern', routerLink: '/'+MC.CLAIM_MODULE+'/mastersCoinsurerpattern' },
      //       ]
      //     },
      //     {
      //       label: 'Broker',
      //       items: [
      //         { label: 'Broker', routerLink: '/'+MC.CLAIM_MODULE+'/mastersBrokers' },
      //       ]
      //     }],
      // },
      // {
      //   label: "Reports", routerLink: '/'+MC.CLAIM_MODULE+'/reports', icon: 'pi pi-fw pi-chart-line',
      // },
      {
        label: 'Happy to help',
        icon: 'pi pi-fw pi-map',
        routerLink: '',
        items: [
          // {
          //   label: 'Support',
          //   items: [
          //      { label: 'Chat', routerLink: '' },
          //      { label: 'User Manual', routerLink: '' },
          //      { label: 'Raise Ticket', routerLink: '' },
          //     { label: 'FAQs', routerLink: '/'+MC.CLAIM_MODULE+'/supportFaq' },
          //   ]
          // },

          {
            label: 'Configuration',
            items: [
              {
                label: 'Emails',
                routerLink: '/'+MC.CLAIM_MODULE+'/configurationEmails',
              },
              // { label: 'Alerts', routerLink: '' },
              // { label: 'Claim Workflow', routerLink: '' },
              // { label: 'Claim Forms', routerLink: '' },
            ],
          },
        ],
      },

      /*  {
        label: "User Administration", icon: 'pi pi-fw pi-user', routerLink: '',

            items: [
              { label: 'Users', routerLink: '/'+MC.CLAIM_MODULE+'/mastersUser' },
               { label: 'Roles', routerLink: '/'+MC.CLAIM_MODULE+'/usersRoles' },
               {
                separator:true
              },
              { label: 'Responsibility Matrix', routerLink: '' },
            ]
          },*/
    ];

    this.settingsMenu = [
      {
        label: 'Profile',
        icon: 'pi pi-user',
      },
      {
        label: 'Settings',
        icon: 'pi pi-cog',
      },
      {
        label: 'Logout',
        icon: 'pi pi-sign-out',
        command: (event) => {
          this.claimsService.logout();
        },
      },
    ];

    this.userId = localStorage.getItem('loginUserId');
    this.claimsService.loader.subscribe((val) => {
      this.showLoader = val ? true : false;
    });
    this.getSingleMallDetails();
    this.getDashboardMenuItems();
  }

  onModuleChange(event) {
    var value = event.value;
    if (value.label === 'PORTFOLIO MODULE') {
      this.router.navigate(['/'+MC.PORTFOLIO_MODULE+'/dashboard']);
      // window.location.href = 'https://arcelormittal.insuranceportfolio.in/ip/admin/';
    }
  }

  getSingleMallDetails() {
    this.claimsService.getSingleMallDetails().subscribe(
      (res) => {
        console.log('res organizationDetails', res);

        if (res['status'] == 1) {
          this.organizationDetails = res['orgs'];

          this.imageLogo = this.organizationDetails[0].logo;
          if (
            this.imageLogo ===
            'https://seimens.oss-ap-south-1.aliyuncs.com/3/users/images/3_1612344064313.png'
          ) {
            this.imageLogo = '';
          }

          console.log('organizationDetails', this.organizationDetails);
        }
      },
      (error: any) => console.log(error)
    );
  }

  getDashboardMenuItems() {
    console.log('userId::', this.userId);
    var inputData = {
      userId: Number(this.userId),
    };
    this.claimsService.getDashboardMenuItems(inputData).subscribe(
      (res) => {
        var navItems = res['NavItems'];
        console.log('dashboard res', JSON.stringify(navItems));

        var navigationList = [];

        for (var i = 0; i < navItems.length; i++) {
          var mainNavObj = {
            label: navItems[i].name,
            order: navItems[i].order,
            navId: navItems[i].navId,
            routerLink: '',
            icon: '',
            templateType: navItems[i].templateType,
            items: [],
          };

          if (navItems[i].name.includes('Dashboard')) {
            mainNavObj.routerLink = '/'+MC.CLAIM_MODULE+'/dashboard';
          } else if (navItems[i].name.includes('Claims')) {
            mainNavObj.routerLink = '/'+MC.CLAIM_MODULE+'/jobsList';
          }

          console.log('mainNavObj', mainNavObj);

          if (
            navItems[i].subNavigation &&
            navItems[i].subNavigation.length != 0
          ) {
            var subNavList = [];

            navItems[i].subNavigation.forEach((element) => {
              var dupSubNavList = subNavList.filter(
                (s) => s === element.navGroupName
              );

              if (dupSubNavList.length == 0) {
                subNavList.push(element.navGroupName);
              }
            });

            subNavList.filter(
              (value, index) => subNavList.indexOf(value) === index
            );

            var subNavigationItems = [];

            for (var j = 0; j < subNavList.length; j++) {
              var subNavObj = {
                label: subNavList[j],
                items: [],
              };

              var subNav = [];

              var subNavItems = navItems[i].subNavigation.filter(
                (s) => s.navGroupName === subNavList[j]
              );

              for (var k = 0; k < subNavItems.length; k++) {
                var obj = {
                  label: subNavItems[k].name,
                  order: subNavItems[k].order,
                  navId: subNavItems[k].navId,
                  routerLink: '',
                  templateType: subNavItems[k].templateType,
                };

                subNav.push(obj);
              }
              subNavObj['items'] = subNav;
              subNavigationItems.push(subNavObj);
            }
            mainNavObj['items'] = subNavigationItems;
          }

          navigationList.push(mainNavObj);
        }

        //this.items = navigationList;

        console.log('navigationList', JSON.stringify(navigationList));
      },
      (error: any) => console.log(error)
    );
  }
}
