import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PortfolioManagementRoutingModule } from './portfolio-management-routing.module';
import { MasterComponent } from './master/master.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import { DashboardComponent } from './dashboard/dashboard.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import { PrimengImportsModule } from 'src/app/shared/primeng-imports/primeng-imports.module';
import { RegisterClaimComponent } from './register-claim/register-claim.component';
import { ViewClaimComponent } from './view-claim/view-claim.component';
import { ConfigurationEmailsComponent } from './configuration-emails/configuration-emails.component';
import { UsersRolesComponent } from './users-roles/users-roles.component';
import { MastersUserComponent } from './masters-user/masters-user.component';
import { ReportsComponent } from './reports/reports.component';
import { SupportFaqComponent } from './support-faq/support-faq.component';
import { ConfEmailbasedjobupdateComponent } from './conf-emailbasedjobupdate/conf-emailbasedjobupdate.component';
import { AddRowDirective } from './add-row.directive';
import { SupportChatComponent } from './support-chat/support-chat.component';
import { UsersRolesauthorizationComponent } from './users-rolesauthorization/users-rolesauthorization.component';
import { AddnewEmailTemplateComponent } from './addnew-email-template/addnew-email-template.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MainMasterComponent } from './main-master/main-master.component';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  declarations: [MasterComponent, AddRowDirective, DashboardComponent, UsersRolesComponent, MastersUserComponent, ReportsComponent,ConfigurationEmailsComponent, SupportFaqComponent, ConfEmailbasedjobupdateComponent, SupportChatComponent, UsersRolesauthorizationComponent, AddnewEmailTemplateComponent, MainMasterComponent,RegisterClaimComponent,ViewClaimComponent],
  imports: [
    CommonModule,
    PrimengImportsModule,
    PortfolioManagementRoutingModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,    
    NgxFileDropModule,
    GooglePlaceModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    DropdownModule,
    ProgressSpinnerModule
  ],
  exports: [MasterComponent, AddRowDirective,ConfigurationEmailsComponent, DashboardComponent, UsersRolesComponent, MastersUserComponent, ReportsComponent, SupportFaqComponent, ConfEmailbasedjobupdateComponent,RegisterClaimComponent,ViewClaimComponent],
})
export class PortfolioManagementModule { }
