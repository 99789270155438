import { Input, ɵCompiler_compileModuleSync__POST_R3__ } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimsService } from '../../../services/claims.service';
import { DatePipe } from '@angular/common';
import { ModuleConstants as MC } from "../../module-constants";

@Component({
  selector: 'app-view-claim',
  templateUrl: './view-claim.component.html',
  styleUrls: ['./view-claim.component.scss'],
})
export class ViewClaimComponent implements OnInit {
  displayBasic: boolean;
  activeState: boolean = false;
  claims: any[];
  selectedClaim: any;
  claimsList: any[];
  value: Date;
  fields: any;
  selectedField: any;
  clonedClaimsLst: any;
  columns: any[];
  selectedColumn: any[];
  first: number = 0;
  globalFilters: any = [];
  selectedColumnsList: any[];
  columnsList: any[];
  selectedClaimType: string = '';
  claimTypeLabel: string = '';
  jobType: any = '';
  jobStatus: any = '';
  fieldsList: any[];
  rolesAccessData: any;
  searchFieldsList: any[];
  conditionsList: any[];
  searchForm: FormGroup;
  columnsForTable: any = [];
  exportName = 'Claims';
  currentGridType = '';
  defaultSelectedClaimType;
  selectedStatus = '';
  specialFilersList = [];
  specialSelectedValues = [];
  textconditionsList = [
    { label: 'is', value: 'is' },
    { label: 'contains', value: 'contains' },
  ];
  numericconditionsList = [
    { label: 'is', value: 'is' },
    { label: 'is less than', value: 'is_less_than' },
    { label: 'is greater than', value: 'is_greater_than' },
    { label: 'between', value: 'between' },
  ];
  dateconditionsList = [
    { label: 'is', value: 'is' },
    { label: 'is less than', value: 'is_less_than' },
    { label: 'is greater than', value: 'is_greater_than' },
    { label: 'between', value: 'date_between' },
  ];
  showAdd = false;
  showEdit = false;
  showView = false;
  showDelete = false;
  showBulkUpload = false;
  constructor(
    private router: Router,
    private claimsService: ClaimsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    console.log('on inti of view');
    this.route.fragment.subscribe((fragment: string) => {
      this.defaultSelectedClaimType = fragment;
    });

    console.log(
      'this.defaultSelectedClaimType ::',
      this.defaultSelectedClaimType
    );
    this.selectedStatus = this.route.snapshot.paramMap.get('selectedStatus');
    this.fields = [
      { name: 'Dates', code: 'dt' },
      { name: 'Claim No', code: 'nm' },
      { name: 'Net Premium', code: 'np' },
    ];

    this.columnsForTable = [
      {
        field: 'Claim_no',
        header: 'Claim No.',
      },
      {
        field: 'Insurer_name',
        header: 'Insured',
      },
      {
        field: 'Date_of_loss',
        header: 'Date of Loss',
      },
      {
        field: '',
        header: 'Intimation from Insured',
      },
      {
        field: 'Brief Description Of loss',
        header: 'Cause of Loss',
      },
      {
        field: 'Claim Type',
        header: 'Claim Type',
      },
      {
        field: 'Loss_estimate_Or_Claim_amount',
        header: 'Intimated Loss Amount(INR)',
      },
      {
        field: 'Claim_status',
        header: 'Claim Status',
      },
    ];

    this.getUserAccessDetails();
    //this.getClaimTypes();
  }

  getUserAccessDetails() {
    let userId = localStorage.getItem('loginUserId');

    this.claimsService.getUserAccessDetails(userId).subscribe((res) => {
      this.rolesAccessData = res;

      console.log('this.rolesAccessData::', this.rolesAccessData);

      this.getClaimTypes();
    });
  }

  initializeForm() {
    this.searchForm = this.fb.group({
      quantities: this.fb.array([]),
    });
  }

  quantities(): FormArray {
    return this.searchForm.get('quantities') as FormArray;
  }

  newSearchForm(): FormGroup {
    return this.fb.group({
      fieldName: [null],
      condition: [null],
      comparision: [null],
    });
  }

  showBasicDialog() {
    this.displayBasic = true;
  }

  registerClaim() {
    var selectedClaim = this.selectedClaimType;
    this.router.navigate(['/'+MC.CLAIM_MODULE+'/registerJob', selectedClaim]);
  }

  onRowEditInit(claimsrow: any) {
    if (
      this.claimsList.filter((row) => row.isEditable && row.isUpdate == false)
        .length > 0
    ) {
      this.claimsList.splice(0, 1);
    }

    if (this.claimsList.filter((row) => row.isEditable).length > 0) {
      const index = this.claimsList.findIndex(
        (data) => data['id'] == this.clonedClaimsLst.id
      );
      this.claimsList[index] = this.clonedClaimsLst;
    }

    this.claimsList
      .filter((row) => row.isEditable)
      .map((r) => {
        r.isEditable = false;
        return r;
      });
    claimsrow.isEditable = true;

    this.clonedClaimsLst = { ...claimsrow };
  }

  onRowEditCancel(claims: any, index: number) {
    if (!claims.isUpdate) {
      this.claimsList.splice(index, 1);
    } else {
      let clndCGA = this.clonedClaimsLst;
      clndCGA.isEditable = false;
      this.claimsList[index] = clndCGA;
    }
  }
  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  onRowEditSave(claims: any) {}
  addQuantity() {
    var rString = this.randomString(
      10,
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    );

    this.specialFilersList.push({
      id: rString,
      fieldName: '',
      selectedfieldValue: '',
      selectedCondition: '',
      comparisionvalue: '',
      conditionsList: [],
      startDate: null,
      endDate: null,
      selectedComparision: 1,
      selectedComparisionList: [],
      selectedBetweenComparision: 0,
      selectedBetweenShowDate: 0,
      selectedFromValue: '',
      selectedToValue: '',
    });
    // this.quantities().push(this.newSearchForm());
  }

  removeQuantity(i: number) {
    //  this.quantities().removeAt(i);

    this.specialFilersList.splice(i, 1);
    this.disablevalues();
  }

  clearSpecialFilters() {
    this.specialFilersList = [];
    this.getClaimTypes();
  }
  submitSpecialFilters() {
    let finalJson = [];
    let sendforsubmit = 1;
    console.log('this.specialFilersList::', this.specialFilersList);
    if (this.specialFilersList.length > 0) {
      this.specialFilersList.forEach((eachFilter) => {
        console.log(' ach filet::', eachFilter);
        let fieldname = eachFilter['selectedfieldValue']['name'];
        let condition = eachFilter['selectedCondition']['value'];

        let value = '';
        let value2 = '';
        let addvalue2 = 0;
        if (condition == 'between' || condition == 'date_between') {
          addvalue2 = 1;
          if (
            eachFilter['selectedfieldValue']['type']
              .toLowerCase()
              .indexOf('date') >= 0
          ) {
            value = this.datePipe.transform(
              eachFilter['selectedFromValue'],
              'dd/MM/yyyy'
            );

            // value2 = eachFilter['selectedToValue'];

            value2 = this.datePipe.transform(
              eachFilter['selectedToValue'],
              'dd/MM/yyyy'
            );
          } else {
            if (
              eachFilter['selectedfieldValue']['type']
                .toLowerCase()
                .indexOf('selection') >= 0
            ) {
              value = eachFilter['selectedFromValue']['value1'];

              // value2 = eachFilter['selectedToValue'];

              value2 = eachFilter['selectedToValue']['value1'];
            } else {
              value = eachFilter['selectedFromValue'];

              value2 = eachFilter['selectedToValue'];
            }
          }
        } else {
          if (
            eachFilter['selectedfieldValue']['type']
              .toLowerCase()
              .indexOf('date') >= 0
          ) {
            value = this.datePipe.transform(
              eachFilter['comparisionvalue'],
              'dd/MM/yyyy'
            );
          } else {
            if (
              eachFilter['selectedfieldValue']['type']
                .toLowerCase()
                .indexOf('selection') >= 0
            ) {
              value = eachFilter['comparisionvalue']['value1'];
            } else {
              value = eachFilter['comparisionvalue'];
            }
          }
        }

        if (addvalue2) {
          if (
            fieldname == null ||
            fieldname == undefined ||
            fieldname == '' ||
            condition == null ||
            condition == undefined ||
            condition == '' ||
            value2 == null ||
            value2 == undefined ||
            value2 == '' ||
            value == null ||
            value == undefined ||
            value == ''
          ) {
            sendforsubmit = 0;
          }
          finalJson.push({
            fieldname: fieldname,
            condition: condition,
            value2: value2,
            value: value,
          });
        } else {
          if (
            fieldname == null ||
            fieldname == undefined ||
            fieldname == '' ||
            condition == null ||
            condition == undefined ||
            condition == '' ||
            value == null ||
            value == undefined ||
            value == ''
          ) {
            sendforsubmit = 0;
          }
          finalJson.push({
            fieldname: fieldname,
            condition: condition,
            value: value,
          });
        }
      });
      if (sendforsubmit == 1) {
        this.claimsList = [];

        var inputData = {
          jobTypeId: this.selectedClaim.value,
          filters: finalJson,
          jobLabel: this.selectedClaim.label,
        };
        this.claimsService.claimsSpecialFilterSearch(inputData).subscribe(
          (res) => {
            if (res['status'] == 1) {
              this.claimsList = this.trimJobIdFromSelectorValues(res['jobs']);
              if (this.selectedStatus) {
                this.claimsList = this.claimsList.filter((list) => {
                  return this.selectedStatus === list.Current_Job_Status;
                });
              }
              console.log('this.claimsList::', this.claimsList);
            }
          },
          (error: any) => {
            console.log(error);
          }
        );
      } else {
        alert('Please Enter All Values');
      }
      console.log('finalJson::', finalJson);
    }
  }

  onChange(event, sfl) {
    console.log('event::', event.value);
    console.log('sfl::', sfl);
    console.log('event.value.value ::', event.value.value);
    if (event.value.value == 'between' || event.value.value == 'date_between') {
      console.log('in if ');
      sfl['selectedBetweenComparision'] = 1;
    } else {
      console.log('in else ');
      sfl['selectedBetweenComparision'] = 0;
    }
    console.log('sfl2::', sfl);
    /*var fieldDate = quantity.value.fieldName.name;


    if (event.value.label === 'is between') {
      if (fieldDate.toLowerCase().indexOf('date') !== -1) {
        this.datesFlag = true;
      } else {
        this.datesFlag = false;
      }
    } else {
      this.datesFlag = false;
    }*/
  }
  disablevalues() {
    this.searchFieldsList.forEach((element) => {
      element['disabled'] = false;
    });
    this.specialSelectedValues = [];
    this.specialFilersList.forEach((element) => {
      if (element['selectedfieldValue']['name'] != '') {
        this.specialSelectedValues.push(element['selectedfieldValue']['name']);
      }
    });
    this.specialSelectedValues.forEach((selvalue) => {
      this.searchFieldsList.forEach((element) => {
        if (selvalue == element.label) {
          element['disabled'] = true;
        }
      });
    });
  }
  checkClaimExist(claimLable) {
    let jobtypes = this.rolesAccessData['JobTypes'];
    let isExist = false;
    jobtypes.forEach((element) => {
      if (element['name'] == claimLable) {
        isExist = true;
      }
    });
    return isExist;
    //this.
  }

  checkAddEditDeleteAccess(claimLable) {
    let jobtypes = this.rolesAccessData['JobTypes'];
    let isExist = false;

    const selectedJobType = jobtypes.filter(
      (jobtype) => jobtype.name === claimLable
    );
    console.log('selectedJobType::', selectedJobType);

    if (
      selectedJobType.length > 0 &&
      selectedJobType[0]['fullAccess'] == true
    ) {
      this.showAdd = true;
      this.showEdit = true;
      this.showDelete = true;
      this.showView = true;
      this.showBulkUpload = true;
    } else {
      this.showAdd = selectedJobType[0]['permissions']['add'];
      this.showEdit = selectedJobType[0]['permissions']['edit'];
      this.showDelete = selectedJobType[0]['permissions']['delete'];
      this.showView = selectedJobType[0]['permissions']['view'];
      this.showBulkUpload = selectedJobType[0]['permissions']['bulkUpload'];
    }
  }

  onfieldChange(event, sfl, index) {
    this.disablevalues();
    console.log('sfl::', index);
    console.log('event value::', event.value);
    /*  console.log('text::', event.value['type'].indexOf('Text'));
    console.log('date::', event.value['type'].indexOf('Date'));
    console.log('numeric::', event.value['type'].indexOf('Numeric'));
    console.log('Selection::', event.value['type'].indexOf('Selection'));*/

    if (event.value['type'].toLowerCase().indexOf('text') >= 0) {
      console.log('text');
      this.specialFilersList[index]['conditionsList'] = this.textconditionsList;
      this.specialFilersList[index]['selectedComparision'] = 2;
      this.specialFilersList[index]['selectedComparisionList'] = [];
    }
    if (event.value['type'].toLowerCase().indexOf('date') >= 0) {
      this.specialFilersList[index]['conditionsList'] = this.dateconditionsList;
      console.log('date');
      this.specialFilersList[index]['selectedComparision'] = 3;
      this.specialFilersList[index]['selectedComparisionList'] = [];
    }
    if (event.value['type'].toLowerCase().indexOf('numeric') >= 0) {
      this.specialFilersList[index]['conditionsList'] =
        this.numericconditionsList;
      console.log('numeric');
      this.specialFilersList[index]['selectedComparision'] = 2;
      this.specialFilersList[index]['selectedComparisionList'] = [];
    }

    if (event.value['type'].toLowerCase().indexOf('selection') >= 0) {
      this.specialFilersList[index]['conditionsList'] = this.textconditionsList;
      let avr = event.value['allowedValuesResults'];
      console.log(event.value['allowedValuesResults']);
      console.log('Selection');

      var result = Object.keys(avr).map((key) => [key, avr[key]]);
      console.log('result', JSON.stringify(result));

      let listarray = [];
      for (var k = 0; k < result.length; k++) {
        var kObj = {
          value: result[k][0].replace(/[(][0-9]{4}[)]$/gi, ''),
          value1: result[k][0],
          code: result[k][1],
        };
        listarray.push(kObj);
      }
      this.specialFilersList[index]['selectedComparision'] = 1;
      this.specialFilersList[index]['selectedComparisionList'] = listarray;
      //   listarray = this.trimJobIdFromSelectorValues(listarray);
      console.log('listarray::', this.specialFilersList);
    }
    //
    console.log('specialFilersList::', this.specialFilersList);
  }

  getClaimTypes() {
    this.claimsService.loader.next(true);
    this.claimsService.getClaimTypes(MC.CLAIM_MODULE_VALUE).subscribe(
      (res) => {
        if (res['status'] == 1) {
          console.log('res.jobs::', res?.jobs);
          let claimsArray = [];
          res?.jobs?.forEach((element) => {

              //Rama : TODO for Access Filters
            //if (this.checkClaimExist(element.Name))
            {
              claimsArray.push({
                label: element.Name,
                value: element.typeID,
              });
            }
          });
          if (this.defaultSelectedClaimType) {
            this.selectedClaimType = this.defaultSelectedClaimType;
            claimsArray.forEach((element) => {
              if (element.label == this.selectedClaimType) {
                this.selectedClaim = element;
              }
            });
          } else {
            this.selectedClaimType = claimsArray[0].label;
            this.selectedClaim = claimsArray[0];
          }

          this.checkAddEditDeleteAccess(this.selectedClaimType);

          if (this.defaultSelectedClaimType) {
            const selectedClaim = claimsArray.filter(
              (claim) => claim.label === this.defaultSelectedClaimType
            );
            if (selectedClaim && selectedClaim.length > 0) {
              this.selectedClaim = selectedClaim[0];
              this.selectedClaimType = this.defaultSelectedClaimType;
            }
          }
          this.claims = claimsArray;
          this.claimsService.loader.next(false);
          this.getAllMasterTypeJobsList(1);
        }
      },
      (error: any) => console.log(error)
    );
  }

  saveSelectedColumns() {
    localStorage.setItem(
      this.currentGridType,
      JSON.stringify(this.selectedColumnsList)
    );
  }

  getAllMasterTypeJobsList(type) {
    localStorage.removeItem('claimStatus');

    this.columns = [];
    console.log('getAllMasterTypeJobsList input::::', this.selectedClaimType);
    var inputData = {
      label: this.selectedClaimType,
    };
    this.claimsService.loader.next(true);
    this.claimsService.getAllMasterTypeJobsList(inputData).subscribe(
      (res) => {
        this.claimsService.loader.next(false);
        if (res['status'] == 1) {
          var result = res['orgs'];
          this.fieldsList = result[0].Fields;

          this.searchFieldsList = result[0].Fields.filter(
            (j) => j.isSearchable === true
          );

          this.searchFieldsList.forEach((element) => {
            element['disabled'] = false;
            element['label'] = element['name'];
            element['value'] = element;
          });

          this.currentGridType = result[0].type;

          const jobStatus = 'Current_Job_Status';
          this.globalFilters.push(jobStatus);
          this.columns.push({
            field: jobStatus,
            header: 'Status',
            type: 'Status',
          });

          this.fieldsList.forEach((element) => {
            let fieldObj = {
              field: element.name,
              type: element.type,
              header: element.name.replace(/_/g, ' '),
            };
            this.globalFilters.push(element.name);
            this.columns.push(fieldObj);
          });
          const selectedColumnsList = JSON.parse(
            localStorage.getItem(this.currentGridType)
          );
          if (selectedColumnsList && selectedColumnsList.length > 0) {
            this.selectedColumnsList = selectedColumnsList;
          } else {
            const prorityCols = [
              {
                field: jobStatus,
                header: 'Status',
                type: 'Status',
              },
            ];
            this.selectedColumnsList = this.fieldsList
              .filter((col) => col?.showInListView)
              .map((col) => {
                return {
                  field: col.name,
                  type: col.type,
                  header: col.name.replace(/_/g, ' '),
                };
              });
            this.selectedColumnsList = [
              ...prorityCols,
              ...this.selectedColumnsList,
            ];
          }
          this.columnsList = this.columns;
          console.log('columns', JSON.stringify(this.columns));

          if (type == 1) {
            this.getMasterClaimTypes();
          }
        }
      },
      (error: any) => console.log(error)
    );
  }

  onClaimsChange() {
    console.log('this.selectedClaim::', this.selectedClaim);
    this.claimsList = [];
    this.selectedClaimType = this.selectedClaim.label;
    window.location.hash = this.selectedClaim.label;
    var inputData = {
      label: this.selectedClaimType,
    };
    this.getAllMasterTypeJobsList(2);
    this.checkAddEditDeleteAccess(this.selectedClaim.label);
    this.claimsService.getMasterClaimTypes(inputData).subscribe(
      (res) => {
        if (res['status'] == 1) {
          this.claimsList = this.trimJobIdFromSelectorValues(res.jobs);
          if (this.selectedStatus) {
            this.claimsList = this.claimsList.filter((list) => {
              return this.selectedStatus === list.Current_Job_Status;
            });
          }
          this.claimsList = this.claimsList.map(claim => {
            this.columns.map(col => {
              //Added to replace HTML tags (VJ 31-08-2021)
              if(claim[col.field] && ['Large Text'].includes(col.type) && typeof(claim[col.field]) === 'string')
                claim[col.field] = claim[col.field].replace( /(<([^>]+)>)/ig, '');
              //Truncate text and add elipses & add tooltip (VJ 31-08-2021)
              if(claim[col.field] && ['Large Text','Small Text'].includes(col.type) && typeof(claim[col.field]) === 'string' && claim[col.field].length > MC.DISPLAY_TEXT_MAX_LENGTH){
                claim[col.field+'tooltip'] = claim[col.field];
                claim[col.field] = claim[col.field].substr(0,MC.DISPLAY_TEXT_MAX_LENGTH)+'...';
              }
            })
            return claim;
          })
          console.log('this.claimsList::', this.claimsList);
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  getMasterClaimTypes() {
    this.claimsList = [];
    var inputData = {
      label: this.selectedClaimType,
    };
    this.getAllMasterTypeJobsList(2);
    this.claimsService.loader.next(true);
    this.claimsService.getMasterClaimTypes(inputData).subscribe(
      (res) => {
        this.claimsService.loader.next(false);
        if (res['status'] == 1) {
          this.claimsList = this.trimJobIdFromSelectorValues(res.jobs);
          if (this.selectedStatus) {
            this.claimsList = this.claimsList.filter((list) => {
              return this.selectedStatus === list.Current_Job_Status;
            });
          }
          this.claimsList = this.claimsList.map(claim => {
            this.columns.map(col => {
              //Added to replace HTML tags (VJ 31-08-2021)
              if(claim[col.field] && ['Large Text'].includes(col.type) && typeof(claim[col.field]) === 'string')
                claim[col.field] = claim[col.field].replace( /(<([^>]+)>)/ig, '');
              //Truncate text and add elipses & add tooltip (VJ 31-08-2021)
              if(claim[col.field] && ['Large Text','Small Text'].includes(col.type) && typeof(claim[col.field]) === 'string' && claim[col.field].length > MC.DISPLAY_TEXT_MAX_LENGTH){
                claim[col.field+'tooltip'] = claim[col.field];
                claim[col.field] = claim[col.field].substr(0,MC.DISPLAY_TEXT_MAX_LENGTH)+'...';
              }
            })
            return claim;
          })
          console.log('this.claimsList1::', this.claimsList);
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  trimJobIdFromSelectorValues(jobs) {
    return jobs.map((list) => {
      Object.keys(list).forEach((l) => {
        list[l] =
          typeof list[l] === 'string'
            ? list[l].replace(/[(][0-9]{4}[)]$/gi, '')
            : list[l];
      });
      return list;
    });
  }

  goToRegisterClaim(claimId, mode) {
    if (claimId) {
      this.router.navigate([
        '/'+MC.CLAIM_MODULE+'/editJob',
        this.selectedClaimType,
        claimId,
        mode,
      ]);
    }
  }

  deleteJob(claimId) {
    var inputData = {
      jobId: claimId,
    };
    if (!confirm('Are you sure you want to delete this job?')) {
      return;
    } else {
      this.claimsService.deleteMainJob(inputData).subscribe(
        (res) => {
          var result = res['myHashMap'];
          if (result['status'] == 1) {
            alert(result['success']);
            this.getClaimTypes();
          } else {
            alert(res['error']);
          }
        },
        (error: any) => console.log(error)
      );
    }
  }
}
