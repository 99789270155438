import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  Claims: any[];
  selectedClaim: any;
  activeState: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
