import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth/auth.guard';
import { AddRolesComponent } from './add-roles/add-roles.component';
import { AddUsersComponent } from './add-users/add-users.component';
import { AddnewEmailTemplateComponent } from './addnew-email-template/addnew-email-template.component';
import { ConfEmailbasedjobupdateComponent } from './conf-emailbasedjobupdate/conf-emailbasedjobupdate.component';
import { ConfigurationEmailsComponent } from './configuration-emails/configuration-emails.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MainMasterComponent } from './main-master/main-master.component';
import { MasterComponent } from './master/master.component';
import { MastersUserComponent } from './masters-user/masters-user.component';
import { RegisterClaimComponent } from './register-claim/register-claim.component';
import { ReportsComponent } from './reports/reports.component';
import { SupportChatComponent } from './support-chat/support-chat.component';
import { SupportFaqComponent } from './support-faq/support-faq.component';
import { UsersRolesComponent } from './users-roles/users-roles.component';
import { UsersRolesauthorizationComponent } from './users-rolesauthorization/users-rolesauthorization.component';
import { ViewClaimComponent } from './view-claim/view-claim.component';
import { ModuleConstants as MC } from "../module-constants";

const routes: Routes = [
  { path: MC.CLAIM_MODULE,
    component: MasterComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'registerJob/:selectedClaim', component: RegisterClaimComponent },
      { path: 'editJob/:selectedClaim/:claimId/:mode', component: RegisterClaimComponent },
      { path: 'editJob/singlejobView/:selectedClaim/:claimId/:mode', component: RegisterClaimComponent },
      { path: 'jobsList', component: ViewClaimComponent },
      { path: 'masters', component: MainMasterComponent },
      { path: 'configurationEmails', component: ConfigurationEmailsComponent },
      { path: 'registerJob/EmailNotificationTemplates', component: ConfigurationEmailsComponent },
      { path: 'usersRoles', component: UsersRolesComponent },
      {path:'addRoles', component: AddRolesComponent},
      { path: 'mastersUser', component: MastersUserComponent },
      {path: 'addUser', component: AddUsersComponent},
      { path: 'reports', component: ReportsComponent },
      { path: 'supportFaq', component: SupportFaqComponent },
      { path: 'conf_EmailbasedJobUpdate', component: ConfEmailbasedjobupdateComponent },
      { path: 'supportChat', component: SupportChatComponent },
      { path: 'usersRolesAuthorization', component: UsersRolesauthorizationComponent },
      { path: 'addNewEmailTemplate', component: AddnewEmailTemplateComponent },
      //{ path:'**', component: PageNotFoundComponent }
      
    ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClaimsModuleRoutingModule { }
