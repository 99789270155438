<div class="p-fluid">
    <!-- Log In page -->
    <div class="p-d-flex p-flex-lg-row  p-flex-column loginWrap">
        <!-- Left Container -->
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-5 p-xl-4 p-order-2 p-order-lg-1">
            <div class="card p-mb-0 shadow-none">
                <div class="card-body">
                    <div class="p-p-1 p-p-md-3">
                        <div class="media">
                            <a href="#" class="logo logo-admin" *ngIf="imageLogo">
                                <!-- <img src="../../../assets/images/logo-sm.png" height="50" alt="logo" class="p-my-3"> -->
                                <img src="{{imageLogo}}" height="50" alt="logo" class="p-my-3">
                            </a>
                            <div class="media-body p-ml-3 p-as-center">
                                <h4 class="p-mt-0 p-mb-1">Login - InsurTech Modules</h4>
                                <p class="text-muted p-mb-0">Enhaced Digital Experience</p>
                            </div>
                        </div>

                        <form [formGroup]="loginForm" style="width: 100%;" (ngSubmit)="tryLogin(loginForm.value)">
                            <div class="p-col-12 p-px-0 p-pt-3 p-pb-0">
                                <label>Username</label>
                            </div>
                            <div class="p-col-12 p-px-0">
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
                                    <input type="text" pInputText Enter formControlName="email"
                                        placeholder="Enter Username"
                                        [class.is-invalid]="submitted1 || (p.email.invalid && p.email.touched)"
                                        autocomplete="email">
                                </div>
                                <div *ngIf="submitted1 || (p.email.invalid && p.email.touched)"
                                    class="invalidFeedback marT10">
                                    <div *ngIf="p.email.errors?.required" style="color: red;">Please enter Username
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-px-0 p-pt-3 p-pb-0">
                                <label>Password</label>
                            </div>
                            <div class="p-col-12 p-px-0">
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
                                    <input type="text" pInputText type="password" formControlName="password"
                                        placeholder="Enter Password"
                                        [class.is-invalid]="submitted1 || (p.password.invalid && p.password.touched)"
                                        autocomplete="password">
                                </div>
                                <div *ngIf="submitted1 || (p.password.invalid && p.password.touched)"
                                    class="invalidFeedback marT10">
                                    <div *ngIf="p.password.errors?.required" style="color: red;">Please enter Password
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-px-0 p-pt-3 p-pb-0">
                                <label>Select the Module</label>
                            </div>
                            <div class="p-col-12 p-px-0">
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon"><i class="pi pi-briefcase"></i></span>
                                    <p-dropdown [options]="cities" formControlName="selectedCity" optionLabel="name"
                                        class="W100"></p-dropdown>
                                </div>
                            </div>
                            <div class="p-col-12 p-px-0 p-pt-4 p-pb-0">
                                <div class="p-d-flex p-flex-row">
                                    <div class="p-col-6 p-p-0">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input"
                                                id="customControlInline">
                                            <label class="custom-control-label" for="customControlInline">Remember
                                                me</label>
                                        </div>
                                    </div>
                                    <div class="p-col-6 p-py-0 text-right">
                                        <a href="/forgotPassword" class="text-muted font-13"><i
                                                class="mdi mdi-lock"></i>
                                            Forgot password?</a>
                                    </div>
                                </div>
                            </div>

                            <div class="p-col-12 p-mt-3 p-p-0">
                                <div class="p-col-12 p-p-0">
                                    <button pButton pRipple type="submit" icon="pi pi-sign-in"
                                         iconPos="right" label="Log In"></button>
                                </div>
                                <small *ngIf="errorMessage != null || errorMessage != undefined || errorMessage != ''" class="p-invalid">   
                                    {{errorMessage}}
                                </small>
                            </div>
                        </form>                        
                    </div>
                </div>
            </div>
        </div>

        <!-- Right Container -->
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-7 p-xl-8 p-p-0 p-d-flex p-jc-center p-order-1 p-order-lg-2">
            <div class="accountbg p-d-flex p-ai-center">
                <div class="account-title">
                    <h4 class="headerTxt p-mt-3">Welcome To Advanced InsurTech Solution</h4>
                    <div class="border w-25 p-mx-auto border-primary"></div>
                    <h1 class="">Let's Get Started</h1>
                    <!-- <p class="p-mt-3 f-18">Don't have an account ? <a href="" class="text-primary">Sign up</a></p> -->

                </div>
            </div>
        </div>
    </div>
</div>