
                <div class="mainDiv">
                    <div class="leftDiv">
                      <p-card>
                        <p-tabView>
                            <p-tabPanel header="General">
                                <div class="p-col-12">
                                    <div class="p-inputgroup">
                                        <input type="text" pInputText placeholder="Search">
                                        <button type="button" pButton pRipple icon="pi pi-search" class="btnCss searchbtn"></button>
                                    </div>
                                </div>
                                <a href="#" style="text-decoration: none;">
                                    <div class="box_wrapper">
                                        <div class="p-grid">
                                            <div class="Leftimg" style="position: relative;">
                                                <img src="assets\images\user-1.jpg" class="profile_pic">
                                                <span class="statuscss statuscolorGreen"></span>
                                            </div>
                                            <div class="name">
                                                <div class="p-col-12" class="person_name">
                                                    Daniel Madsen
                                                </div>
                                                <div class="p-col-12" class="message">
                                                      Good morning
                                                </div>
                                            </div>
                                            <div class="month">
                                                <div class="p-col-12" class="date">20 Feb</div>
                                                    <div class="p-col-12" class="number">1</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="#" style="text-decoration: none;">
                                    <div class="box_wrapper">
                                        <div class="p-grid">
                                            <div class="Leftimg" style="position: relative;">
                                                <img src="assets\images\user-1.jpg" class="profile_pic">
                                                <span class="statuscss statuscolorGreen"></span>
                                            </div>
                                            <div class="name">
                                                <div class="p-col-12" class="person_name">
                                                    Daniel Madsen
                                                </div>
                                                <div class="p-col-12" class="message">
                                                      Good morning
                                                </div>
                                            </div>
                                            <div class="month">
                                                <div class="p-col-12" class="date">20 Feb</div>
                                                    <div class="p-col-12" class="number">1</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="#" style="text-decoration: none;">
                                    <div class="box_wrapper">
                                        <div class="p-grid">
                                            <div class="Leftimg" style="position: relative;">
                                                <img src="assets\images\user-1.jpg" class="profile_pic">
                                                <span class="statuscss statuscolorGreen"></span>
                                            </div>
                                            <div class="name">
                                                <div class="p-col-12" class="person_name">
                                                    Daniel Madsen
                                                </div>
                                                <div class="p-col-12" class="message">
                                                      Good morning
                                                </div>
                                            </div>
                                            <div class="month">
                                                <div class="p-col-12" class="date">20 Feb</div>
                                                    <div class="p-col-12" class="number">1</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </p-tabPanel>
                            <p-tabPanel header="Groups">
                                <div class="p-col-12">
                                    <div class="p-inputgroup">
                                        <input type="text" pInputText placeholder="Search">
                                        <button type="button" pButton pRipple icon="pi pi-search" class="btnCss searchbtn"></button>
                                    </div>
                                </div>
                                <a href="#" style="text-decoration: none;">
                                    <div class="box_wrapper">
                                        <div class="p-grid">
                                            <div class="Leftimg" style="position: relative;">
                                                <img src="assets\images\user-1.jpg" class="profile_pic">
                                                <span class="statuscss statuscolorGreen"></span>
                                            </div>
                                            <div class="name">
                                                <div class="p-col-12" class="person_name">
                                                    Daniel Madsen
                                                </div>
                                                <div class="p-col-12" class="message">
                                                      Good morning
                                                </div>
                                            </div>
                                            <div class="month">
                                                <div class="p-col-12" class="date">20 Feb</div>
                                                    <div class="p-col-12" class="number">1</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="#" style="text-decoration: none;">
                                    <div class="box_wrapper">
                                        <div class="p-grid">
                                            <div class="Leftimg" style="position: relative;">
                                                <img src="assets\images\user-1.jpg" class="profile_pic">
                                                <span class="statuscss statuscolorGreen"></span>
                                            </div>
                                            <div class="name">
                                                <div class="p-col-12" class="person_name">
                                                    Daniel Madsen
                                                </div>
                                                <div class="p-col-12" class="message">
                                                      Good morning
                                                </div>
                                            </div>
                                            <div class="month">
                                                <div class="p-col-12" class="date">20 Feb</div>
                                                    <div class="p-col-12" class="number">1</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="#" style="text-decoration: none;">
                                    <div class="box_wrapper">
                                        <div class="p-grid">
                                            <div class="Leftimg" style="position: relative;">
                                                <img src="assets\images\user-1.jpg" class="profile_pic">
                                                <span class="statuscss statuscolorGreen"></span>
                                            </div>
                                            <div class="name">
                                                <div class="p-col-12" class="person_name">
                                                    Daniel Madsen
                                                </div>
                                                <div class="p-col-12" class="message">
                                                      Good morning
                                                </div>
                                            </div>
                                            <div class="month">
                                                <div class="p-col-12" class="date">20 Feb</div>
                                                    <div class="p-col-12" class="number">1</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </p-tabPanel>
                            <p-tabPanel header="Personal">
                                <div class="p-col-12">
                                    <div class="p-inputgroup">
                                        <input type="text" pInputText placeholder="Search">
                                        <button type="button" pButton pRipple icon="pi pi-search" class="btnCss searchbtn"></button>
                                    </div>
                                </div>
                                <a href="#" style="text-decoration: none;">
                                    <div class="box_wrapper">
                                        <div class="p-grid">
                                            <div class="Leftimg" style="position: relative;">
                                                <img src="assets\images\user-1.jpg" class="profile_pic">
                                                <span class="statuscss statuscolorGreen"></span>
                                            </div>
                                            <div class="name">
                                                <div class="p-col-12" class="person_name">
                                                    Daniel Madsen
                                                </div>
                                                <div class="p-col-12" class="message">
                                                      Good morning
                                                </div>
                                            </div>
                                            <div class="month">
                                                <div class="p-col-12" class="date">20 Feb</div>
                                                    <div class="p-col-12" class="number">1</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="#" style="text-decoration: none;">
                                    <div class="box_wrapper">
                                        <div class="p-grid">
                                            <div class="Leftimg" style="position: relative;">
                                                <img src="assets\images\user-1.jpg" class="profile_pic">
                                                <span class="statuscss statuscolorGreen"></span>
                                            </div>
                                            <div class="name">
                                                <div class="p-col-12" class="person_name">
                                                    Daniel Madsen
                                                </div>
                                                <div class="p-col-12" class="message">
                                                      Good morning
                                                </div>
                                            </div>
                                            <div class="month">
                                                <div class="p-col-12" class="date">20 Feb</div>
                                                    <div class="p-col-12" class="number">1</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="#" style="text-decoration: none;">
                                    <div class="box_wrapper">
                                        <div class="p-grid">
                                            <div class="Leftimg" style="position: relative;">
                                                <img src="assets\images\user-1.jpg" class="profile_pic">
                                                <span class="statuscss statuscolorGreen"></span>
                                            </div>
                                            <div class="name">
                                                <div class="p-col-12" class="person_name">
                                                    Daniel Madsen
                                                </div>
                                                <div class="p-col-12" class="message">
                                                      Good morning
                                                </div>
                                            </div>
                                            <div class="month">
                                                <div class="p-col-12" class="date">20 Feb</div>
                                                    <div class="p-col-12" class="number">1</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </p-tabPanel>
                        </p-tabView>
                      </p-card>
                    </div>
                    <div class="RightDiv">
                        <a href="#" style="text-decoration: none;">
                            <div class="box_wrapper">
                                <div class="p-grid">
                                    <div class="Rightimg">
                                        <img src="assets\images\user-1.jpg" class="profile_pic">
                                       <!--  <span class="statuscss statuscolorGreen"></span> -->
                                    </div>
                                    <div class="chat_name" style="width: 60%; padding-top: 20px;">
                                            <div class="p-col-12" class="heading">Mary Schneider</div>
                                            <div class="p-col-12" class="sub_heading">last seen 2 hours ago</div>
                                    </div>
                                    <div class="features" style="width: 25%; float: right; padding-top: 20px;">
                                        <a href="#" class="phone"><i class="pi pi-phone"></i></a> &nbsp; &nbsp;
                                        <a href="#" class="phone"><i class="pi pi-video"></i></a> &nbsp; &nbsp;
                                        <a href="#" class="phone"><i class="pi pi-trash"></i></a> &nbsp; &nbsp;
                                        <a href="#" class="phone"><i class="pi pi-ellipsis-v"></i></a>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <div class="chatBgImg">
                            <div class="chatMainDiv">
                                <div class="pic_img">
                                    <img src="assets\images\user-1.jpg" class="profile_pic1">
                                </div>
                               <div class="chatbody">
                                <div class="Chat-mes">
                                    <p style="padding-left: 60px;"> Good Morning !</p>
                                </div>
                                <div class="Chat-mes">
                                    <p>There are many variations of passages of Lorem Ipsum available.</p>
                                </div>
                          </div>
                        </div>
                        <div class="chatMainDiv">
                            <div class="chatbodyGray">
                            <div class="Chat-mesGray">
                                <p style="padding-right: 60px;">Good Morning !</p>
                            </div>
                            <div class="Chat-mesGray">
                                <p>There are many variations of passages of Lorem Ipsum available.</p>
                            </div>
                            </div>
                            <div class="pic_img">
                                <img src="assets\images\user-1.jpg" class="profile_pic1">
                            </div>
                        </div>
                    </div>
                    <div class="p-grid">
                        <input type="text" pInputText placeholder="Type something here" class="text_box">
                       </div>
                        
                    </div>
                </div>
             