import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users-roles',
  templateUrl: './users-roles.component.html',
  styleUrls: ['./users-roles.component.scss']
})
export class UsersRolesComponent implements OnInit {
  cities: any[];
  selectedCity: any;
  displayBasic: boolean;
  activeState: boolean = false;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  showBasicDialog() {
    this.displayBasic = true;
  }
  goToAddRoles() {
    this.router.navigate(['/ClaimModule/addRoles']);
  }
}
