<div class="p-col-12">
<div class="p-grid">
    <div class="p-col-12 p-xl-8 p-lg-8 p-md-12 p-sm-12">
       <p-card>
        <div class="p-col-12" class="header-title">MOST COMMONLY ASKED QUESTIONS</div>
        <div class="p-col-12" class="subheading">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.</div>
        <div class="p-col-12" style="margin-top: 20px;">
            <div class="p-grid">
                <div class="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12">
                    <div class="p-col-12" class="question">
                        1. What is CoKube CLAIM MODULE?
                    </div>
                    <div class="p-col-12" class="para">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                    </div>
                    </div>
                    <div class="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12">
                    <div class="p-col-12" class="question">
                        2. How buy CoKube CLAIM MODULE on coin?
                    </div>
                    <div class="p-col-12" class="para">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                    </div>
                </div>
                <div class="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12">
                    <div class="p-col-12" class="question">
                        3. What cryptocurrency can i use to buy CoKube CLAIM MODULE?
                    </div>
                    <div class="p-col-12" class="para">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                    </div>
                </div>
                <div class="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12">
                    <div class="p-col-12" class="question">
                        4. Where can i download the wallet?
                  </div>
                  <div class="p-col-12" class="para">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                  </div>
                </div>
                <div class="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12">
                    <div class="p-col-12" class="question">
                        5. Can i trade CoKube CLAIM MODULE?
                </div>
                <div class="p-col-12" class="para">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                </div>
                </div>
                <div class="p-col-12 p-xl-6 p-lg-6 p-md-12 p-sm-12">
                    <div class="p-col-12" class="question">
                        6. What is CoKube CLAIM MODULE?
                </div>
                <div class="p-col-12" class="para">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.
                </div>
                </div>
                </div>
        </div>
       </p-card>
    </div>
    <div class="p-col-12 p-xl-4 p-lg-4 p-md-12 p-sm-12">
       <p-card>
        <div class="p-col-12" class="header-title">HAVE MORE QUESTIONS</div>
        <div class="p-col-12" class="subheading">Don't Worry ! Email us your Questions or you can send us twitter.</div>
        <div class="p-col-12" style="margin-top: 10px;">
            <div class="p-grid">
                <div class="p-col-6">
                    <input type="text" pInputText  placeholder="Name">
                </div>
                <div class="p-col-6">
                    <input type="text" pInputText  placeholder="Email">
                </div>
                <div class="p-col-12">
                    <input type="text" pInputText placeholder="Subject">
                </div>
                <div class="p-col-12">
                    <textarea pInputTextarea placeholder="Your message" style="height: 125px;"></textarea>
                </div>
                <div class="p-col-12">
                    <button pButton type="button" label="Send Email" class="btnCss newrowBtn p-mr-2" style="width: 100%;"> </button>
                    <!-- <a href="#" class="send_email-btn">Send Email</a> -->
                </div>
            </div>
        </div>
       </p-card>
    </div>
    <p-card>
        <div class="p-col-12" class="header-title">MOST COMMONLY ASKED QUESTIONS</div>
        <div class="p-col-12" class="subheading">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.</div>
    <div class="p-grid p-pt-4">
        <p-accordion>
           
                <p-accordionTab header="What is CoKube CLAIM MODULE?" class="question" [selected]="true">
                    <div class="p-col-12" class="accordion_content">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                    </div>
                  </p-accordionTab>
          
            <p-accordionTab header="How buy CoKube CLAIM MODULE on coin?" class="question">
                <div class="p-col-12" class="accordion_content">
                  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                </div>
              </p-accordionTab>
          
            <p-accordionTab header="What cryptocurrency can i use to buy CoKube CLAIM MODULE?" class="question">
                <div class="p-col-12" class="accordion_content">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch.
                </div>    
            </p-accordionTab>
          
        </p-accordion>
    </div>
</p-card>
</div>
</div>
