<div class="p-fluid claimModule">
    <mat-toolbar>
        <div class="p-col p-p-0 p-d-flex p-jc-start" style="align-self: center;">
            <!-- <img src="./../../../../assets/images/logo-sm.png" class="logo" alt="logo" /> -->
            <img *ngIf="imageLogo" src="{{imageLogo}}" class="logo" alt="logo" />
            <!-- <span *ngIf="imageLogo" class="moduleHeader">CLAIM MODULE</span> -->

            <div class="p-col p-p-0 p-d-flex p-jc-start dropselection" style="align-self: center;">
                <p-dropdown [options]="modulesList" [(ngModel)]="selectedModule" (onChange)="onModuleChange($event)"
                    optionLabel="label"></p-dropdown>
            </div>
        </div>
        <span class="menu-spacer"></span>
        <div class="p-col p-p-0 p-d-flex p-jc-end" style="align-items: center;">
            <div fxShow="true" fxHide.lt-md>
                <div class="p-col p-p-0 p-d-flex p-jc-end" style="align-items: center;">
                    <ngx-avatar class="toolbarAvatar" name="Srivalli Gunturu" src="assets/images/test.png"
                        (click)="profileMenu.toggle($event)"></ngx-avatar>
                    <p-menu #profileMenu [popup]="true" [model]="settingsMenu"></p-menu>
                </div>
            </div>
            <a mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
                <mat-icon class="hamburger">menu</mat-icon>
            </a>
        </div>
    </mat-toolbar>
    <div class="page-wrapper-img">
        <div class="p-fluid">

        </div>
    </div>
    <mat-sidenav-container>
        <mat-sidenav #sidenav>
            <p-panelMenu [model]="mobileItems"></p-panelMenu>
        </mat-sidenav>
        <mat-sidenav-content>
            <p-card class="menuCard" *ngIf="!isSinglejobView">
                <div class="p-fluid">
                    <div fxShow="true" fxHide.lt-md>
                        <p-megaMenu [model]="items"></p-megaMenu>
                    </div>
                </div>
            </p-card>
            <div class="p-fluid">
                <div class="pageWrapper">
                    <div style="
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transform: -webkit-translate(-50%, -50%);
                    transform: -moz-translate(-50%, -50%);
                    transform: -ms-translate(-50%, -50%);
                    color: darkred;
                    z-index: 999999;" *ngIf="showLoader">
                        <p-progressSpinner></p-progressSpinner>
                    </div>
                    <router-outlet></router-outlet>
                </div>

            </div>


        </mat-sidenav-content>

    </mat-sidenav-container>

</div>
<div class="p-fluid">
    <div class="footerBg">
        <div class="p-col-12  p-sm-6 p-d-flex p-jc-center p-jc-sm-start"> © 2021 CoKube </div>
        <div class="p-col-12  p-sm-6 p-d-flex p-jc-center p-jc-sm-end">Crafted with &nbsp;<i class="pi pi-heart"
                style="color: red;"></i>&nbsp; using
            Low-code framework </div>
    </div>
</div>