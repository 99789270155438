import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ClaimsService } from 'src/app/services/claims.service';
import { Constants } from "../constants";

@Component({
  selector: 'app-masters-user',
  templateUrl: './masters-user.component.html',
  styleUrls: ['./masters-user.component.scss']
})
export class MastersUserComponent implements OnInit {
  displayBasic: boolean = false;
  users : Array<any> = [];
  roles : Array<any> = [];
  roleProperties : Array<any> = [];
  selectedRole = {};
  
  headerLabel : string = 'Add a new user';
  UserForm : FormGroup;
  addMoreGroupMap = {};
  
  constructor(private cService : ClaimsService,private msg:MessageService,private fb: FormBuilder, private confirmService : ConfirmationService) {
    this.UserForm = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      emailId: ['', Validators.required],
      password: ['', Validators.required],
      role: ['', Validators.required],
      isActive: ['1'],
    })
  }

  ngOnInit(): void {
    this.getUsers();
    this.getRoles();
  }

  getUsers(){
    this.cService.getRolesOrUsers(Constants.USERS,Constants.LIST,"pageNumber=1&pageSize=100&sortBy=id&sortOrder=asc").subscribe((users) => {
      if(users['status'] == 1){
        this.users = users['result'];
      }
    },(error) => {
      this.msg.add({severity:'error', summary: 'Error', sticky: true, detail: 'Something went wrong please try again Error: '+error.message});
    })
  }
  getRoles(){
    this.cService.getRolesOrUsers(Constants.ROLES,Constants.LIST,"orgId=3").subscribe((roles) => {
      if(roles['status'] == 1){
        this.roles = roles['data'];
      }
    },(error) => this.errorHandling(error) )
  }

  OnRoleChange(event){
    console.log(event);
    // event.value.properties[0]['options'] = [{"code":"Name","value":"VJ"},{"code":"Desp","value":"Good Boy"}];
    let fieldData = event.value.Fields.map(ele => {
      if(ele.type === 'Selection' && ele.allowedValuesResults){
        ele.allowedValuesResults = Object.entries(ele.allowedValuesResults).map(([key, value]) => {
          return {
            code : value,
            value : key,
          }
        });
      }
      return ele;
    })
    console.log(fieldData);
    this.roleProperties = fieldData;
  }
  errorHandling(error){
    this.msg.add({severity:'error', summary: 'Error', detail: 'Something went wrong please try again Error: '+error.message});
  }
  addUser() {
    this.UserForm.reset();
    this.headerLabel = 'Add a new user';
    this.displayBasic = true;
  }
  closeAddPopup(){
    this.displayBasic = false;
    this.selectedRole = '';
  }

  submitUser(){
    console.log(this.formatPostValues(this.roleProperties));
    return false;
    if(this.UserForm.valid){
    // if(1){
      const userObj = {
        email : this.UserForm.get('emailId').value,
        name : this.UserForm.get('firstname').value+' '+this.UserForm.get('lastname').value,
        firstname : this.UserForm.get('firstname').value,
        lastname : this.UserForm.get('firstname').value,
        logo : "",
        password : this.UserForm.get('password').value,
        active : this.UserForm.get('isActive').value ? 1 : 0,
        accountRole : {
          id : this.UserForm.get('role').value['id']
        },
        properties : []
      }
      console.log(userObj);
      this.cService.postRolesOrUsersData(Constants.USERS,Constants.CREATE,userObj,'').subscribe((users) => {
      if(users['status'] === '1'){
        this.msg.add({severity:'success', summary: 'Success', detail: users['message']});
        this.getUsers();
        this.displayBasic = false;
      }
      else
        this.msg.add({severity:'error', summary: 'Error', detail: users['message']});
     },(error) => this.errorHandling(error) )
    }
  }

  formatPostValues(form, subFormGroupName = '') {
    let key = '';
    let vValue: any;
    let jobFilesList = [];
    let listObj = {};

    form.forEach((element) => {
      key = element['name'];
      let grpName = subFormGroupName ? subFormGroupName : element.groupName;

      vValue = element.value || '';

      if (
        vValue &&
        vValue.length != 0 &&
        element.type === 'Selection' &&
        element.multiselect === 'true'
      ) {
        vValue = vValue.join('%23');
      }

      if(element.type === 'Attachment') {
        if(element[element.name+'_URL']) {
          const val = element[element.name+'_Name'] + '~' + element[element.name+'_URL'] + '`' + element.uniqueId;
          if (this.addMoreGroupMap[grpName]){
            listObj[key] = listObj[key] ? [...listObj[key], val] : [val];
          } else {
            listObj[key] = val;
          }
        } else if(vValue) {
          jobFilesList.push(vValue);
          const val = vValue.name + '`' + element.uniqueId;
          if (this.addMoreGroupMap[grpName]){
            listObj[key] = listObj[key] ? [...listObj[key], val] : [val];
          } else {
            listObj[key] = val;
          }
        } else {
          if (this.addMoreGroupMap[grpName]){
            listObj[key] = listObj[key] ? [...listObj[key], ''] : [''];
          } else {
            listObj[key] = '';
          }
        }
      } else if (
        element['type'] === 'Date' ||
        element['type'] === 'Date Time' ||
        element['type'] === 'Auto Date' ||
        element['type'] === 'Auto Date Time'
      ) {
        if (vValue) {
          const val = this.dateFormat(vValue) + '`' + element.uniqueId;
          if (this.addMoreGroupMap[grpName]) {
            listObj[key] = listObj[key] ? [...listObj[key], val] : [val];
          } else {
            listObj[key] = val;
          }
        } else {
          if (this.addMoreGroupMap[grpName]) {
            listObj[key] = listObj[key] ? [...listObj[key], ''] : [''];
          } else {
            listObj[key] = '';
          }
        }
      } else {
        if (vValue) {
          const val = vValue + '`' + element.uniqueId;
          if (this.addMoreGroupMap[grpName]) {
            listObj[key] = listObj[key] ? [...listObj[key], val] : [val];
          } else {
            listObj[key] = val;
          }
        } else {
          if (this.addMoreGroupMap[grpName]) {
            listObj[key] = listObj[key] ? [...listObj[key], ''] : [''];
          } else {
            listObj[key] = '';
          }
        }
      }
    });
    return {
      'jobFilesList': jobFilesList,
      'listObj': listObj
    }
  }

  dateFormat(date) {
    var d = new Date(date);

    var datestring =
      d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();

    return datestring;
  }
  goToRegisterClaim(er,r){

  }
  editUser(userId,type){
    this.headerLabel = "Edit User";
    // this.users.find((user) => user.id === userId)
    const selectedUser = {};
    console.log(selectedUser)
    selectedUser['firstname'] = "";
    selectedUser['emailId'] = this.users.find((user) => user.id === userId)['emailId'];
    selectedUser['lastname'] = "";
    selectedUser['password'] = "";
    selectedUser['role'] = '';
    selectedUser['isActive'] = '';
    this.UserForm.setValue(selectedUser);
    this.displayBasic = true;
  }
  deleteUser(userId){
    if(!userId){
      this.msg.add({severity:'warn', summary: 'Warning', detail: "User ID is missing"});
      return false;
    }
    console.log(userId);
    this.confirmService.confirm({
        message: 'Do you want to delete this user?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        reject: (type) => {
          this.msg.add({severity:'warn', summary:'Cancelled', detail:'You have cancelled'});
        },
        accept: () => {
          this.cService.getRolesOrUsers(Constants.USERS,'delete',"userId="+userId).subscribe((users) => {
          if(users['status'] === '1'){
            this.getUsers();
            this.msg.add({severity:'success', summary: 'Success', detail: users['message']});
          }
          else
            this.msg.add({severity:'error', summary: 'Error', detail: users['message']});
          },(error) => this.errorHandling(error) )
        }        
    })
  }
}
