import { Component } from '@angular/core';
import { ClaimsService } from './services/claims.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'raymond-claim-management';
  constructor(private claimsService: ClaimsService){
    this.claimsService.autoLogin();
  }
}
