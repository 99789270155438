<p-card class="claimWorkflow">
    <div class="tableheader">
        ADD ROLES
    </div>
    <div class="p-grid">
        <div class="p-col-12">
            Role Name:
        </div>
        <div class="p-col-12">
            Role Description:
        </div>
    </div>
</p-card>

<p-card class="claimWorkflow">
    <div class="p-col p-flex-row p-pt-0 p-pb-0">
        <div class="p-grid p-mt-0 dynamicAccordion">
            <p-accordion [multiple]="true">
                <p-accordionTab header="Add Filters" [(selected)]="roleWidget">
                    <div class="p-col-12" style="position:relative;margin-top: 40px;">
                        <!-- <div class="p-grid">
                            <div class="dynamicCtrlWrap">
                            <div class=" p-col-12 p-md-4">
                                <label for="firstname">Field name</label>
                                <p-dropdown [(ngModel)]="sfl.selectedfieldValue" [options]="fieldsList"
                                    (onChange)="onfieldChange($event,sfl,i)" optionValue="name" optionLabel="name"
                                    placeholder="Select field type">
                                </p-dropdown>
                            </div>

                            <div class="p-col-12 p-md-3">
                                <label for="firstname">Condition</label>
                                <p-dropdown [(ngModel)]="sfl.selectedCondition" [options]="sfl.conditionsList"
                                    optionValue="value" optionLabel="label" (onChange)="onChange($event, sfl)"
                                    placeholder="Select condition"></p-dropdown>
                            </div>

                            <div *ngIf="sfl.selectedBetweenComparision == 0" class="p-col-12 p-md-5">
                                <label for="lastname">Comparison</label>
                                <input [(ngModel)]="sfl.comparisionvalue" *ngIf="sfl.selectedComparision == 2"
                                    type="text" pInputText>
                                <p-dropdown [(ngModel)]="sfl.comparisionvalue" *ngIf="sfl.selectedComparision == 1"
                                    [options]="sfl.selectedComparisionList" optionValue="code" optionLabel="value"
                                    placeholder="Select Values">
                                </p-dropdown>
                                <p-calendar [(ngModel)]="sfl.comparisionvalue" *ngIf="sfl.selectedComparision == 3"
                                    [showIcon]="true" class="calanderIcon">
                                </p-calendar>
                            </div>
                            <div *ngIf="sfl.selectedBetweenComparision == 1" class="p-col-12 p-md-5 p-d-flex p-flex-row">
                                <div class="p-col-12 p-md-6 p-sm-12 p-p-0 p-mr-2">
                                    <label for="lastname">From</label>
                                    <p-calendar [(ngModel)]="sfl.selectedFromValue"
                                        *ngIf="sfl.selectedComparision == 3" [showIcon]="true" class="calanderIcon">
                                    </p-calendar>

                                    <input [(ngModel)]="sfl.selectedFromValue" *ngIf="sfl.selectedComparision == 2"
                                        type="number" pInputText>
                                </div>

                                <div class="p-col-12 p-md-6 p-sm-12 p-p-0 p-pr-1">
                                    <label for="lastname">To</label>
                                    <p-calendar [(ngModel)]="sfl.selectedToValue"
                                        *ngIf="sfl.selectedComparision == 3" [showIcon]="true" class="calanderIcon">
                                    </p-calendar>
                                    <input [(ngModel)]="sfl.selectedToValue" *ngIf="sfl.selectedComparision == 2"
                                        type="number" pInputText>
                                </div>

                            </div>
                        </div>
                            <div class="removeWrapperDiv diagnosisRemove">
                                <i class="pi pi-times closeIcon_marginT" (click)="removeQuantity(i)"></i>
                            </div>
                        </div> -->
                    </div>
                </p-accordionTab>
                <p-accordionTab header="Enable Features" [(selected)]="roleWidget">
                    <div class="p-col-12" style="position:relative;">                       
                        <div class="p-col-12 p-d-flex p-flex-row">
                            <p-table [scrollable]="true" [style]="{width:'100%'}" scrollHeight="200px">
                                <ng-template pTemplate="caption">
                                    <div class="p-grid">
                                        <div class="p-col-12 p-xl-4 p-lg-4 p-md-2 p-sm-12 p-pb-0">
                                            <div class="p-col-12 p-pb-0">
                                               Roles and Responsibilities
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-xl-8 p-lg-8 p-md-10 p-sm-12 p-pr-0 p-pb-0">
                                            <div class="p-grid p-col-12 p-jc-end">
                                                <div class="p-col-12 p-lg-6 p-md-6 p-sm-6 p-pb-0 p-mt-1 p-jc-end text-right">
                                                    Roles:
                                                </div>
                                                <div class="p-col-12 p-lg-6 p-md-6 p-sm-6 p-pb-0">
                                                    <div class="p-inputgroup">
                                                         <ng-select [items]="itemsList" style="width:100%"
                                                                                    bindLabel="value" bindValue="code"
                                                                                    [multiple]="false"
                                                                                    [closeOnSelect]="true"
                                                                                    [searchable]="true">
                                                                                </ng-select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Type</th>
                                        <th>Can View</th>
                                        <th>Can Add</th>
                                        <th>Can Edit</th>
                                        <th>Can Delete</th>         
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-product let-columns="columns">
                                    <tr>                                       
                                        <td> </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                       
                                    </tr>
                                </ng-template>
                            </p-table>
                            </div>
                    </div>
                </p-accordionTab>
                <p-accordionTab header="Enable Job Level Controls" [(selected)]="roleWidget">
                    <div class="p-col-12" style="position:relative;">
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>
        <div class="p-grid p-jc-end">
            <div class="p-col-12 p-md-2 p-sm-12 p-mt-5 p-d-flex  p-jc-end">               
                    <button pButton type="button" label="Save"
                         class="btnCss saveAllBtn"></button>
               
            </div>
        </div>
    </div>
</p-card>