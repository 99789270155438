import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimsService } from 'src/app/services/claims.service';
import { DatePipe } from '@angular/common';
import { ModuleConstants as MC } from '../../module-constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  Claims: any[];
  selectedClaim: any;
  activeState: boolean = false;
  selectedClaimType: string = '';
  claims: any[];
  jobStatusList: any = [];
  subJobsList: any = [];
  selectedClaimTypeId: any = '';
  fieldsList: any[];
  conditionsList: any[];
  rolesAccessData: any;
  fieldcomp = {
    fieldName: '',
    selectedCondition: '',
    conditionsList: [],
    startDate: null,
    endDate: null,
    selectedComparision: 1,
    selectedComparisionList: [],
    selectedBetweenComparision: 0,
    selectedBetweenShowDate: 0,
  };
  textconditionsList = [
    { label: 'is', value: 'is' },
    { label: 'contains', value: 'contains' },
  ];
  numericconditionsList = [
    { label: 'is', value: 'is' },
    { label: 'is less than', value: 'is_less_than' },
    { label: 'is greater than', value: 'is_greater_than' },
    { label: 'between', value: 'between' },
  ];
  dateconditionsList = [
    { label: 'is', value: 'is' },
    { label: 'is less than', value: 'is_less_than' },
    { label: 'is greater than', value: 'is_greater_than' },
    { label: 'between', value: 'date_between' },
  ];
  searchForm: FormGroup;
  datesFlag: boolean = false;
  jobTypeId: number = 0;
  claimsTypeList: any[];
  defaultSelectedClaimType;

  specialFilersList = [];

  specialSelectedValues = [];

  constructor(
    private claimsService: ClaimsService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.initializeForm();
  }

  ngOnInit(): void {
    this.getUserAccessDetails();
  }

  getUserAccessDetails() {
    let userId = localStorage.getItem('loginUserId');

    this.claimsService.getUserAccessDetails(userId).subscribe((res) => {
      this.rolesAccessData = res;

      console.log('this.rolesAccessData::', this.rolesAccessData);

      this.getClaimTypes();

      this.route.fragment.subscribe((fragment: string) => {
        this.defaultSelectedClaimType = fragment;
      });
    });
  }
  initializeForm() {
    this.searchForm = this.fb.group({
      quantities: this.fb.array([]),
    });
  }

  quantities(): FormArray {
    return this.searchForm.get('quantities') as FormArray;
  }

  newSearchForm(): FormGroup {
    return this.fb.group({
      fieldName: [null],
      condition: [null],
      comparision: [null],
      startDate: [null],
      endDate: [null],
      conditionsList: this.conditionsList,
      showFieldComparsion: true,
    });
  }

  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  clearSpecialFilters() {
    this.specialFilersList = [];
    this.getClaimTypes();
  }
  submitSpecialFilters() {
    let finalJson = [];

    let sendforsubmit = 1;
    console.log('this.specialFilersList::', this.specialFilersList);
    if (this.specialFilersList.length > 0) {
      this.specialFilersList.forEach((eachFilter) => {
        console.log(' ach filet::', eachFilter);
        let fieldname = eachFilter['selectedfieldValue']['name'];
        let condition = eachFilter['selectedCondition']['value'];

        let value = '';
        let value2 = '';

        let addvalue2 = 0;

        if (condition == 'between' || condition == 'date_between') {
          addvalue2 = 1;
          if (
            eachFilter['selectedfieldValue']['type']
              .toLowerCase()
              .indexOf('date') >= 0
          ) {
            value = this.datePipe.transform(
              eachFilter['selectedFromValue'],
              'dd/MM/yyyy'
            );

            // value2 = eachFilter['selectedToValue'];

            value2 = this.datePipe.transform(
              eachFilter['selectedToValue'],
              'dd/MM/yyyy'
            );
          } else {
            if (
              eachFilter['selectedfieldValue']['type']
                .toLowerCase()
                .indexOf('selection') >= 0
            ) {
              value = eachFilter['selectedFromValue']['value1'];

              // value2 = eachFilter['selectedToValue'];

              value2 = eachFilter['selectedToValue']['value1'];
            } else {
              value = eachFilter['selectedFromValue'];

              value2 = eachFilter['selectedToValue'];
            }
          }
        } else {
          if (
            eachFilter['selectedfieldValue']['type']
              .toLowerCase()
              .indexOf('date') >= 0
          ) {
            value = this.datePipe.transform(
              eachFilter['comparisionvalue'],
              'dd/MM/yyyy'
            );
          } else {
            if (
              eachFilter['selectedfieldValue']['type']
                .toLowerCase()
                .indexOf('selection') >= 0
            ) {
              value = eachFilter['comparisionvalue']['value1'];
            } else {
              value = eachFilter['comparisionvalue'];
            }
          }
        }

        if (addvalue2) {
          if (
            fieldname == null ||
            fieldname == undefined ||
            fieldname == '' ||
            condition == null ||
            condition == undefined ||
            condition == '' ||
            value2 == null ||
            value2 == undefined ||
            value2 == '' ||
            value == null ||
            value == undefined ||
            value == ''
          ) {
            sendforsubmit = 0;
          }
          finalJson.push({
            fieldname: fieldname,
            condition: condition,
            value2: value2,
            value: value,
          });
        } else {
          if (
            fieldname == null ||
            fieldname == undefined ||
            fieldname == '' ||
            condition == null ||
            condition == undefined ||
            condition == '' ||
            value == null ||
            value == undefined ||
            value == ''
          ) {
            sendforsubmit = 0;
          }
          finalJson.push({
            fieldname: fieldname,
            condition: condition,
            value: value,
          });
        }
      });

      if (sendforsubmit == 1) {
        var input = {
          typeId: this.selectedClaimTypeId,
          filters: JSON.stringify(finalJson),
        };

        this.claimsService.getDocumentsStatus(input).subscribe(
          (res) => {
            this.jobStatusList = res['jobStatus'];
            this.subJobsList = res['subJobs'];
            console.log('this.jobStatusList ::', this.jobStatusList);
          },
          (error: any) => console.log(error)
        );

        console.log('finalJson::', finalJson);
      } else {
        alert('Please Enter All Values');
      }
    }
  }

  addQuantity() {
    var rString = this.randomString(
      10,
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    );

    // this.fieldsList.forEach((element) => {
    //   element['disabled'] = false;
    // });

    this.specialFilersList.push({
      id: rString,
      fieldName: '',
      selectedfieldValue: '',
      selectedCondition: '',
      comparisionvalue: '',
      conditionsList: [],
      startDate: null,
      endDate: null,
      selectedComparision: 1,
      selectedComparisionList: [],
      selectedBetweenComparision: 0,
      selectedBetweenShowDate: 0,
      selectedFromValue: '',
      selectedToValue: '',
    });

    this.quantities().push(this.newSearchForm());
  }

  removeQuantity(i: number) {
    this.quantities().removeAt(i);
    this.datesFlag = false;

    this.specialFilersList.splice(i, 1);
    this.disablevalues();
  }

  checkClaimExist(claimLable) {
    let jobtypes = this.rolesAccessData['JobTypes'];
    let isExist = false;
    jobtypes.forEach((element) => {
      if (element['name'] == claimLable) {
        isExist = true;
      }
    });
    return isExist;
    //this.
  }

  getClaimTypes() {
    this.claimsService.getClaimTypes(MC.CLAIM_MODULE_VALUE).subscribe(
      (res) => {
        if (res['status'] == 1) {
          this.claimsTypeList = res.jobs;
          console.log('claimtypeslist:::', JSON.stringify(this.claimsTypeList));

          var claimsArray = [];

          for (var i = 0; i < this.claimsTypeList.length; i++) {
            this.selectedClaim = {
              label: this.claimsTypeList[0].Name,
              value: this.claimsTypeList[0].typeID,
            };

            this.selectedClaimType = this.claimsTypeList[0].Name;
            this.selectedClaimTypeId = this.claimsTypeList[0].typeID;
            this.jobTypeId = this.claimsTypeList[0].jobTypeId;

            var claimObj = {
              label: this.claimsTypeList[i].Name,
              value: this.claimsTypeList[i].typeID,
            };
  //Rama : TODO for Access Filters
            //if (this.checkClaimExist(this.claimsTypeList[i].Name))
            {
              claimsArray.push(claimObj);
            }
          }

          if (this.defaultSelectedClaimType) {
            const selectedClaim = this.claimsTypeList.filter(
              (claim) => claim.Name === this.defaultSelectedClaimType
            );
            if (selectedClaim && selectedClaim.length > 0) {
              this.selectedClaim = {
                label: selectedClaim[0].Name,
                value: selectedClaim[0].typeID,
              };
              this.selectedClaimType = selectedClaim[0].Name;
              this.selectedClaimTypeId = selectedClaim[0].typeID;
            }
          }

          this.claims = claimsArray;

          this.getDocumentsStatus();
          this.getAllMasterTypeJobsList();
        }
      },
      (error: any) => console.log(error)
    );
  }

  getDocumentsStatus() {
    var input = {
      typeId: this.selectedClaimTypeId,
    };

    this.claimsService.getDocumentsStatus(input).subscribe(
      (res) => {
        this.jobStatusList = res['jobStatus'];
        this.subJobsList = res['subJobs'];
      },
      (error: any) => console.log(error)
    );
  }

  onClaimsChange(event) {
    this.selectedClaimType = this.selectedClaim.label;
    this.selectedClaimTypeId = this.selectedClaim.value;
    window.location.hash = this.selectedClaim.label;

    var claimType = this.claimsTypeList.filter(
      (j) => j.Name === this.selectedClaimType
    );
    this.jobTypeId = claimType[0].jobTypeId;

    this.getDocumentsStatus();
    this.getAllMasterTypeJobsList();
    this.specialFilersList = [];
  }

  redirectToViewClaim(formName, jobType, jobStatus) {
    var claimStatus = {
      formName: formName,
      jobType: jobType,
      jobStatus: jobStatus,
      claimType: this.selectedClaimType,
    };
    localStorage.setItem('claimStatus', JSON.stringify(claimStatus));
    this.router.navigate(
      ['/'+MC.CLAIM_MODULE+'/jobsList', { selectedStatus: jobType }],
      { preserveFragment: true }
    );
  }

  getAllMasterTypeJobsList() {
    var inputData = {
      label: this.selectedClaimType,
    };

    this.claimsService.getAllMasterTypeJobsList(inputData).subscribe(
      (res) => {
        if (res['status'] == 1) {
          var result = res['orgs'];
          console.log('result[0].Fields::', result[0].Fields);

          this.fieldsList = result[0].Fields.filter(
            (j) => j.isSearchable === true
          );

          this.fieldsList.forEach((element) => {
            element['disabled'] = false;
            element['label'] = element['name'];
            element['value'] = element;
          });
          // this.fieldsList = result[0].Fields;
          console.log('this.fieldsList::', this.fieldsList);
        }
      },
      (error: any) => console.log(error)
    );
  }

  onChange(event, sfl) {
    console.log('event::', event.value);
    console.log('sfl::', sfl);
    console.log('event.value.value ::', event.value.value);
    if (event.value.value == 'between' || event.value.value == 'date_between') {
      console.log('in if ');
      sfl['selectedBetweenComparision'] = 1;
    } else {
      console.log('in else ');
      sfl['selectedBetweenComparision'] = 0;
    }
    console.log('sfl2::', sfl);
    /*var fieldDate = quantity.value.fieldName.name;


    if (event.value.label === 'is between') {
      if (fieldDate.toLowerCase().indexOf('date') !== -1) {
        this.datesFlag = true;
      } else {
        this.datesFlag = false;
      }
    } else {
      this.datesFlag = false;
    }*/
  }

  disablevalues() {
    this.fieldsList.forEach((element) => {
      element['disabled'] = false;
    });
    this.specialSelectedValues = [];
    this.specialFilersList.forEach((element) => {
      if (element['selectedfieldValue']['name'] != '') {
        this.specialSelectedValues.push(element['selectedfieldValue']['name']);
      }
    });
    this.specialSelectedValues.forEach((selvalue) => {
      this.fieldsList.forEach((element) => {
        if (selvalue == element.label) {
          element['disabled'] = true;
        }
      });
    });
  }

  onfieldChange(event, sfl, index) {
    this.disablevalues();
    console.log('sfl::', index);
    console.log('event value::', event.value.name);
    /*  console.log('text::', event.value['type'].indexOf('Text'));
    console.log('date::', event.value['type'].indexOf('Date'));
    console.log('numeric::', event.value['type'].indexOf('Numeric'));
    console.log('Selection::', event.value['type'].indexOf('Selection'));*/

    if (event.value['type'].toLowerCase().indexOf('text') >= 0) {
      console.log('text');
      this.specialFilersList[index]['conditionsList'] = this.textconditionsList;
      this.specialFilersList[index]['selectedComparision'] = 2;
      this.specialFilersList[index]['selectedComparisionList'] = [];
    }
    if (event.value['type'].toLowerCase().indexOf('date') >= 0) {
      this.specialFilersList[index]['conditionsList'] = this.dateconditionsList;
      console.log('date');
      this.specialFilersList[index]['selectedComparision'] = 3;
      this.specialFilersList[index]['selectedComparisionList'] = [];
    }
    if (event.value['type'].toLowerCase().indexOf('numeric') >= 0) {
      this.specialFilersList[index]['conditionsList'] =
        this.numericconditionsList;
      console.log('numeric');
      this.specialFilersList[index]['selectedComparision'] = 2;
      this.specialFilersList[index]['selectedComparisionList'] = [];
    }

    if (event.value['type'].toLowerCase().indexOf('selection') >= 0) {
      this.specialFilersList[index]['conditionsList'] = this.textconditionsList;
      let avr = event.value['allowedValuesResults'];
      console.log(event.value['allowedValuesResults']);
      console.log('Selection');

      var result = Object.keys(avr).map((key) => [key, avr[key]]);
      console.log('result', JSON.stringify(result));

      let listarray = [];
      for (var k = 0; k < result.length; k++) {
        var kObj = {
          value: result[k][0].replace(/[(][0-9]{4}[)]$/gi, ''),
          value1: result[k][0],
          code: result[k][1],
        };
        listarray.push(kObj);
      }
      this.specialFilersList[index]['selectedComparision'] = 1;
      this.specialFilersList[index]['selectedComparisionList'] = listarray;
      //   listarray = this.trimJobIdFromSelectorValues(listarray);
      console.log('listarray::', this.specialFilersList);
    }

    //

    console.log('specialFilersList::', this.specialFilersList);
  }

  trimJobIdFromSelectorValues(lists) {
    return lists.map((list) => {
      list.value = list.value.replace(/[(][0-9]{4}[)]$/gi, '');
      list.options = list?.options.map((o) => {
        o.value = o.value.replace(/[(][0-9]{4}[)]$/gi, '');
        return o;
      });

      return list;
    });
  }

  downloadPdf() {
    var input = {
      jobType: this.selectedClaimTypeId,
    };

    this.claimsService
      .downloadPdf(input)

      .subscribe(
        (res) => {
          console.log('..downloading ');

          const blob = new Blob([res], { type: 'application/pdf' });

          const url = window.URL.createObjectURL(blob);

          window.open(url);
        },

        (error: any) => console.log(error)
      );
  }
}
