<p-card>
    <div class="p-col-12" style="position: relative;">
        <button pButton type="button" label="Add New User" class="btnCss newrowBtn p-mr-2 p-mt-2" (click)="addUser()">
        </button>
    </div>
    <form action="" [formGroup]="UserForm" *ngIf="displayBasic">
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <label>First Name</label>
                <input formControlName="firstname" type="text" pInputText>
            </div>
            <div class="p-col-12 p-md-6">
                <label>Last Name</label>
                <input formControlName="lastname" type="text" pInputText>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <label>Email</label>
                <input formControlName="emailId" type="email" pInputText>
            </div>
            <div class="p-col-12 p-md-6">
                <label>Password</label>
                <input formControlName="password" type="password" pInputText>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <label>Select Role</label>
                <p-dropdown formControlName="role" [options]="roles" [(ngModel)]="selectedRole"
                    (onChange)="OnRoleChange($event)" optionLabel="name" [filter]="false" filterBy="name"
                    [showClear]="false" placeholder="Select a Role">
                    <!-- <ng-template pTemplate="selectedItem">
                            <div class="country-item country-item-value" *ngIf="selectedRole">
                                <div>{{selectedRole.name}}</div>
                            </div>
                        </ng-template>
                        <ng-template let-role>
                            <div class="country-item">
                                <div>{{role.name}}</div>
                            </div>
                        </ng-template> -->
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-6"><br>
                <div class="p-field-checkbox">
                    <p-checkbox value="1" formControlName="isActive"></p-checkbox>
                    <label>Active</label>
                </div>
            </div>
        </div>
        <div class="p-grid" *ngIf="roleProperties.length">
            <ng-container *ngFor="let registerForm of roleProperties">
                <div class="p-col-12 p-md-6">
                    <label>{{registerForm.name}}</label><br>
                    <ng-container *ngIf="registerForm.type === 'Small Text';else notText">
                        <input type="text" [(ngModel)]="registerForm.value" [disabled]="isViewOnly" pInputText>
                    </ng-container>
                    <ng-template #notText>
                        <ng-container *ngIf="registerForm.type === 'ReadOnly Small Text';else notReadonly">
                            <input type="text" [(ngModel)]="registerForm.value" [disabled]="isViewOnly"
                                [readonly]="true" pInputText>
                        </ng-container>
                    </ng-template>
                    <ng-template #notReadonly>
                        <ng-container *ngIf="registerForm.type === 'Large Text';else notLargetext">
                            <p-editor [(ngModel)]="registerForm.value" [style]="{'height':'320px'}"></p-editor>
                        </ng-container>
                    </ng-template>
                    <ng-template #notLargetext>
                        <ng-container *ngIf="registerForm.type === 'Date Time';else notDatetime">
                            <p-calendar [(ngModel)]="registerForm.value" [disabled]="isViewOnly" showTime="true"
                                hourFormat="24" appendTo="body">
                            </p-calendar>
                        </ng-container>
                    </ng-template>
                    <ng-template #notDatetime>
                        <ng-container *ngIf="registerForm.type === 'Date'; else notDate">
                            <p-calendar [showIcon]=" true" appendTo="body" [(ngModel)]="registerForm.value"
                                [disabled]="isViewOnly" [monthNavigator]="true" [yearNavigator]="true"
                                dateFormat="dd/mm/yy" inputId="navigators" yearRange="1990:2050">
                            </p-calendar>
                        </ng-container>
                    </ng-template>
                    <ng-template #notDate>
                        <ng-container *ngIf="registerForm.type === 'Selection';else notSelect">
                            <div *ngIf="registerForm.multiselect === 'false'">
                                <ng-select [items]="registerForm.allowedValuesResults" bindLabel="value"
                                    [disabled]="isViewOnly" [(ngModel)]="registerForm.value" bindValue="code"
                                    [multiple]="false" [closeOnSelect]="true" [ngModelOptions]="{standalone: true}"
                                    [searchable]="true">
                                </ng-select>
                            </div>
                            <div *ngIf="registerForm.multiselect === 'true'">
                                <ng-select [items]="registerForm.allowedValuesResults" bindLabel="value"
                                    [disabled]="isViewOnly" [(ngModel)]="registerForm.value" bindValue="code"
                                    [multiple]="true" [closeOnSelect]="false" [searchable]="true">
                                </ng-select>
                            </div>

                        </ng-container>
                    </ng-template>
                    <ng-template #notSelect>
                        <ng-container *ngIf="registerForm.type === 'Large Selection';else notLargeSelection">
                            <ng-select [items]="itemsList" bindLabel="value" [(ngModel)]="registerForm.value"
                                bindValue="code" [multiple]="true" [closeOnSelect]="true" [searchable]="true">
                            </ng-select>
                        </ng-container>
                    </ng-template>
                    <ng-template #notLargeSelection>
                        <ng-container *ngIf="registerForm.type === 'Small Heading';else notSmallHeading">
                            <h2>Test Small Heading</h2>
                        </ng-container>
                    </ng-template>
                    <ng-template #notSmallHeading>
                        <ng-container *ngIf="registerForm.type === 'Large Heading';else notLargeHeading">
                            <h1>Test Large Heading</h1>
                        </ng-container>
                    </ng-template>
                    <ng-template #notLargeHeading>
                        <ng-container *ngIf="registerForm.type === 'Radio OR CheckBox';else notRadio">
                            <p-checkbox name="groupname" [(ngModel)]="registerForm.value" value="val1">
                            </p-checkbox>

                            <p-radioButton name="groupname" [(ngModel)]="registerForm.value" value="val2">
                            </p-radioButton>
                        </ng-container>
                    </ng-template>
                    <ng-template #notRadio>
                        <ng-container *ngIf="registerForm.type === 'Attachment';else notAttachment">
                            <ngx-file-drop dropZoneLabel="Drop files here" class="claimdragdrop"
                                (onFileDrop)="dropped($event,registerForm)" (onFileOver)="fileOver($event)"
                                (onFileLeave)="fileLeave($event)" multiple="false">
                                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector"
                                    multiple="true">
                                    <div
                                        style="width:100%;height:24px;flex:1;text-align: center;margin-top:1px;color: #646565;">
                                        Drag & Drop Here</div>
                                    <div style="width:100%;height:auto;flex:1;text-align: center;">
                                        <button type="button" (click)="openFileSelector()">Browse</button>
                                    </div>
                                </ng-template>

                            </ngx-file-drop>
                            <tr *ngIf="registerForm[registerForm.name +'_Name']" style="cursor: pointer;">
                                <td><a (click)="downloadAttachment(registerForm[registerForm.name +'_URL'])">
                                        {{registerForm[registerForm.name
                                        +'_Name']}}
                                    </a>
                                </td>
                            </tr>
                            <tr *ngIf="registerForm.fileName">
                                <td><strong>{{ registerForm.fileName }}</strong>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-template>
                    <ng-template #notAttachment>
                        <ng-container *ngIf="registerForm.type === 'Hidden Text Field';else notHiddentext">
                            <input type="text" [(ngModel)]="registerForm.value" [disabled]="isViewOnly" type="hidden"
                                pInputText>
                        </ng-container>
                    </ng-template>
                    <ng-template #notHiddentext>
                        <ng-container *ngIf="registerForm.type === 'Auto Date Time';else notAutoDatetime">

                            <p-calendar [(ngModel)]="registerForm.value" [showTime]="true" [disabled]="isViewOnly"
                                appendTo="body" inputId="time"></p-calendar>
                        </ng-container>
                    </ng-template>
                    <ng-template #notAutoDatetime>
                        <ng-container *ngIf="registerForm.type === 'Auto Date';else notAutoTime">
                            <p-calendar [showIcon]=" true" [monthNavigator]="true" appendTo="body"
                                [yearNavigator]="true" dateFormat="dd/mm/yy" inputId="navigators"
                                [disabled]="isViewOnly" yearRange="1990:2050" [(ngModel)]="registerForm.value">
                            </p-calendar>
                        </ng-container>
                    </ng-template>
                    <ng-template #notAutoTime>
                        <ng-container *ngIf="registerForm.type === 'Time';else notTime">
                            <p-calendar [(ngModel)]="registerForm.value" appendTo="body" [timeOnly]="true"
                                [disabled]="isViewOnly" inputId="timeonly">
                            </p-calendar>
                        </ng-container>
                    </ng-template>
                    <ng-template #notTime>
                        <ng-container *ngIf="registerForm.type === 'MulltiMedia';else notMultimedia">
                            <input type="text" pInputText [disabled]="isViewOnly" [(ngModel)]="registerForm.value">
                        </ng-container>
                    </ng-template>
                    <ng-template #notMultimedia>
                        <ng-container *ngIf="registerForm.type === 'Geo Location';else notGeoLocation">
                            <input ngx-google-places-autocomplete #places="ngx-places" [(ngModel)]="registerForm.value"
                                (onAddressChange)="handleAddressChange($event,registerForm)" />

                        </ng-container>
                    </ng-template>
                    <ng-template #notGeoLocation>
                        <ng-container *ngIf="registerForm.type === 'Numeric Text';else notNumericText">
                            <input pInputText type="text" pKeyFilter="pnum" [disabled]="isViewOnly"
                                [(ngModel)]="registerForm.value" />
                        </ng-container>
                    </ng-template>
                    <ng-template #notNumericText>
                        <ng-container *ngIf="registerForm.type === 'Dates after today';else notDatesAfterToday">
                            <p-calendar [showIcon]=" true" [monthNavigator]="true" appendTo="body"
                                [yearNavigator]="true" dateFormat="dd/mm/yy" [minDate]="tomorrow" inputId="navigators"
                                [disabled]="isViewOnly" [(ngModel)]="registerForm.value" yearRange="1990:2050">
                            </p-calendar>
                        </ng-container>
                    </ng-template>
                    <ng-template #notDatesAfterToday>
                        <ng-container *ngIf="registerForm.type === 'SobJob';else notSubJob">
                            <input type="text" pInputText [(ngModel)]="registerForm.value">
                        </ng-container>
                    </ng-template>
                    <ng-template #notSubJob>
                        <ng-container *ngIf="registerForm.type === 'Email';else notEmail">
                            <input pInputText type="email" [disabled]="isViewOnly" [(ngModel)]="registerForm.value"
                                pattern="^[a-z0-9._-]+@[a-z]+\.[a-z]{2,4}$" required />
                        </ng-container>
                    </ng-template>
                </div>
            </ng-container>
        </div>

        <div class="p-grid p-jc-end p-mt-2">
            <button pButton type="button" label="Cancel" (click)="closeAddPopup()"
                class="btnCss popupcancel p-mr-2"></button>
            <button pButton type="button" label="Save Changes" (click)="submitUser()"
                class="btnCss addBtn p-mr-2"></button>
        </div>
    </form>
</p-card>

<p-card>
    <div class="p-col-12 r_fontsize p-pl-0 p-pr-0">
        <p-table #dt [rows]="10" [paginator]="true" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [rowsPerPageOptions]="[10,20,30,40,50]" sortMode="multiple" [scrollable]="true" [style]="{width:'100%'}"
            scrollHeight="400px" [value]="users" [globalFilterFields]="['emailId','userRole']">
            <ng-template pTemplate="caption">
                <div class="p-grid">
                    <div class="p-grid p-col-12">
                        <!-- <div class="p-col-12 p-md-3 p-sm-6">
                            <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                                selectedItemsLabel="{0} columns selected" placeholder="Toggle columns"></p-multiSelect>
                        </div> -->
                        <div class="p-col-12 p-md-3 p-sm-6">
                            <span class="p-input-icon-left p-ml-auto">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search keyword" />
                            </span>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Actions</th>
                    <th pSortableColumn="emailId">Email <p-sortIcon field="emailId"></p-sortIcon>
                    </th>
                    <th pSortableColumn="userRole">Role <p-sortIcon field="userRole"></p-sortIcon>
                    </th>
                    <th>Active</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-user let-columns="columns" let-ri="rowIndex">
                <tr [pEditableRow]="user">
                    <td>
                        <a (click)="editUser(user.id, 'view')" class="viewcion p-pr-3"><i class="pi pi-eye"></i></a>
                        <a (click)="editUser(user.id, 'edit')" class="statusicon p-pr-3"><i
                                class="pi pi-compass"></i></a>
                        <a (click)="deleteUser(user.id)" class="statusicon"><i class="pi pi-trash"></i></a>
                    </td>
                    <td>{{user.emailId}}</td>
                    <td>{{user.accountRole.name}}</td>
                    <td>{{user.active ? "Active" : "Inactive"}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-card>
<p-toast></p-toast>
<p-confirmDialog #cd [style]="{width: '50vw'}" rejectButtonStyleClass="p-button-text">
    <ng-template pTemplate="header">
        <h3>Header Content</h3>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-arrow-right" label="Yes" (click)="cd.accept()"></button>
        <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
    </ng-template>
</p-confirmDialog>