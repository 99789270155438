import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    UrlTree
  } from '@angular/router';
  import { Injectable } from '@angular/core';
  import { Observable } from 'rxjs';
  import { map } from 'rxjs/operators';
  import { ClaimsService } from '../claims.service';
  
  @Injectable({ providedIn: 'root' })
  export class AuthGuard implements CanActivate {
    constructor(private claimService: ClaimsService, private router: Router) {}
  
    canActivate(
      route: ActivatedRouteSnapshot,
      router: RouterStateSnapshot
    ):
      | boolean
      | UrlTree
      | Promise<boolean | UrlTree>
      | Observable<boolean | UrlTree> {
      return this.claimService.user.pipe(
        map(user => {
          const isAuth = !!user;
          if (isAuth) {
            return true;
          }
          this.router.navigate(['login'], { queryParams: { returnUrl: router.url }});
          return false;
        })
      );
    }
  }
  