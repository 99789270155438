<p-card>
    <div class="p-grid">
        <div class="p-col-12 p-lg-12 p-md-12 p-sm-12 p-pt-0 p-pb-0 dropselection">
            <div class="p-grid">
                <div class="p-col-6 p-lg-2 p-md-4 p-sm-12 p-pt-0 p-pb-0 dropselection">
                    <!-- <label for="">Group</label> -->
                    <p-dropdown [options]="policyGrp" [(ngModel)]="selectedPolicy" (onChange)="onPolicyChange($event)">
                    </p-dropdown>
                </div>
                <div class="p-col-6 p-lg-2 p-md-4 p-sm-12 p-pt-0 p-pb-0 dropselection">
                    <p-dropdown [options]="claims" [(ngModel)]="selectedClaim" (onChange)="onClaimsChange($event)">
                    </p-dropdown>
                </div>
            </div>
        </div>
        <p-header class="p-col-12 p-pt-0 p-pb-0 subheading">
            Download or receive the filtered data.
        </p-header>
        <div class="p-col-12" style="position:relative;">
            <button pButton type="button" label="Download xls" (click)="dt.exportCSV()"
                class="btnCss downloadBtn poscss p-mr-2 p-mt-4">
            </button>
            <button pButton type="button" label="Add New" (click)="registerClaim()"
                class="btnCss downloadBtn regposs p-mr-2 p-mt-4"> </button>
            <p-accordion>
            </p-accordion>
        </div>
    </div>

</p-card>

<p-card>
    <div class="p-col-12 r_fontsize p-p-0">
        <p-table #dt [exportFilename]="exportName" [columns]="selectedColumnsList" [value]="claimsList"
            styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" editMode="row" dataKey="id"
            [style]="{width:'100%'}" [globalFilterFields]="globalFilters" [rows]="10" [paginator]="true"
            [showCurrentPageReport]="true" [(first)]="first"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [rowsPerPageOptions]="[10,20,30,40,50]" sortMode="multiple" [scrollable]="true" [style]="{width:'100%'}">
            <ng-template pTemplate="caption">
                <div class="p-grid">
                    <div class="p-col-12 p-lg-4 p-md-6 p-sm-12 p-pb-0">
                        <div class="p-col-12 p-pb-0">
                        </div>
                    </div>
                    <div class="p-col-12 p-lg-8 p-md-6 p-sm-12 p-pr-0 p-pb-0">
                        <div class="p-grid p-col-12 p-jc-end">
                            <div class="p-col-12 p-lg-6 p-md-6 p-sm-6 p-pb-0">
                                <p-multiSelect [options]="columnsList" [(ngModel)]="selectedColumnsList"
                                    optionLabel="header" optionValue="header" placeholder="Toggle columns"
                                    (onChange)="saveSelectedColumns()">
                                </p-multiSelect>
                            </div>
                            <div class="p-col-12 p-lg-6 p-md-6 p-sm-6 p-pb-0">
                                <div class="p-inputgroup">
                                    <input type="text" pInputText placeholder="Search"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')">
                                    <button type="button" pButton pRipple icon="pi pi-search"
                                        class="btnCss searchbtn"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr *ngIf=columns>
                    <th style="width:80px">Actions</th>
                    <th *ngFor="let col of columns" style="width:100px" pSortableColumn="col.header">{{col.header}}
                        <p-sortIcon field="col.header">
                        </p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-claims let-columns="columns" let-ri="rowIndex">
                <tr [pEditableRow]="claims">
                    <td style="width:80px">
                        <a (click)="goToRegisterClaim(claims.id, 'view')" class="viewcion p-pr-3"><i
                                class="pi pi-eye"></i></a>
                        <a (click)="goToRegisterClaim(claims.id, 'edit')" class="statusicon p-pr-3"><i
                                class="pi pi-compass"></i></a>
                        <a (click)="deleteJob(claims.id)" class="statusicon"><i class="pi pi-trash"></i></a>
                    </td>
                    <td *ngFor="let col of columns" style="width:100px">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <ng-container *ngIf="claims.isUpdate == true && !claims.isEditable; else noUpdate">
                                    {{claims[col.field]}}
                                </ng-container>
                                <ng-template #noUpdate>
                                    <input pInputText type="text" [(ngModel)]="claims[col.field]" name="col.field"
                                        #col.field="ngModel" />
                                </ng-template>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{claims[col.field]}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-card>