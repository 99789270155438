import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ClaimsService } from 'src/app/services/claims.service';

@Component({
  selector: 'app-configuration-emails',
  templateUrl: './configuration-emails.component.html',
  styleUrls: ['./configuration-emails.component.scss']
})
export class ConfigurationEmailsComponent implements OnInit {

  ItemCode: any = "";
  emailJobId: any = "";
  emailTemplatesList: any[];
  emailTemplates: any[];
  emailBodyTemplate: any;
  fromEmail: any = "";
  toEmail: any = "";
  subject: any = "";
  emailTemplateForm: FormGroup;
  emailTemplateName: any = "";
  loginEmail: any = "";

  selectedTemplate: any;

  constructor(private router: Router, private formBuilder: FormBuilder, private claimsService: ClaimsService) {
    this.initializeForm();
  }

  ngOnInit(): void {
    this.getEmailTemplates();
    this.loginEmail = localStorage.getItem('loginUserName');
  }

  initializeForm() {
    this.emailTemplateForm = this.formBuilder.group({
      EmailTemplateId: [null],
      EmailTemplateName: [null],
      FromEmail: [null],
      Subject: [null],
      EmailBody: [null]
    });
  }

  conf_EmailbasedJobUpdate() {
    this.router.navigate(['/ClaimModule/conf_EmailbasedJobUpdate']);
  }

  addNewEmailTemplate() {
    this.router.navigate(['/ClaimModule/registerJob/EmailNotificationTemplates']);
  }

  currDiv: string = 'A';

  ShowDiv(divVal: string) {
    this.currDiv = divVal;
  }

  getEmailTemplates() {
    this.emailTemplatesList = [];
    this.claimsService.getEmailTemplates()
      .subscribe((res) => {
        if (res["status"] == 1) {
          this.emailTemplates = res['jobs'];

          if (this.emailTemplates.length != 0) {
            this.emailBodyTemplate = this.emailTemplates[0]['EmailBody'];
            this.emailTemplateName = this.emailTemplates[0]['EmailTemplateName'];            
            this.fromEmail = this.emailTemplates[0]['FromEmail'];
            this.toEmail = this.emailTemplates[0]['ToEmail'];
            this.subject = this.emailTemplates[0]['Subject'];
            this.ItemCode = this.emailTemplates[0]['ItemCode'];
            this.emailJobId = this.emailTemplates[0]['id'];    
            
            this.selectedTemplate= this.emailTemplates[0];

            this.emailTemplateForm.setValue({
              EmailTemplateId: this.emailTemplates[0]['id'],
              EmailTemplateName: this.emailTemplateName,
              FromEmail: this.fromEmail,
              Subject: this.subject,
              EmailBody: this.emailBodyTemplate
            });
          }

        }
      },
        (error: any) => console.log(error)
      );
  }

  showEmailTemplate(templateDetails) {
    var emailId = templateDetails['id'];
    var emailTemplate = this.emailTemplates.filter(e => e.id === templateDetails.id);

    const index = this.emailTemplates.findIndex(e => e.id === templateDetails.id);

    if (emailTemplate.length != 0) {
      this.emailBodyTemplate = emailTemplate[0]['EmailBody'];
      this.emailTemplateName = emailTemplate[0]['EmailTemplateName'];
      this.fromEmail = emailTemplate[0]['FromEmail'];
      this.toEmail = emailTemplate[0]['ToEmail'];
      this.subject = emailTemplate[0]['Subject'];
      this.ItemCode = emailTemplate[0]['ItemCode'];
      this.emailJobId = emailId;  

      this.selectedTemplate = this.emailTemplates[index];

      this.emailTemplateForm.setValue({
        EmailTemplateId: emailTemplate[0]['id'],
        EmailTemplateName: this.emailTemplateName,
        FromEmail: this.fromEmail,
        Subject: this.subject,
        EmailBody: this.emailBodyTemplate
      });
      
      console.log("this.emailTemplates", this.emailTemplates);
    }
  }

  updateEmail(value) {   
    if(value) {
      this.router.navigate(['/ClaimModule/editJob/EmailNotificationTemplates', value.EmailTemplateId, 'edit']);      
    }
  }
}
