import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable, BehaviorSubject, Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { User } from './auth/user.model';
import { Router } from '@angular/router';
import { ModuleConstants as MC } from '../components/module-constants';

@Injectable({
  providedIn: 'root',
})
export class ClaimsService {
  user = new BehaviorSubject<User>(null);
  loader = new Subject<boolean>();
  //apiURL: string = 'http://raymond.mmcdevops.com:8081/';
  // apiURL: string = 'http://gagrovet.insuranceportfolio.in:8081/';
  //apiURL: string = 'https://gagrovet.insuranceportfolio.in:9081/';
   //apiURL: string = 'http://3.109.201.244:8081/';
    apiURL: string = 'https://sa1.insuranceportfolio.in:9081/';
   //apiURL: string = 'http://localhost:8081/';

  // apiURL: string = 'http://arcelormittal.insuranceportfolio.in:8081/';
  // apiURL: string = 'https://gcpladmin.insuranceportfolio.in:9081/';
  // apiURL: string = 'http://gcpladmin.insuranceportfolio.in:8081/';
  // apiURL: string = 'http://hmel.insuranceportfolio.in:8081/';
  // apiURL: string = 'http://gcpl.insuranceportfolio.in:8081/';

  curdate = new Date().getTime();
  tokenExpirationTimer;

  constructor(private httpClient: HttpClient, private router: Router) {}
  updateUser(user) {
    this.user.next(user);
  }

  autoLogin() {
    const userData: {
      userName: string;
      name: string;
      expirationDate: Date;
    } = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      return;
    }

    const loadedUser = new User(
      userData.userName,
      userData.name,
      new Date(userData.expirationDate)
    );

    if (loadedUser.userName) {
      this.user.next(loadedUser);
      const expirationDuration =
        new Date(userData.expirationDate).getTime() - new Date().getTime();
      this.autoLogout(expirationDuration);
    }
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  logout() {
    this.user.next(null);
    this.router.navigate(['/login']);
    localStorage.removeItem('userData');
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error.message || 'Server Error!');
  }

  getClaimTypes(claimType = MC.CLAIM_MODULE_VALUE): Observable<any> {
    return this.httpClient.get(
      this.apiURL +
        'Services/getMasters?type=' +
        claimType +
        '&mallId='+MC.MALL_ID+
        '&cache_bust=' +
        this.curdate
    );
  }

  getUserAccessDetails(userId): Observable<any> {
    let curdate = new Date().getTime();
    return this.httpClient.get(
      this.apiURL +
        'api/management/access?orgId='+MC.MALL_ID+'&userId=' +
        userId +
        '&cache_bust=' +
        curdate
    );
  }
  getMasterClaimTypes(input): Observable<any> {
    let userId = localStorage.getItem('loginUserId');
    return this.httpClient.get(
      this.apiURL +
        'Services/getMasters?type=' +
        input.label +
        '&mallId='+MC.MALL_ID+
        '&userId=' +
        userId +
        '&cache_bust=' +
        this.curdate
    );
  }

  findByCredentials(inputData) {
    return this.httpClient.get(
      this.apiURL +
        'MobileAPIs/findByCredentials?username=' +
        inputData.email +
        '&password=' +
        inputData.password
    );
  }

  getAllMasterTypeJobsList(input) {
    var url =
      this.apiURL +
      'Services/getMasters?type=allJobTypes|' +
      input.label +
      '&mallId='+MC.MALL_ID+'&dt=CAMPAIGNS';
    return this.httpClient.get(url + '&cache_bust=' + this.curdate);
  }

  resetPassword(input) {
    return this.httpClient.get(
      this.apiURL +
        'MobileAPIs/businessUserChangePwd?cPassword=' +
        input.currentPassword +
        '&nPassword=' +
        input.newPassword +
        '&nrPassword=' +
        input.confirmPassword +
        '&email=' +
        input.email +
        '&remail=' +
        input.remail
    );
  }

  updateConsumerJobMetaData(input) {
    return this.httpClient.get(
      this.apiURL +
        'Services/updateConsumerJobMetaData?op=' +
        input.jobSeqNo +
        '&userId=' +
        input.userId +
        '&jobId=' +
        input.jobId
    );
  }

  getSingleMasterJob(input) {
    return this.httpClient.get(
      this.apiURL +
        'Services/getMasters?jobId=' +
        input.claimId +
        '&cache_bust=' +
        this.curdate
    );
  }

  getDocumentsStatus(input) {
    var string =
      this.apiURL + 'api/v2/jobs/status?userId='+input.userId+'&jobTypeId=' + input.typeId;
    console.log('string:::', string);

    let url = '';

    if (input.filters) {
      url =
      this.apiURL +
        'api/v2/jobs/status?userId='+input.userId+'&jobTypeId=' +
        input.typeId +
        '&cache_bust=' +
        this.curdate +
        '&filters=' +
        input.filters;
    } else {
      url =
        this.apiURL +
        'api/v2/jobs/status?userId='+input.userId+'&jobTypeId=' +
        input.typeId +
        '&cache_bust=' +
        this.curdate;
    }

    console.log('string1:::', url);

    return this.httpClient.get(url);
  }

  getSingleMallDetails() {
    var string = this.apiURL + 'Services/getMasters?type=singleMall&mallId='+MC.MALL_ID;
    console.log('mall details:::', string);
    return this.httpClient.get(
      this.apiURL +
        'Services/getMasters?type=singleMall&mallId='+MC.MALL_ID+
        '&cache_bust=' +
        this.curdate
    );
  }

  saveSubJob(inputData) {
    var string =
      this.apiURL +
      'MobileAPIs/postedJobs?userId='+MC.MALL_ID +
      '&consumerEmail=' +
      inputData.clientEmail +
      '&type=' +
      inputData.subFormName +
      '&json={"list":' +
      inputData.subFormList +
      '}&dt=CAMPAIGNS&category=Services&parentJobId=' +
      inputData.jobId;
    console.log('string::::', string);
    return this.httpClient.get(
      this.apiURL +
        'MobileAPIs/postedJobs?userId='+MC.MALL_ID +
        '&consumerEmail=' +
        inputData.clientEmail +
        '&type=' +
        inputData.subFormName +
        '&json={"list":' +
        inputData.subFormList +
        '}&dt=CAMPAIGNS&category=Services&parentJobId=' +
        inputData.jobId
    );
  }

  saveSubJobPost(inputData) {
    const url = this.apiURL + 'api/jobs/create';
    const payLoad = new FormData();
    payLoad.append('userId', MC.MALL_ID+'');
    payLoad.append('consumerEmail', inputData.clientEmail);
    payLoad.append('type', inputData.subFormName);
    payLoad.append('json', '{"list":' + inputData.subFormList + '}');
    payLoad.append('dt', 'CAMPAIGNS');
    payLoad.append('category', 'Services');
    payLoad.append('parentJobId', inputData.jobId);

    if (inputData.files && inputData.files.length > 0) {
      inputData.files.forEach((file) => {
        payLoad.append('srcFile', file, file.name);
      });
    }
    return this.httpClient.post(url, payLoad);
  }

  saveMainJob(inputData) {
    var string =
      this.apiURL +
      'MobileAPIs/postedJobs?userId='+MC.MALL_ID +
      '&consumerEmail=' +
      inputData.clientEmail +
      '&type=' +
      inputData.subFormName +
      '&json={"list":' +
      inputData.subFormList +
      '}&dt=CAMPAIGNS&category=Services';

    if (inputData.ItemCode) {
      string = string + '&ItemCode=' + inputData.ItemCode;
    }
    console.log('string::::', string);
    return this.httpClient.get(string);
  }

  saveMainJobPost(inputData) {
    const url = this.apiURL + 'api/jobs/create';
    const payLoad = new FormData();
    payLoad.append('userId', MC.MALL_ID+'');
    payLoad.append('consumerEmail', inputData.clientEmail);
    payLoad.append('type', inputData.subFormName);
    payLoad.append('json', '{"list":' + inputData.subFormList + '}');
    payLoad.append('dt', 'CAMPAIGNS');
    payLoad.append('category', 'Services');

    if (inputData.files && inputData.files.length > 0) {
      inputData.files.forEach((file) => {
        payLoad.append('srcFile', file, file.name);
      });
    }
    return this.httpClient.post(url, payLoad);
  }

  updateMainJob(inputData) {
    var url =
      this.apiURL +
      'MobileAPIs/updateMultipleProperties?ownerId='+MC.MALL_ID+'&jobId=' +
      inputData.jobId +
      '&jsonString=' +
      inputData.subFormList;

    console.log('update job url:::', JSON.stringify(url));
    return this.httpClient.get(url);
  }

  getDashboardMenuItems(inputData) {
    return this.httpClient.get(
      this.apiURL + 'api/navigation/tabs?userId=' + inputData.userId
    );
  }

  getEmailTemplates() {
    return this.httpClient.get(
      this.apiURL +
        'Services/getMasters?type=EmailNotificationTemplates&mallId='+MC.MALL_ID+
        '&cache_bust=' +
        this.curdate
    );
  }

  downloadPdf(inputData) {
    // var string = this.apiURL + 'api/reports/jobs/status/download/pdf?jobTypeId=' + inputData.jobType;

    // console.log("download pdf api::::", string);

    // return this.httpClient.get(this.apiURL + 'api/reports/jobs/status/download/pdf?jobTypeId=' + inputData.jobType);

    const url =
      this.apiURL +
      'RenderCsvFile/downloadDashboardPDF?userId='+inputData.userId+'&jobTypeId=' +
      inputData.jobType;

    const headers = new HttpHeaders({
      Accept: 'application/pdf',
    });

    return this.httpClient.get(url, { headers, responseType: 'blob' });
  }

  removeFileAttachments(inputData) {
    return this.httpClient.get(
      this.apiURL + 'api/jobs/remove/attachments',
      inputData
    );
  }

  addFileAttachments(inputData) {
    return this.httpClient.post(
      this.apiURL + 'JobsController/uploadAttachments',
      inputData
    );
  }

  claimsSpecialFilterSearch(inputData) {
    let finaldata = {
      mallId: MC.MALL_ID,
      type: null,
      jobName: null,
      pageNumber: 0,
      pageSize: 0,
      unlimited: false,
      skipCount: false,
      sortBy: null,
      keyWord: null,
      isSortOrderAsc: false,
      jobId: 0,
      selectRefPropFldName: null,
      myJobs: false,
      consumerId: 0,
      typeIsDT: true,
      userAccountId: null,
      filterJson: {
        jobTypeId: inputData.jobTypeId,
        orgId: MC.MALL_ID,
        list: JSON.stringify(inputData.filters),
      },
    };

    let filterJson = {
      jobTypeId: inputData.jobTypeId,
      orgId: MC.MALL_ID,
      list: JSON.stringify(inputData.filters),
    };
    let filterJsonV1 = {
      list: inputData.filters,
    };
    console.log('finalurl::', this.apiURL + 'api/search/list');
    console.log('finalData::', finaldata);

    //   return this.httpClient.post(this.apiURL + 'api/search/list', inputData);
    let userId = localStorage.getItem('loginUserId');
    let curdate = new Date().getTime();
    console.log(
      'final urls::',
      this.apiURL +
        'Services/getMasters?type=' +
        inputData.jobLabel +
        '&mallId=' +MC.MALL_ID+
        '&userId=' +
        userId +
        '&cache_bust=' +
        curdate +
        '&filterJson=' +
        JSON.stringify(filterJsonV1)
    );
    return this.httpClient.get(
      this.apiURL +
        'Services/getMasters?type=' +
        inputData.jobLabel +
        '&mallId='+MC.MALL_ID +
        '&userId=' +
        userId +
        '&cache_bust=' +
        curdate +
        '&filterJson=' +
        JSON.stringify(filterJsonV1)
    );
  }

  saveEmailTemplates(inputData) {
    var string =
      this.apiURL +
      'MobileAPIs/postedJobs?userId='+inputData.userId +
      '&consumerEmail=' +
      inputData.clientEmail +
      '&type=EmailNotificationTemplates' +
      '&json=' +
      inputData.emailFormList +
      '&dt=CAMPAIGNS&category=Services';

    if (inputData.ItemCode) {
      string = string + '&ItemCode=' + inputData.ItemCode;
    }
    console.log('string::::', string);
    return this.httpClient.get(
      this.apiURL +
        'MobileAPIs/postedJobs?userId='+inputData.userId +
        '&consumerEmail=' +
        inputData.clientEmail +
        '&type=EmailNotificationTemplates' +
        '&json=' +
        inputData.emailFormList +
        '&dt=CAMPAIGNS&category=Services'
    );
  }

  sendEmail(inputData) {
    var url =
      this.apiURL +
      'api/jobs/status/email?jobId=' +
      inputData.jobId +
      '&userId=' +
      inputData.userId +
      '&recipientEmail=' +
      inputData.recipientEmail +
      '&emailTemplateId=' +
      inputData.emailTemplateId;

    console.log('send email url:::', url);
    return this.httpClient.get(url);
  }

  deleteMainJob(inputData) {
    return this.httpClient.get(
      this.apiURL + 'api/jobs/delete?jobId=' + inputData.jobId
    );
  }

  //To get master claim types (VJ 01-08-2021)
  getMastersData(): Observable<any> {
    return this.httpClient.get(this.apiURL + 'Services/getMasters?type=C_masterSubCategories&mallId='+MC.MALL_ID+ '&cache_bust=' + this.curdate);
  }

  getRolesOrUsers(category:string, type:string,queryParam? : string){
    return this.httpClient.get(this.apiURL + `api/management/${category}/${type}?${queryParam}`);
  }
  postRolesOrUsersData(category:string, type:string,data : {},queryParam? : string){
    return this.httpClient.post<any>(this.apiURL + `api/management/${category}/${type}?${queryParam}`,data);
  }
}
