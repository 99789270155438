<!-- <p-card>
    <div class="p-col-12" style="position: relative;">
        <button pButton type="button" label="Add New Role" class="btnCss newrowBtn p-mr-2 p-mt-2"
            (click)="showBasicDialog()">
        </button>
    </div>
    <form [formGroup]="roleForm" *ngIf="toggleRoleForm">
        <div class="p-grid">
            <div class="p-col-12 p-md-6">
                <label>Role Title</label>
                <input formControlName="name" type="text" pInputText>
            </div>
            <div class="p-col-12 p-md-6">
                <label>Role Description</label>
                <input formControlName="description" type="text" pInputText>
            </div>
        </div>

        <div class="p-grid p-jc-end p-mt-2">
            <button pButton type="button" label="Cancel" (click)="closeAddPopup()"
                class="btnCss addBtn p-mr-2"></button>
            <button pButton type="button" label="Save Changes" (click)="submitRole()"
                class="btnCss popupcancel p-mr-2"></button>
        </div>
    </form>
</p-card> -->

<p-card>
    <div class="p-col-12 r_fontsize p-pl-0 p-pr-0">
        <p-table #dt [rows]="10" [paginator]="true" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [rowsPerPageOptions]="[10,20,30,40,50]" sortMode="multiple" [scrollable]="true" [style]="{width:'100%'}"
            scrollHeight="200px" [value]="roles" [globalFilterFields]="['name','description']">
            <ng-template pTemplate="caption">
                <div class="p-grid">
                    <div class="p-grid p-col-12">
                        <!-- <div class="p-col-12 p-md-3 p-sm-6">
                            <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                                selectedItemsLabel="{0} columns selected" placeholder="Toggle columns"></p-multiSelect>
                        </div> -->
                        <div class="p-col-12 p-md-3 p-sm-6">
                            <span class="p-input-icon-left p-ml-auto">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search keyword" />
                            </span>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <!-- <th>Actions</th> -->
                    <th pSortableColumn="name">Role Title <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <!-- <th>Role Description</th> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-role let-columns="columns" let-ri="rowIndex">
                <tr [pEditableRow]="role">
                    <!-- <td>
                        <a (click)="editRole(role.id, 'view')" class="viewcion p-pr-3"><i class="pi pi-eye"></i></a>
                        <a (click)="editRole(role.id, 'edit')" class="statusicon p-pr-3"><i
                                class="pi pi-compass"></i></a>
                        <a (click)="deleteRole(role.id)" class="statusicon"><i class="pi pi-trash"></i></a>
                    </td> -->
                    <td>{{role.name}}</td>
                    <!-- <td>{{role.Description}}</td> -->
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-card>
<p-toast></p-toast>
<!-- <div class="popupviewClaim">
    <p-dialog header="Add a new row" [(visible)]="toggleRoleForm" class="popupviewClaim" [baseZIndex]="10000"
        [draggable]="false" [resizable]="false">
    </p-dialog>
</div> -->