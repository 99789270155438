export class ModuleConstants {
    public static CLAIM_MODULE: string = "LossMitigation";
    public static CLAIM_MODULE_VALUE: string = "cClaimCategories";

    public static PORTFOLIO_MODULE: string = "PortfolioModule";
    public static PORTFOLIO_MODULE_VALUE: string = "iPortfolioSubCategories";
    public static PORTFOLIO_MODULE_SUB_CAT: string = "iPortfolioSubCategories";
    public static PORTFOLIO_MODULE_SUB_SUB_CAT: string = "iPortfolio3rdSubCategories";

    public static MALL_ID: number = 43;


/*
apiURL: string = 'https://sa1.insuranceporfolio.in:9081/';

32	Vedanta2	vedanta2@cokube.com
26	Siemens2		siemens2@cokube.com
21	Jubilant FoodWorks	Jubilant@cokube.com
17	IOB		iobadmin@cokube.com
13	Allied Blenders And Distillers		adb@cokube.com
11	Indian bank
*/


    public static DISPLAY_TEXT_MAX_LENGTH: number = 25;
}
