import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimsService } from '../../../services/claims.service';
import { ModuleConstants as MC } from '../../module-constants';

@Component({
  selector: 'app-view-claim',
  templateUrl: './view-claim.component.html',
  styleUrls: ['./view-claim.component.scss'],
})
export class ViewClaimComponent implements OnInit {
  displayBasic: boolean;
  activeState: boolean = false;
  claims: any[];
  selectedClaim: any;
  claimsList: any[];
  value: Date;
  fields: any;
  selectedField: any;
  clonedClaimsLst: any;
  columns: any[];
  selectedColumn: any[];
  first: number = 0;
  globalFilters: any = [];
  selectedColumnsList: any[];
  columnsList: any[];
  selectedClaimType: string = '';
  claimTypeLabel: string = '';
  jobType: any = '';
  jobStatus: any = '';
  fieldsList: any[];
  conditionsList: any[];
  searchForm: FormGroup;
  columnsForTable: any = [];
  exportName = 'Claims';
  currentGridType = '';
  defaultSelectedClaimType;
  selectedStatus = '';

  constructor(
    private router: Router,
    private claimsService: ClaimsService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    console.log('on inti of view');
    this.route.fragment.subscribe((fragment: string) => {
      this.defaultSelectedClaimType = fragment;
    });
    this.selectedStatus = this.route.snapshot.paramMap.get('selectedStatus');
    this.fields = [
      { name: 'Dates', code: 'dt' },
      { name: 'Claim No', code: 'nm' },
      { name: 'Net Premium', code: 'np' },
    ];

    this.columnsForTable = [
      {
        field: 'Claim_no',
        header: 'Claim No.',
      },
      {
        field: 'Insurer_name',
        header: 'Insured',
      },
      {
        field: 'Date_of_loss',
        header: 'Date of Loss',
      },
      {
        field: '',
        header: 'Intimation from Insured',
      },
      {
        field: 'Brief Description Of loss',
        header: 'Cause of Loss',
      },
      {
        field: 'Claim Type',
        header: 'Claim Type',
      },
      {
        field: 'Loss_estimate_Or_Claim_amount',
        header: 'Intimated Loss Amount(INR)',
      },
      {
        field: 'Claim_status',
        header: 'Claim Status',
      },
    ];

    this.getClaimTypes();

    this.conditionsList = [
      { label: 'is', value: 'is' },
      { label: 'is less than', value: 'is less than' },
      { label: 'is greater than', value: 'is greater than' },
      { label: 'is between', value: 'is between' },
      { label: 'contains', value: 'contains' },
      { label: 'between', value: 'between' },
    ];
  }

  initializeForm() {
    this.searchForm = this.fb.group({
      quantities: this.fb.array([]),
    });
  }

  quantities(): FormArray {
    return this.searchForm.get('quantities') as FormArray;
  }

  newSearchForm(): FormGroup {
    return this.fb.group({
      fieldName: [null],
      condition: [null],
      comparision: [null],
    });
  }

  addQuantity() {
    this.quantities().push(this.newSearchForm());
  }

  removeQuantity(i: number) {
    this.quantities().removeAt(i);
  }

  showBasicDialog() {
    this.displayBasic = true;
  }

  registerClaim() {
    var selectedClaim = this.selectedClaimType;
    this.router.navigate(['/'+MC.PORTFOLIO_MODULE+'/registerJob', selectedClaim]);
  }

  onRowEditInit(claimsrow: any) {
    if (
      this.claimsList.filter((row) => row.isEditable && row.isUpdate == false)
        .length > 0
    ) {
      this.claimsList.splice(0, 1);
    }

    if (this.claimsList.filter((row) => row.isEditable).length > 0) {
      const index = this.claimsList.findIndex(
        (data) => data['id'] == this.clonedClaimsLst.id
      );
      this.claimsList[index] = this.clonedClaimsLst;
    }

    this.claimsList
      .filter((row) => row.isEditable)
      .map((r) => {
        r.isEditable = false;
        return r;
      });
    claimsrow.isEditable = true;

    this.clonedClaimsLst = { ...claimsrow };
  }

  onRowEditCancel(claims: any, index: number) {
    if (!claims.isUpdate) {
      this.claimsList.splice(index, 1);
    } else {
      let clndCGA = this.clonedClaimsLst;
      clndCGA.isEditable = false;
      this.claimsList[index] = clndCGA;
    }
  }

  onRowEditSave(claims: any) {}

  getClaimTypes() {
    this.claimsService.getClaimTypes(MC.PORTFOLIO_MODULE_VALUE).subscribe(
      (res) => {
        if (res['status'] == 1) {
          let claimsArray = [];
          res?.jobs?.forEach((element) => {
            claimsArray.push({
              label: element.Name,
              value: element.jobTypeId,
            });
          });
          this.selectedClaimType = claimsArray[0].label;
          this.selectedClaim = claimsArray[0];
          if (this.defaultSelectedClaimType) {
            const selectedClaim = claimsArray.filter(
              (claim) => claim.label === this.defaultSelectedClaimType
            );
            if (selectedClaim && selectedClaim.length > 0) {
              this.selectedClaim = selectedClaim[0];
              this.selectedClaimType = this.defaultSelectedClaimType;
            }
          }
          this.claims = claimsArray;
          this.getAllMasterTypeJobsList(1);
        }
      },
      (error: any) => console.log(error)
    );
  }

  saveSelectedColumns() {
    localStorage.setItem(
      this.currentGridType,
      JSON.stringify(this.selectedColumnsList)
    );
  }

  getAllMasterTypeJobsList(type) {
    localStorage.removeItem('claimStatus');

    this.columns = [];
    console.log('getAllMasterTypeJobsList input::::', this.selectedClaimType);
    var inputData = {
      label: this.selectedClaimType,
    };

    this.claimsService.getAllMasterTypeJobsList(inputData).subscribe(
      (res) => {
        if (res['status'] == 1) {
          var result = res['orgs'];
          this.fieldsList = result[0].Fields;
          this.currentGridType = result[0].type;

          const jobStatus = 'Current_Job_Status';
          this.globalFilters.push(jobStatus);
          this.columns.push({
            field: jobStatus,
            header: 'Status',
          });

          this.fieldsList.forEach((element) => {
            var fieldObj = {
              field: element.name,
              header: element.name.replace(/_/g, ' '),
            };
            this.globalFilters.push(element.name);
            this.columns.push(fieldObj);
          });
          const selectedColumnsList = JSON.parse(
            localStorage.getItem(this.currentGridType)
          );
          if (selectedColumnsList && selectedColumnsList.length > 0) {
            this.selectedColumnsList = selectedColumnsList;
          } else {
            const prorityCols = [
              {
                field: jobStatus,
                header: 'Status',
              },
            ];
            this.selectedColumnsList = this.fieldsList
              .filter((col) => col?.showInListView)
              .map((col) => {
                return {
                  field: col.name,
                  header: col.name.replace(/_/g, ' '),
                };
              });
            this.selectedColumnsList = [
              ...prorityCols,
              ...this.selectedColumnsList,
            ];
          }
          this.columnsList = this.columns;
          console.log('columns', JSON.stringify(this.columns));

          if (type == 1) {
            this.getMasterClaimTypes();
          }
        }
      },
      (error: any) => console.log(error)
    );
  }

  onClaimsChange() {
    this.claimsList = [];
    this.selectedClaimType = this.selectedClaim.label;
    window.location.hash = this.selectedClaim.label;
    var inputData = {
      label: this.selectedClaimType,
    };
    this.claimsService.getMasterClaimTypes(inputData).subscribe(
      (res) => {
        if (res['status'] == 1) {
          this.claimsList = this.trimJobIdFromSelectorValues(res.jobs);
          if (this.selectedStatus) {
            this.claimsList = this.claimsList.filter((list) => {
              return this.selectedStatus === list.Current_Job_Status;
            });
          }
          console.log('this.claimsList::', this.claimsList);
          this.getAllMasterTypeJobsList(2);
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  getMasterClaimTypes() {
    this.claimsList = [];
    var inputData = {
      label: this.selectedClaimType,
    };
    this.claimsService.getMasterClaimTypes(inputData).subscribe(
      (res) => {
        if (res['status'] == 1) {
          this.claimsList = this.trimJobIdFromSelectorValues(res.jobs);
          if (this.selectedStatus) {
            this.claimsList = this.claimsList.filter((list) => {
              return this.selectedStatus === list.Current_Job_Status;
            });
            console.log('this.claimsList1::', this.claimsList);
          }
          console.log('this.claimsList1::', this.claimsList);
          this.getAllMasterTypeJobsList(2);
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  trimJobIdFromSelectorValues(jobs) {
    return jobs.map((list) => {
      Object.keys(list).forEach((l) => {
        list[l] =
          typeof list[l] === 'string'
            ? list[l].replace(/[(][0-9]{4}[)]$/gi, '')
            : list[l];
      });
      return list;
    });
  }

  goToRegisterClaim(claimId, mode) {
    if (claimId) {
      this.router.navigate([
        '/'+MC.PORTFOLIO_MODULE+'/editJob',
        this.selectedClaimType,
        claimId,
        mode,
      ]);
    }
  }

  deleteJob(claimId) {
    var inputData = {
      jobId: claimId,
    };
    if (!confirm('Are you sure you want to delete this job?')) {
      return;
    } else {
      this.claimsService.deleteMainJob(inputData).subscribe(
        (res) => {
          var result = res['myHashMap'];
          if (result['status'] == 1) {
            alert(result['success']);
            this.getClaimTypes();
          } else {
            alert(res['error']);
          }
        },
        (error: any) => console.log(error)
      );
    }
  }
}
