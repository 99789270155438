import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-masters-user',
  templateUrl: './masters-user.component.html',
  styleUrls: ['./masters-user.component.scss']
})
export class MastersUserComponent implements OnInit {
  displayBasic: boolean;
  activeState: boolean = false;
  constructor(  private router: Router,) { }

  ngOnInit(): void {
  }

  showBasicDialog() {
    this.displayBasic = true;
  }
  goToAddUser(){
    this.router.navigate(['/ClaimModule/addUser']);
  }

}
