import { Component, OnInit } from '@angular/core';
// import { Role } from 'src/app/models/role';
import { ClaimsService } from 'src/app/services/claims.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import { Constants } from "../constants";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-users-roles',
  templateUrl: './users-roles.component.html',
  styleUrls: ['./users-roles.component.scss']
})
export class UsersRolesComponent implements OnInit {
  toggleRoleForm: boolean;
  roles = [];

  headerLabel : string = 'Add a new role';
  roleForm : FormGroup;
  isEdit : boolean = false;
  roleId : number;
  
  constructor(private cService : ClaimsService,private msg:MessageService,private fb: FormBuilder, private confirmService : ConfirmationService) {
    this.roleForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
    })
  }

  ngOnInit(): void {
    this.getRoles();
/*     setTimeout(() => {
      this.editRole(1,'edit')
    }, 10000); */
  }

  getRoles(){
    this.cService.getRolesOrUsers(Constants.ROLES,Constants.LIST,"orgId=3").subscribe((roles) => {
      if(roles['status'] == 1){
        // this.roles = roles['result'];
        this.roles = roles['data'];
      }
    },(error) => {
      this.msg.add({severity:'error', summary: 'Error', detail: 'Something went wrong please try again Error: '+error.message});
    })
  }
  deleteRole(roleId){}
  showBasicDialog() {
    this.roleForm.reset();
    this.toggleRoleForm = true;
  }
  closeAddPopup(){
    this.toggleRoleForm = false;
  }

  submitRole(){
    if(this.roleForm.valid){
      const roleObj = {
        userId : localStorage.getItem('loginUserId'),
        list : [{
          name : this.roleForm.get('name').value,
          description : this.roleForm.get('description').value,
          cannotdelete:false,
          isMasterRole:false,
        }]  
      }
      
      let actionType = '';
      if(!this.isEdit)
        actionType = Constants.CREATE;
      else{
        roleObj.list[0]['id'] = this.roleId;
        actionType = Constants.UPDATE;
      }
      console.log(roleObj);
      // return false
      this.cService.postRolesOrUsersData(Constants.ROLES,actionType,roleObj,'').subscribe((res) => {
      if(res['status'] === '1'){
        this.msg.add({severity:'success', summary: 'Success', detail: res['message']});
        this.getRoles();
        this.toggleRoleForm = false;
      }
      else
        this.msg.add({severity:'error', summary: 'Error', detail: res['message']});
     },(error) => this.errorHandling(error) )
    }
  }

  editRole(roleId,type){
    this.roleForm.reset();
    this.toggleRoleForm = this.isEdit = true;
    this.roleId = roleId;
    const selectedRole = this.roles.find((role) => role.id === roleId)
    this.roleForm.get('name').setValue(selectedRole.name)
    this.roleForm.get('description').setValue(selectedRole.description)
  }
  errorHandling(error){
    this.msg.add({severity:'error', summary: 'Error', detail: 'Something went wrong please try again Error: '+error.message});
  }
}
