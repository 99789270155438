<p-card>
    <div class="p-grid">
        <p-header class="p-col-12 p-pt-0 p-pb-0 header-title">
            ROLES
        </p-header>
    </div>
    <div class="p-col-12" style="position: relative;">
        <button pButton type="button" label="Register Role" class="btnCss downloadBtn addposs p-mr-2 p-mt-2" (click)="goToAddRoles()"> </button>       
        <button pButton type="button" label="Download Sample xls" class="btnCss downloadBtn downloadposs p-mr-2 p-mt-2"> </button>
       <p-accordion>
            <p-accordionTab header="Bulk Upload" [(selected)]="activeState">
                <div class="p-grid p-mt-4">
                    <div class="cardSection">
                        <p-card>
                            <div class="p-col-12 p-pl-0">
                                <div class="tableSubheader">
                                    Upload claim data
                                </div>
                            </div>
                            <div class="p-grid">
                                <div class="dynamicCtrlWrap">
                                <div class=" p-col-12 p-md-3">
                                    <label for="firstname">Document type</label>
                                    <p-dropdown optionLabel="name"></p-dropdown>
                                </div>
                                <div class="p-col-12 p-md-3">
                                    <label for="lastname">Document Name</label>
                                    <input id="fieldId" type="text" pInputText>
                                </div>
                                    <div class="p-col-12 p-md-3 center">
                                        <label for="firstname">Browse file</label>
                                        <ngx-file-drop
                                            dropZoneLabel="Drop files here"                                            
                                            accept=".pdf,.docx,.xlsx,.pptx"
                                            multiple="true"
                                        >
                                            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector" multiple="true">
                                                <div
                                                    style="width:100%;height:24px;flex:3;text-align: center;margin-top:1px;color: #646565;">
                                                 
                                                </div>
                                                <div style="width:100%;height:auto;flex:1;text-align: center;margin-top:0px;">
                                                    <button type="button">Browse Files</button>
                                                </div>
                                            </ng-template>
                                        </ngx-file-drop>
                                       
                                    </div>
                               
                                <div class="p-col-12  p-md-3">
                                    <label for="lastname">Description</label>
                                    <input id="fieldId" type="text" pInputText>
                                </div>
                                </div>

                                <div class="removeWrapperDiv diagnosisRemove">
                                    <i class="pi pi-times closeIcon_marginT"></i>
                                </div>
                            </div>
                            <div class="p-col-12 p-pl-0">
                                <button pButton type="button" class="btnCss submitBtn" label="Submit"></button>
                            </div>
                        </p-card>  
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>     
    </div>
</p-card>

<p-card>
    <div class="p-col-12 r_fontsize p-pl-0 p-pr-0">
        <p-table [scrollable]="true" [style]="{width:'100%'}" scrollHeight="200px">
            <ng-template pTemplate="caption">
                <div class="p-grid">
                    <div class="p-col-12 p-xl-4 p-lg-4 p-md-2 p-sm-12 p-pb-0">
                        <div class="p-col-12 p-pb-0">
                            <!-- <button pButton type="button" label="Claim Intimation" pAddRow [table]="dt"
                                [newRow]="newRow()" class="btnCss newrowBtn"> </button> -->
                        </div>
                    </div>
                    <div class="p-col-12 p-xl-8 p-lg-8 p-md-10 p-sm-12 p-pr-0 p-pb-0">
                        <div class="p-grid p-col-12 p-jc-end">
                            <div class="p-col-12 p-lg-6 p-md-6 p-sm-6 p-pb-0">
                                <!-- <p-multiSelect [options]="columnsList" [(ngModel)]="selectedColumnsList" optionLabel="header"
                                optionValue="header" placeholder="Toggle columns" (onChange)="saveSelectedColumns()">
                                </p-multiSelect> -->
                            </div>
                            <div class="p-col-12 p-lg-6 p-md-6 p-sm-6 p-pb-0">
                                <div class="p-inputgroup">
                                    <!-- <input type="text" pInputText placeholder="Search"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')">
                                    <button type="button" pButton pRipple icon="pi pi-search"
                                        class="btnCss searchbtn"></button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Actions</th>
                    <th>Roles</th>
                    <th>Properties</th>
                    <th>Feature Access</th>
                    <th>Job Level Access</th>                    
                    <th></th>                   
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product let-columns="columns">
                <tr>
                    <td style="width:80px">
                        <div class="p-d-flex p-flex-row p-jc-around">
                            <!-- <a title="View" (click)="goToRegisterClaim(claims.id, 'view')" class="viewcion"><i class="pi pi-eye"></i></a>
                        <a title="Edit" (click)="goToRegisterClaim(claims.id, 'edit')" class="statusicon"><i class="pi pi-compass"></i></a>
                        <a title="Delete" (click)="deleteJob(claims.id)" class="deleteicon"><i class="pi pi-trash"></i></a> -->
                        </div>
                    </td>
                    <td> </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                   
                </tr>
            </ng-template>
        </p-table>
    </div>
   
</p-card>


