import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItemGroup } from 'primeng/api';
import { ClaimsService } from 'src/app/services/claims.service';
import { ModuleConstants as MC } from "../../module-constants";

@Component({
  selector: 'app-main-master',
  templateUrl: './main-master.component.html',
  styleUrls: ['./main-master.component.scss']
})
export class MainMasterComponent implements OnInit {

  claims : Array<any> = [];
  policyGrp : Array<any> = [];
  claimsList : Array<any> = [];
  displayBasic: boolean;
  activeState: boolean = false;
  selectedClaim: any;
  value: Date;
  fields: any;
  selectedField: any;
  clonedClaimsLst: any;
  columns: any[];
  selectedColumn: any[];
  first: number = 0;
  globalFilters: any = [];
  selectedColumnsList: any[];
  columnsList: any[];
  selectedClaimType: string = "";
  selectedPolicy: string = "";
  claimTypeLabel: string = "";
  jobType: any = "";
  jobStatus: any = "";
  selectedClaimTypeLabel: string = "";
  claimStatusObj: any;
  fieldsList: any[];
  conditionsList: any[];
  searchForm: FormGroup;
  columnsForTable: any = [];
  exportName = 'Masters';
  currentGridType = '';
  claimsArray = {}
  setModuleValue = '';
  setModule = '';
  constructor(private claimsService:ClaimsService,private router:Router,private route: ActivatedRoute) {
    let moduleName = this.route.snapshot['_urlSegment']['segments'][0]['path'];
    switch (moduleName) {
      case MC.CLAIM_MODULE:
        this.setModuleValue = MC.CLAIM_MODULE_VALUE;
        this.setModule = MC.CLAIM_MODULE;
        break;
      case MC.PORTFOLIO_MODULE:
        this.setModuleValue = MC.PORTFOLIO_MODULE_VALUE;
        this.setModule = MC.PORTFOLIO_MODULE;
        break;
    
      default:
        break;
    }
   }

  ngOnInit(): void {
    this.getClaimTypes();
    this.claimStatusObj = {};

    this.conditionsList= [
      {label: "is", value:"is"},
      {label: "is less than", value:"is less than"},
      {label: "is greater than", value:"is greater than"},
      {label: "is between", value:"is between"},
      {label: "contains", value:"contains"},
      {label: "between", value:"between"}
    ];
    this.columnsForTable = [
      {
        field: 'Claim_no',
        header: 'Claim No.'
      },
      {
        field: 'Insurer_name',
        header: 'Insured'
      },
      {
        field: 'Date_of_loss',
        header: 'Date of Loss'
      },
      {
        field: '',
        header: 'Intimation from Insured'
      },
      {
        field: 'Brief Description Of loss',
        header: 'Cause of Loss'
      },
      {
        field: 'Claim Type',
        header: 'Claim Type'
      },
      {
        field: 'Loss_estimate_Or_Claim_amount',
        header: 'Intimated Loss Amount(INR)'
      },
      {
        field: 'Claim_status',
        header: 'Claim Status'
      },
    ];
  }

  getClaimTypes() {
    this.claimsService.getMastersData()
      .subscribe((res) => {

        if (res["status"] == 1) {

          var jobs = res.jobs;
          console.log("jobs:::", jobs);

          for (var i = 0; i < jobs.length; i++) {           

            this.claimStatusObj = localStorage.getItem('claimStatus');

            if (this.claimStatusObj) {
              this.claimStatusObj = JSON.parse(this.claimStatusObj);
              this.selectedClaimType = this.claimStatusObj['claimType'];
              this.claimTypeLabel = this.claimStatusObj['jobType'];
              this.jobType = this.claimStatusObj['jobType'];
              this.jobStatus = this.claimStatusObj['jobStatus'];

              if(this.jobStatus == 1) {
                var jobsArray = jobs.filter(j => j.Name === this.selectedClaimType);
                // console.log("jobsArray:::",jobsArray)
                var jobTypeValue = jobsArray[0].jobTypeId;
                this.selectedClaim = { label: this.selectedClaimType, value: jobTypeValue };
              } else if(this.jobStatus == 2) {
              }
            } else {
              this.selectedClaimType = jobs[0].TabName;
              this.selectedClaimTypeLabel = jobs[0].DisplayName;
              // this.selectedClaim = { label: jobs[0].Name, value: jobs[0].jobTypeId };
            }
            // console.log("this.selectedClaimType", this.selectedClaimType);

            var claimObj = {
              label: jobs[i].DisplayName,id: jobs[i].typeID, value: jobs[i].TabName
            }

            let check_grp = Object.keys(this.claimsArray).find(ele => ele === jobs[i].Group)
            if(!check_grp) this.claimsArray[jobs[i].Group] = [];
            this.claimsArray[jobs[i].Group].push(claimObj);
          }
          this.policyGrp = Object.keys(this.claimsArray).map(ele => ({ 'label' : ele, 'value' : ele }));
          this.claims = this.claimsArray[this.policyGrp[0]['label']];
          this.selectedPolicy = this.policyGrp[0]['label'];
          this.selectedClaim = this.claims[0]['label'];
          /* this.claims = Object.keys(claimsArray).map(ele => 
            ({
              'label' : ele,
              'value' : ele,
              'items' : claimsArray[ele]
            })
          ); */      
          console.log("this.claims", this.claims);
          console.log("this.policy", this.policyGrp);
          this.getAllMasterTypeJobsList(1);
        }
      },
        (error: any) => console.log(error)
      );
  }
  goToRegisterClaim(claimId, mode) {
    if (claimId) {
      this.router.navigate([
        '/'+this.setModule+'/editJob',
        this.selectedClaimType,
        claimId,
        mode,
      ]);
    }
  }
  onClaimsChange(event) {
    console.log(event)
    this.claimsList = [];
    this.selectedClaimType = event.value;
    this.selectedClaimTypeLabel = event.originalEvent?.target?.innerText;
    var inputData = {
      label: this.selectedClaimType
    }
    this.claimsService.getMasterClaimTypes(inputData)
      .subscribe((res) => {
        if (res["status"] == 1) {
          this.claimsList = res.jobs;
          this.getAllMasterTypeJobsList(2);
        }
      },
        (error: any) => console.log(error)
      );
  }
  onPolicyChange(event) {
    console.log(event)
    // this.selectedClaimType = event.value;
    this.claims = this.claimsArray[event.value];
    this.selectedClaimType = this.selectedClaim = this.claims[0]['value'];
    this.selectedClaimTypeLabel = this.claims[0]['label'];
    var inputData = {
      label: this.selectedClaimType
    }
    this.claimsService.getMasterClaimTypes(inputData)
      .subscribe((res) => {
        if (res["status"] == 1) {
          this.claimsList = res.jobs;
          this.getAllMasterTypeJobsList(2);
        }
      },
        (error: any) => console.log(error)
      );
  }

  getAllMasterTypeJobsList(type) {
    if(type == 1) {
      this.getMasterClaimTypes();
    }else{
      localStorage.removeItem('claimStatus')

      this.columns = [];
      var inputData = {
        label: this.selectedClaimType
      }   
      this.claimsService.getAllMasterTypeJobsList(inputData).subscribe((res) => {
        if (res["status"] == 1) {

          var result = res['orgs'];
          this.fieldsList = result[0].Fields
          this.currentGridType = result[0].type;

          const jobStatus = 'Current_Job_Status';
          this.globalFilters.push(jobStatus);
          this.columns.push({
            field: jobStatus,
            header: jobStatus.replace(/_/g, " ")
          });

          this.fieldsList.forEach(element => {
            var fieldObj = {
              field: element.name,
              header: element.name.replace(/_/g, " ")
            }
            this.globalFilters.push(element.name);
            this.columns.push(fieldObj);
            console.log(fieldObj)
          });
          const selectedColumnsList = JSON.parse(localStorage.getItem(this.currentGridType));
          if(selectedColumnsList && selectedColumnsList.length > 0) {
            this.selectedColumnsList = selectedColumnsList;
          } else {
            const prorityCols = [{
              field: jobStatus,
              header: jobStatus.replace(/_/g, " ")
            }];
            this.selectedColumnsList = this.fieldsList.filter(col => col?.showInListView).map(col => {
              return {
                field: col.name,
                header: col.name.replace(/_/g, " ")
              };
            });
            this.selectedColumnsList = [...prorityCols, ...this.selectedColumnsList]
          }
          this.columnsList = this.columns
          console.log("columns", JSON.stringify(this.columns));         
        }
      },
        (error: any) => console.log(error)
      );
    }
  }

  getMasterClaimTypes() {
    this.claimsList = [];
    var inputData = {
      label: this.selectedClaimType
    }
    this.claimsService.getMasterClaimTypes(inputData)
      .subscribe((res) => {
        if (res["status"] == 1) {
          // console.log("claimsList", JSON.stringify(this.claimsList));

          if(this.jobStatus && this.jobType) {
            var claimList = res.jobs;
            this.claimsList = claimList.filter(c => c.Current_Job_Status === this.jobType)
          } else {
            this.claimsList = res.jobs;
          }
          this.getAllMasterTypeJobsList(2);
        }
      },
        (error: any) => console.log(error)
      );
  }
  saveSelectedColumns() {
    localStorage.setItem(this.currentGridType, JSON.stringify(this.selectedColumnsList));
  }
  registerClaim(){
    let selectedClaim = this.selectedClaimType
    this.router.navigate(['/ClaimModule/registerJob', selectedClaim]);
  }
}
