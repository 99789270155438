<p-card class="dashboardHeader">
    <div class="p-grid">
        <div class="p-col-12 p-lg-2 p-md-4 p-sm-12 p-pt-0 p-pb-0 dropselection">
            <p-dropdown [options]="claims" [(ngModel)]="selectedClaim" (onChange)="onClaimsChange($event)"
                optionLabel="label"></p-dropdown>
        </div>
        <p-header class="p-col-12 p-pt-0 p-pb-0 subheading">
            Download or receive the filtered data.
        </p-header>


        <div class="p-col-12" style="position: relative;">
            <button pButton type="button" label="Download PDF" (click)="downloadPdf()"
                class="btnCss downloadBtn poscss p-mr-2 p-mt-4">
            </button>

            <p-accordion>
                <p-accordionTab header="{{activeState?'Special filters':'Special filters'}}" [(selected)]="activeState">

                    <div class="p-col-12 p-p-0 p-mt-4">

                        <div class="p-col-12 p-p-0" *ngFor="let sfl of specialFilersList; let i=index">
                            <div class="p-grid">
                                <div class="dynamicCtrlWrap">
                                    <div class=" p-col-12 p-md-4">
                                        <label for="firstname">Field name</label>
                                        <p-dropdown [(ngModel)]="sfl.selectedfieldValue" [options]="fieldsList"
                                            (onChange)="onfieldChange($event,sfl,i)" placeholder="Select field type">
                                        </p-dropdown>
                                    </div>

                                    <div class="p-col-12 p-md-3">
                                        <label for="firstname">Condition</label>
                                        <p-dropdown [(ngModel)]="sfl.selectedCondition" [options]="sfl.conditionsList"
                                            optionValue="value" optionLabel="label" (onChange)="onChange($event, sfl)"
                                            placeholder="Select condition"></p-dropdown>
                                    </div>

                                    <div *ngIf="sfl.selectedBetweenComparision == 0" class="p-col-12 p-md-5">
                                        <label for="lastname">Comparison</label>
                                        <input [(ngModel)]="sfl.comparisionvalue" *ngIf="sfl.selectedComparision == 2"
                                            type="text" pInputText>
                                        <p-dropdown [(ngModel)]="sfl.comparisionvalue"
                                            *ngIf="sfl.selectedComparision == 1" [options]="sfl.selectedComparisionList"
                                            optionValue="code" optionLabel="value" placeholder="Select Values">
                                        </p-dropdown>
                                        <p-calendar [(ngModel)]="sfl.comparisionvalue"
                                            *ngIf="sfl.selectedComparision == 3" [showIcon]="true" class="calanderIcon">
                                        </p-calendar>
                                    </div>
                                    <div *ngIf="sfl.selectedBetweenComparision == 1"
                                        class="p-col-12 p-md-5 p-d-flex p-flex-row">
                                        <div class="p-col-12 p-md-6 p-sm-12 p-p-0 p-mr-2">
                                            <label for="lastname">From</label>
                                            <p-calendar [(ngModel)]="sfl.selectedFromValue"
                                                *ngIf="sfl.selectedComparision == 3" [showIcon]="true"
                                                class="calanderIcon">
                                            </p-calendar>

                                            <input [(ngModel)]="sfl.selectedFromValue"
                                                *ngIf="sfl.selectedComparision == 2" type="number" pInputText>
                                        </div>

                                        <div class="p-col-12 p-md-6 p-sm-12 p-p-0 p-pr-1">
                                            <label for="lastname">To</label>
                                            <p-calendar [(ngModel)]="sfl.selectedToValue"
                                                *ngIf="sfl.selectedComparision == 3" [showIcon]="true"
                                                class="calanderIcon">
                                            </p-calendar>
                                            <input [(ngModel)]="sfl.selectedToValue"
                                                *ngIf="sfl.selectedComparision == 2" type="number" pInputText>
                                        </div>

                                    </div>
                                </div>
                                <div class="removeWrapperDiv diagnosisRemove">
                                    <i class="pi pi-times closeIcon_marginT" (click)="removeQuantity(i)"></i>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="p-grid p-col-12" style="display: inline;">
                        <button pButton type="button" icon="pi pi-plus" iconPos="left" label="Add"
                            (click)="addQuantity()" class="btnCss addBtn p-mr-2"> </button>
                        <button (click)="submitSpecialFilters()" pButton type="button" class="btnCss submitBtn p-mr-2"
                            label="Submit"></button>
                        <button (click)="clearSpecialFilters()" pButton type="button" class="btnCss"
                            label="Clear"></button>
                    </div>
                </p-accordionTab>
            </p-accordion>

        </div>



    </div>
</p-card>
<p-card>
    <div class="p-grid">
        <div class="cardSection">
            <div class="p-grid p-col-12">
                <div class="p-col-12 p-xl-2 p-lg-3 p-md-4 p-sm-6 p-xs-12"
                    *ngFor="let form of jobStatusList, let i = index">
                    <div class="dashboard_tile">
                        <div class="p-col-12">
                            <button pButton type="button" label="{{form.name}}" class="btnDashboard"
                                [ngClass]="{'badge-danger': (i == 0 || i == 4 || i== 8 || i == 11),'badge-info': (i == 1 || i==5 || i== 8), 'badge-warning': (i == 2 || i==6 ||  i== 9), 'badge-success': (i==3 || i==7 ||  i== 10) }">
                            </button>
                        </div>
                        <div class="p-col-12 numbercss">
                            {{form.count}}
                        </div>
                        <div class="p-col-12">
                            <!-- <a href="/ClaimModule/viewClaim" class="view-all"></a> -->
                            <a (click)="redirectToViewClaim('', form.name, 1)" style="cursor: pointer; color:blue;">View
                                All</a>
                        </div>
                    </div>
                </div>
                <!-- <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">
                    <div class="dashboard_tile">
                        <div class="p-col-12">
                            <button pButton type="button" label="Documents Requested" class="btnDashboard badge-info">
                            </button>
                        </div>
                        <div class="p-col-12 numbercss">
                            12
                        </div>
                        <div class="p-col-12">
                            <a href="#" class="view-all">View All</a>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">
                    <div class="dashboard_tile">
                        <div class="p-col-12">
                            <button pButton type="button" label="Documents Submitted"
                                class="btnDashboard badge-warning"> </button>
                        </div>
                        <div class="p-col-12 numbercss">
                           0
                        </div>
                        <div class="p-col-12">
                            <a href="#" class="view-all">View All</a>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">
                    <div class="dashboard_tile">
                        <div class="p-col-12">
                            <button pButton type="button" label="Awaiting Assessment"
                                class="btnDashboard badge-success"> </button>
                        </div>
                        <div class="p-col-12 numbercss">
                            2
                        </div>
                        <div class="p-col-12">
                            <a href="#" class="view-all">View All</a>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">
                    <div class="dashboard_tile">
                        <div class="p-col-12">
                            <button pButton type="button" label="Assessment Shared" class="btnDashboard badge-danger">
                            </button>
                        </div>
                        <div class="p-col-12 numbercss">
                           2
                        </div>
                        <div class="p-col-12">
                            <a href="#" class="view-all">View All</a>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">
                    <div class="dashboard_tile">
                        <div class="p-col-12">
                            <button pButton type="button" label="Consent Received" class="btnDashboard badge-info">
                            </button>
                        </div>
                        <div class="p-col-12 numbercss">
                            12
                        </div>
                        <div class="p-col-12">
                            <a href="#" class="view-all">View All</a>
                        </div>
                    </div>
                </div>  -->
            </div>
            <!-- <div class="p-grid p-col-12">
                <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">

                </div>
                <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">
                    <div class="dashboard_tile">
                        <div class="p-col-12">
                            <button pButton type="button" label="Settlement Follow-up"
                                class="btnDashboard badge-warning"> </button>
                        </div>
                        <div class="p-col-12 numbercss">
                           0
                        </div>
                        <div class="p-col-12">
                            <a href="#" class="view-all">View All</a>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">
                    <div class="dashboard_tile">
                        <div class="p-col-12">
                            <button pButton type="button" label="Settled"
                                class="btnDashboard badge-success"> </button>
                        </div>
                        <div class="p-col-12 numbercss">
                            0
                        </div>
                        <div class="p-col-12">
                            <a href="#" class="view-all">View All</a>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">
                    <div class="dashboard_tile">
                        <div class="p-col-12">
                            <button pButton type="button" label="Rejected" class="btnDashboard badge-danger">
                            </button>
                        </div>
                        <div class="p-col-12 numbercss">
                           2
                        </div>
                        <div class="p-col-12">
                            <a href="#" class="view-all">View All</a>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">
                    <div class="dashboard_tile">
                        <div class="p-col-12">
                            <button pButton type="button" label="Withdrawn" class="btnDashboard badge-danger">
                            </button>
                        </div>
                        <div class="p-col-12 numbercss">
                           2
                        </div>
                        <div class="p-col-12">
                            <a href="#" class="view-all">View All</a>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">

                </div>
            </div> -->

        </div>


    </div>
</p-card>
 <p-card *ngFor="let subJob of subJobsList">
    <div class="p-grid">
        <p-header class="p-col-12 p-pt-0 p-pb-0 Subheader-title">
            <div class="p-col-12">
                {{subJob.jobType}}
            </div>
        </p-header>
    </div>
    <div class="p-grid">
        <div class="p-grid p-col-12">

            <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12"  *ngFor="let job of subJob.jobStatus, let i= index">
                <div class="dashboard_tile">
                    <div class="p-col-12">
                        <button pButton type="button" label="{{job.name}}" class="btnDashboard" [ngClass]="{'badge-danger': (i == 0 || i == 4 || i== 8 || i == 11),'badge-info': (i == 1 || i==5 || i== 8), 'badge-warning': (i == 2 || i==6 ||  i== 9), 'badge-success': (i==3 || i==7 ||  i== 10) }">
                        </button>
                    </div>
                    <div class="p-col-12 numbercss">
                        {{job.count}}
                    </div>
                    <div class="p-col-12">

                        <a (click)="redirectToViewClaim(subJob.jobType, job.name, 2)"
                                    style="cursor: pointer; color:blue;">View All</a>
                    </div>
                </div>
            </div>


        </div>
    </div>
</p-card>
<!-- <p-card>
    <div class="p-grid">
        <p-header class="p-col-12 p-pt-0 p-pb-0 Subheader-title">
            <div class="p-col-12">
                Awaiting Assessment :
            </div>
        </p-header>
    </div>
    <div class="p-grid">
        <div class="p-grid p-col-12">
            <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">
                <div class="dashboard_tile">
                    <div class="p-col-12">
                        <button pButton type="button" label="Reminder 1" class="btnDashboard badge-info">
                        </button>
                    </div>
                    <div class="p-col-12 numbercss">
                        12
                    </div>
                    <div class="p-col-12">
                        <a href="#" class="view-all">View All</a>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">
                <div class="dashboard_tile">
                    <div class="p-col-12">
                        <button pButton type="button" label="Escalation 1" class="btnDashboard badge-warning"> </button>
                    </div>
                    <div class="p-col-12 numbercss">
                        0
                    </div>
                    <div class="p-col-12">
                        <a href="#" class="view-all">View All</a>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">
                <div class="dashboard_tile">
                    <div class="p-col-12">
                        <button pButton type="button" label="Escalation 2" class="btnDashboard badge-success"> </button>
                    </div>
                    <div class="p-col-12 numbercss">
                        2
                    </div>
                    <div class="p-col-12">
                        <a href="#" class="view-all">View All</a>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">
                <div class="dashboard_tile">
                    <div class="p-col-12">
                        <button pButton type="button" label="Escalation X" class="btnDashboard badge-danger">
                        </button>
                    </div>
                    <div class="p-col-12 numbercss">
                        2
                    </div>
                    <div class="p-col-12">
                        <a href="#" class="view-all">View All</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-card>
<p-card>
    <div class="p-grid">
        <p-header class="p-col-12 p-pt-0 p-pb-0 Subheader-title">
            <div class="p-col-12">
                Consent Received:
            </div>
        </p-header>
    </div>
    <div class="p-grid">
        <div class="p-grid p-col-12">
            <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">
                <div class="dashboard_tile">
                    <div class="p-col-12">
                        <button pButton type="button" label="Reminder 1" class="btnDashboard badge-info">
                        </button>
                    </div>
                    <div class="p-col-12 numbercss">
                        12
                    </div>
                    <div class="p-col-12">
                        <a href="#" class="view-all">View All</a>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">
                <div class="dashboard_tile">
                    <div class="p-col-12">
                        <button pButton type="button" label="Escalation 1" class="btnDashboard badge-warning"> </button>
                    </div>
                    <div class="p-col-12 numbercss">
                        0
                    </div>
                    <div class="p-col-12">
                        <a href="#" class="view-all">View All</a>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">
                <div class="dashboard_tile">
                    <div class="p-col-12">
                        <button pButton type="button" label="Escalation 2" class="btnDashboard badge-success"> </button>
                    </div>
                    <div class="p-col-12 numbercss">
                        2
                    </div>
                    <div class="p-col-12">
                        <a href="#" class="view-all">View All</a>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-lg-2 p-md-4 p-sm-6 p-xs-12">
                <div class="dashboard_tile">
                    <div class="p-col-12">
                        <button pButton type="button" label="Escalation X" class="btnDashboard badge-danger">
                        </button>
                    </div>
                    <div class="p-col-12 numbercss">
                        2
                    </div>
                    <div class="p-col-12">
                        <a href="#" class="view-all">View All</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-card> -->
